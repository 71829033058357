import React, { useState, useEffect } from 'react';

const DepartmentQuestion = ({ questionId, questionData, answer, setAnswer, situation, updateSituation, engine, updateResult }) => {
    const [nomCommune, setNomCommune] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dictionnaire, setDictionnaire] = useState({
        "29": 0.85, "22": 0.85, "56": 0.85, "35": 0.85, "53": 0.85, "72": 0.85, "41": 0.85, "61": 0.85, "14": 0.85,
        "44": 0.85, "49": 0.85, "85": 0.85, "79": 0.85, "86": 0.85, "17": 0.85, "16": 0.85, "87": 0.85, "33": 0.85,
        "40": 0.85, "31": 0.85, "34": 0.85, "2A": 0.85, "13": 0.85, "83": 0.85, "42": 0.85, "69": 0.85, "43": 0.85,
        "03": 0.85, "71": 0.85, "90": 0.85, "68": 0.85, "67": 0.85, "57": 0.85, "62": 0.85, "59": 0.85, "02": 0.85,
        "51": 0.85, "10": 0.85, "60": 0.85, "95": 0.85, "78": 0.85, "91": 0.85, "77": 0.85, "75": 0.85, "92": 0.85,
        "93": 0.85, "94": 0.85, "45": 0.85, "50": 0.775, "76": 0.775, "80": 0.775, "28": 0.775, "54": 0.775, "52": 0.775,
        "70": 0.775, "21": 0.775, "58": 0.775, "18": 0.775, "36": 0.775, "25": 0.775, "39": 0.775, "01": 0.775, "74": 0.775,
        "38": 0.775, "26": 0.775, "07": 0.775, "23": 0.775, "63": 0.775, "19": 0.775, "24": 0.775, "82": 0.775, "12": 0.775,
        "11": 0.775, "06": 0.775, "64": 0.775, "27": 0.725, "08": 0.725, "89": 0.725, "88": 0.725, "73": 0.725, "15": 0.725,
        "48": 0.725, "30": 0.725, "84": 0.725, "81": 0.725, "65": 0.725, "32": 0.725, "47": 0.725, "66": 0.725, "972": 0.725,
        "37": 0.725, "976": 0.725, "973": 0.725, "971": 0.65, "974": 0.65, "2B": 0.65, "09": 0.65, "05": 0.65, "04": 0.65,
        "55": 0.65, "46": 0.65
    });

    const [dictionnaireCouleur, setDictionnaireCouleur] = useState({
        "pas de restrictions": "#F7F7F7",
        "vigilance": "#fde68a",
        "alerte": "#fed7aa",
        "alerte renforcée": "#fecaca",
        "crise": "#f87171",
    });

    const [dictionnaireCouleurFoncee, setDictionnaireCouleurFoncee] = useState({ 
        "pas de restrictions": "#1f2937",
        "vigilance": "#f59e0b",
        "alerte": "#ea580c",
        "alerte renforcée": "#be123c",
        "crise": "#881337"
    });

    useEffect(() => {
        setAnswer(situation.simulation.simulationAnswers[questionId] || '');
    }, [questionId]);

    useEffect(() => {
        const fetchDepartment = async () => {
            setLoading(true);
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/api_vigieau`);
                const response = await fetch(url.toString());
                if (response.ok) {
                    const result = await response.json();
                    setNomCommune(result);
                } else {
                    setError('Error fetching data');
                }
            } catch (error) {
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchDepartment();
    }, []);

    const UpsertAnswerSituation = (answerValue) => {
        const newSituation = { ...situation };
        const key = questionId;
        console.log(dictionnaire[answerValue], 'test');

        if (answerValue.startsWith('-')) {
            answerValue = answerValue.slice(1);
        }

        if (questionId === 'direct . nombre de personnes' && answerValue[0] === '0') {
            answerValue = '';
        }

        if (!dictionnaire[answerValue] || dictionnaire[answerValue] === '' || dictionnaire[answerValue] === null) {
            setAnswer(answerValue);
            delete newSituation.simulation.simulationAnswers['indirect . services societaux . fuites'];
        } else {
            setAnswer(answerValue);
            newSituation.simulation.simulationAnswers['indirect . services societaux . fuites . rendement' ] =  dictionnaire[answerValue];
        }

        updateSituation(newSituation);
        engine.setSituation(situation.simulation.simulationAnswers);
        updateResult();
    };

    const handleSelectChange = (event) => {
        setAnswer(event.target.value);
    };

    const getNomCommuneInfo = () => {
        const commune = nomCommune.find(item => item.code === answer);
        return commune ? commune.info : 'Information not available';
    };

    const getVigilance = () => {
        const commune = nomCommune.find(item => item.code === answer);
        return commune ? commune.titre : 'None';
    };

    const getName= () => {
        const commune = nomCommune.find(item => item.code === answer);
        return commune ? commune.nom : 'None';
    };

    const getNiveauGraviteMax = () => {
        const commune = nomCommune.find(item => item.code === answer);
        console.log(commune.getNiveauGraviteMax,'TEST')
        return commune ? commune.niveauGraviteMax : 'None';
    };

    return (
        <div>
           
            <div className='answer_container' style={{marginTop:'1rem'}}>
                <label>
                    Code du département:
                    <input
                        className='answer_input'
                        type="text"
                        max="100000"
                        value={answer}
                        onChange={(e) => UpsertAnswerSituation(e.target.value)}
                        placeholder={questionData['par défaut']}
                    />
                </label>
                {answer in dictionnaire && getVigilance() !== 'None' ? (
                    <div className='api-vigieau' style={{ backgroundColor: dictionnaireCouleur[getNiveauGraviteMax()] }}>
                        <div className='api-vigieau-department-container' style={{display:'flex', gap:'1rem', alignItems:'center'}}>
                            <div style={{backgroundColor:dictionnaireCouleurFoncee[getNiveauGraviteMax()], padding:'5px 10px', color:'white', borderRadius:'15px' }}>{getNiveauGraviteMax().toUpperCase()}</div>
                            <div style={{display:'flex', alignItems:'center',gap:'1rem'}}><div><img src='/img/location.svg' style={{width:'2rem'}}></img></div> <div>{getName()}, {answer}</div></div>
                            
                        </div>
                        <div className='answer_info'>
                            
                        </div>
                        <div style={{ textAlign: 'left' }} className='answer_info'>
                            {loading && <p>Loading...</p>}
                            {error && <p>{error}</p>}
                            {!loading && !error && (
                                <div>
                                <p className='api-vigieau-vigilance'>{getVigilance()}</p>
                                <p>{getNomCommuneInfo()}</p></div>
                            )}
                        </div>
                        <img src='/img/logo_vigieau.svg' style={{height:'2rem'}}></img>
                    </div>
                ) : ''}
            </div>
        </div>
    );
};

export default DepartmentQuestion;
