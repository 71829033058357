import { v4 as uuidv4 } from 'uuid';

export const getInitSituation = () => {
  return {
    userId: uuidv4(),
    simulation: {
      id: uuidv4(),
      foldedSteps: ["direct . nombre de personnes"],
      simulationAnswers: {},
      startDate: new Date().toISOString(),
      results: {
        direct: 0,
        indirect: 0,
      },
      footprintResult: {
        globalFootprint: 0,
        directFootprint: 0,
        indirectFootprint: 0,
        detail: [],
      },
      is_finish: false,
    },
  };
};
