import React from 'react';
import { numStr } from './utils/FormatNumber';

const WaterFootprintColor = ({ engine, setShowGoutteEau, showGoutteEau }) => {
  // Évaluation des valeurs
  const bleuValue = engine.evaluate('empreinte eau . bleu').nodeValue;
  const vertValue = engine.evaluate('empreinte eau . vert').nodeValue;
  const grisValue = engine.evaluate('empreinte eau . gris').nodeValue;

  // Récupération des rawNode
  const bleuRawNode = engine.publicParsedRules['empreinte eau . bleu'].rawNode.description;
  const vertRawNode = engine.publicParsedRules['empreinte eau . vert'].rawNode.description;
  const grisRawNode = engine.publicParsedRules['empreinte eau . gris'].rawNode.description;

  return (
    <div style={{display:'flex', flexDirection:'column'}}>
    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '50px', color: 'blue' }}><img src='/img/eau.png' onClick={() => setShowGoutteEau('bleu')} className='goutte-eau-img'></img></div>
        <div>{numStr(bleuValue)} L</div>
       
      </div>
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '50px', color: 'green' }}><img src='/img/eau-verte.png' onClick={() => setShowGoutteEau('vert')} className='goutte-eau-img'></img></div>
        <div>{numStr(vertValue)} L</div>
     
      </div>
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '50px', color: 'gray' }}><img src='/img/eau-grise.png' onClick={() => setShowGoutteEau('grise')} className='goutte-eau-img'></img></div>
        <div>{numStr(grisValue)} L</div>
       
      </div>
    
    </div>
      {showGoutteEau === 'bleu' && <div><div style={{backgroundColor:'#F7F7FF'}}className="couleur-empreinte-info"><p style={{color:'var(--hydrosblue)', fontWeight:'bold'}}>Eau bleu</p><p>{bleuRawNode}</p> </div>
          
      </div>}
      {showGoutteEau === 'vert' && 
      <div><div style={{backgroundColor:'#F7FFF7'}} className="couleur-empreinte-info"><p style={{color:'var(--hydrosblue)', fontWeight:'bold'}}>Eau verte</p><p>{vertRawNode}</p> </div>
          
      </div>}

      {showGoutteEau === 'grise' && 
      <div><div className="couleur-empreinte-info"><p style={{color:'var(--hydrosblue)', fontWeight:'bold'}}>Eau grise</p><p>{grisRawNode}</p> </div>
          
      </div>}</div>
  );
};

export default WaterFootprintColor;
