const rules = `

empreinte eau:
 formule: direct + indirect

empreinte eau . moyenne:
  formule: 6000
  unité: litre/jour

explication bleu vert gris:
  description: Pour réaliser ce calculateur, nous avons utilisé des données répartissant l'eau en trois catégories selon sa provenance et son usage. Cliquez sur chaque goutte pour découvrir à quoi celles-ci correspondent !

empreinte eau . bleu:
  description: L'eau bleue représente les eaux prélevées sous-terre ou en surface. C'est l'eau de notre douche, ou du robinet par exemple.
  unité: litre/jour
  arrondi: oui
  formule:
    somme:
      - direct . bleu
      - indirect . alimentation . bleu
      - indirect . textile . bleu
      - indirect . numerique . bleu
      - indirect . nrj . bleu
      - indirect . logement . bleu
      - indirect . services societaux . bleu

empreinte eau . vert:
  description: L'eau verte représente l'eau de pluie dans le sol, utilisée en grande majorité pour l'alimentation.
  unité: litre/jour
  arrondi: oui
  formule:
    somme:
      - direct . vert
      - indirect . alimentation . vert
      - indirect . textile . vert
      - indirect . numerique . vert
      - indirect . nrj . vert
      - indirect . logement . vert
      - indirect . services societaux . vert

empreinte eau . gris:
  description: L'eau grise représente la quantité d'eau douce nécessaire pour diluer les polluants présents dans les eaux usées. Par exemple, l'industrie peut représenter une grande part de cette empreinte eau.
  unité: litre/jour
  arrondi: oui
  formule:
    somme:
      - direct . gris
      - indirect . alimentation . gris
      - indirect . textile . gris
      - indirect . numerique . gris
      - indirect . nrj . gris
      - indirect . logement . gris
      - indirect . services societaux . gris

direct:
  titre: Empreinte eau directe
  description: Empreinte eau liée à notre consommation d'eau de tous les jours. 
  icônes: 💧
  unité: litre/jour
  arrondi: oui
  formule:
    somme:
      - douche
      - vaisselle
      - bain
      - wc
      - robinet
      - linge
      - cuisine
      - animaux
      - voiture
      - jardin
      - piscine
      - terrasse
      - hydratation

direct . bleu:
  formule: direct
  unité: litre/jour

direct . vert:
  formule: 0
  unité: litre/jour

direct . gris:
  formule: 0
  unité: litre/jour

direct . moyenne:
  formule: 148
  unité: litre/jour

direct . nombre de personnes:
  question: Combien y a-t-il de personnes dans votre logement ?
  type: value
  par défaut: 1
  description: Ce nombre sera utilisé pour répartir la consommation d'eau liée aux lessives ou vaisselles par exemple. Si vous changez régulièrement d’endroit, répondez par rapport à l’endroit où vous êtes le plus souvent.  
  suggestions:
    Seul.e: 1
    En couple: 2
    En famille: 5
  unité: personne

direct . unité:
  valeur: 1
  unité: personne
  note: sert dans les calculs où le nombre de personnes est pris en compte, pour simplifier l'unité "personne"

direct . douche:
  titre: Douche
  description_action: La douche représente souvent la plus grande part de l'empreinte directe. Voici comment vous pourriez agir sur sa contribution.
  icônes: 🚿
  unité: litre/jour
  arrondi: oui
  produit:
    - fréquence / unites . jours par semaine
    - litres consommés

direct . douche . fréquence:
  question: Combien prenez-vous de douches par semaine ?
  description: En France, la douche représente généralement la plus grande part de notre empreinte eau directe individuelle. Répondez en moyenne combien de douches vous prenez par semaine. 
  type: value
  par défaut: 6
  unité: douche/semaine
  suggestions:
    Une par jour: 7
    Cinq par semaine: 5
    Deux par jour: 14

direct . douche . pomme:
  question: Utilisez-vous un pommeau de douche économe ?
  description: Si votre douche est relativement récente (moins de 3 ans), il y a de fortes chances que votre pommeau de douche soit économe. Si vous ne savez pas, répondez par défaut "non". 
  note: Savais-tu qu’un pommeau de douche classique consomme en moyenne 12 litres d’eau par minute ?
  type: bool
  par défaut: oui

direct . douche . litres consommés:
  formule:
    variations:
      - si: douche . pomme = oui
        alors:
          durée moyenne * faible debit
      - sinon:
          durée moyenne * fort debit
  unité: litre/douche

direct . douche . durée moyenne:
  question: Combien de minutes dure votre douche en général ?
  description: Nous savons que ce chiffre peut grandement varier, l’idée ici est plutôt de mettre une moyenne. En France, à titre de comparaison, la douche moyenne dure 9 minutes.
  par défaut: 8
  type: value
  suggestions:
    Expresse: 5
    Moyenne: 10
    Lente: 20
  unité: min/douche

direct . douche . fort debit:
  valeur: 12
  unité: litre/min
  références:
    économise l'eau: https://www.jeconomiseleau.org/index.php/particuliers/economies-par-usage/la-douche-et-le-bain

direct . douche . faible debit:
  valeur: 9
  unité: litre/min
  références:
    économise l'eau: https://www.jeconomiseleau.org/index.php/particuliers/economies-par-usage/la-douche-et-le-bain

direct . bain:
  titre: Bain
  description: Empreinte eau liée aux bains
  icônes: 🛁
  formule: fréquence * consommation / unites . jours par semaine
  unité: litre/jour
  arrondi: oui

direct . bain . fréquence:
  question: Combien prenez-vous de bains par semaine ?
  description: Indiquez ici environ combien de bains vous prenez par semaine. Si vous en prenez un par an, répondez "0". 
  note: 1 bain équivaut en moyenne à 175 litres d’eau !
  type: value
  par défaut: 0
  unité: bain/semaine
  suggestions:
    Aucun: 0
    Un par semaine: 1
    Un par mois: 0.25

direct . bain . consommation:
  valeur: 175 litre/bain
  références: Donnée issue de https://ekwateur.fr/blog/ma-consommation-d-energie/consommation-bain/
  unité: litre/bain

direct . wc:
  titre: Toilettes
  description: Empreinte eau liée à l'usage des toilettes
  icônes: 🚽
  formule:
    variations:
      - si: boutons = oui
        alors:
          somme:
            - empreinte grande chasse
            - empreinte petite chasse
      - sinon: empreinte chasse normale
  unité: litre/jour
  arrondi: oui

direct . wc . boutons:
  question: Avez-vous une chasse d'eau avec deux boutons (double flux) ?
  description: Si vos toilettes ont deux boutons, un petit, un grand, répondez "oui". Avec deux boutons, on adapte la quantité d'eau en fonction du besoin, donc on économise de l'eau.
  type: bool
  par défaut: oui

direct . wc . fréquence grande:
  question: Combien de fois par jour tirez-vous la grande chasse d'eau (à peu près) ?
  description: Entrez combien de fois par jour en moyenne vous utilisez la grande chasse d’eau. 
  applicable si:
    toutes ces conditions:
      - boutons = oui
  type: value
  par défaut: 1
  suggestions:
    2 fois par jour: 2
    1 fois par jour: 1
    Tous les deux jours: 0.5
  unité: grande chasse/jour

direct . wc . fréquence petite:
  question: Combien de fois par jour tirez-vous la petite chasse d'eau (à peu près) ?
  description: Entrez combien de fois par jour en moyenne vous utilisez la petite chasse d’eau. 
  applicable si:
    toutes ces conditions:
      - boutons = oui
  type: value
  par défaut: 4
  suggestions:
    2 fois par jour: 2
    4 fois par jour: 4
    6 fois par jour: 6
  unité: petite chasse/jour

direct . wc . fréquence normale:
  question: Combien de fois par jour tirez-vous la chasse d'eau (à peu près) ?
  description: Entrez combien de fois par jour en moyenne vous utilisez la chasse d’eau. 
  applicable si:
    toutes ces conditions:
      - boutons = non
  type: value
  par défaut: 4
  suggestions:
    2 fois par jour: 2
    4 fois par jour: 4
    6 fois par jour: 6
  unité: chasse normale/jour

direct . wc . empreinte grande chasse:
  formule: fréquence grande * conso grande
  unité: litre/jour

direct . wc . empreinte petite chasse:
  formule: fréquence petite * conso petite
  unité: litre/jour

direct . wc . empreinte chasse normale:
  formule: fréquence normale * conso normale
  unité: litre/jour

direct . wc . conso grande:
  valeur: 6
  références: Donnée issue de https://multimat.fr/2021/10/17/quelle-consommation-deau-pour-une-chasse-deau/
  unité: litre/grande chasse

direct . wc . conso petite:
  valeur: 3
  références: Donnée issue de https://multimat.fr/2021/10/17/quelle-consommation-deau-pour-une-chasse-deau/
  unité: litre/petite chasse

direct . wc . conso normale:
  valeur: 10.5
  références: Donnée issue de https://multimat.fr/2021/10/17/quelle-consommation-deau-pour-une-chasse-deau/
  unité: litre/chasse normale

direct . robinet:
  titre: Robinet
  description: Empreinte eau liée à l'usage du robinet
  icônes: 🚰
  formule: fréquence * conso
  unité: litre/jour
  arrondi: oui

direct . robinet . fréquence:
  question: Combien de fois par jour vous lavez-vous les mains ?
  description: Entrez combien de fois par jour environ vous vous lavez les mains complètement, durant plus ou moins 30 secondes sous l’eau.
  type: value
  par défaut: 5
  suggestions:
    2 fois par jour: 2
    4 fois par jour: 4
    8 fois par jour: 8
  unité: lavage/jour

direct . robinet . conso:
  valeur: 4.25
  références: Avec 30 secondes de lavage, et une consommation entre 5 et 12 L/min pour le robinet, soit 8,5 en moyenne, on obtient 4,25 L/lavage. sources https://www.activeau.fr/Mesurer-consommation-eau-lecture-directe.htm https://www.esprit-public.fr/lavage-des-mains-comment-frequence-et-duree/
  unité: litre/lavage

direct . linge:
  titre: Linge
  icônes: 👕
  description: Si vous utilisez un service de lavomatique avec de grosses machines à laver (poids soutenable supérieur à 8 kg), entrez “non”. 
  formule:
    variations:
      - si: lave-linge = oui
        alors: empreinte lave-linge
      - sinon: empreinte lavomatique
  unité: litre/jour
  arrondi: oui

direct . linge . lave-linge:
  question: Possédez-vous un lave-linge ? 
  description: Si vous allez plutôt au lavomatique, répondez "non" à cette question. 
  type: bool
  par défaut: oui

direct . linge . empreinte lave-linge:
  formule: unité * fréquence lave-linge * consommation lave-linge / (unites . jours par semaine * nombre de personnes)
  unité: litre/jour

direct . linge . eco ou pas:
  question: Votre lave-linge est-il à faible consommation d'eau ?
  applicable si:
    toutes ces conditions:
      - lave-linge = oui
  description: Les vieux lave-linge (plus de 4 ans) consomment en moyenne 1,5 fois plus d'eau. Si vous avez un lave-linge qui a plus de 4 ans, répondez "non". Si vous ne savez pas, répondez par défaut "non". 
  type: bool
  par défaut: non

direct . linge . fréquence lave-linge:
  question: Combien de fois par semaine lancez-vous une machine ?
  description: Entrez ici en moyenne le nombre de fois par semaine que vous lancez un cycle de votre machine à laver. Si vous êtes plusieurs dans votre logement, répondez combien de fois par semaine la machine à laver de votre logement est lancée, peu importe par qui.
  applicable si:
    toutes ces conditions:
      - lave-linge = oui
  type: value
  par défaut: 2
  note: A chaque machine, les textiles synthétiques libèrent des milliers de microfibres qui se retrouvent dans l’océan.
  suggestions:
    2 fois par mois: 0.5
    Une fois par semaine: 1
    Trois fois par semaine: 3
  unité: machine/semaine

direct . linge . consommation lave-linge:
  titre: Consommation moyenne d'un lave-linge selon l'âge
  références: La moyenne aujourd'hui est de 50 litres par lavage, on augmente donc ce nombre de 20% si la machine est vieille, et on la baisse de 20% si elle est récente. Source https://www.kelwatt.fr/eau/consommation/machine-a-laver
  formule:
    variations:
      - si: eco ou pas = non
        alors: 60
      - sinon: 40
  unité: litre/machine

direct . linge . empreinte lavomatique:
  formule: unité * fréquence lavomatique * consommation lavomatique / (unites . jours par semaine * nombre de personnes)
  unité: litre/jour

direct . linge . fréquence lavomatique:
  question: Combien de fois par semaine allez-vous au lavomatique ?
  description: Entrez ici en moyenne combien de fois par semaine vous allez au lavomatique. Si vous êtes plusieurs dans votre logement,  répondez combien de fois par semaine le foyer utilise le lavomatique.
  applicable si:
    toutes ces conditions:
      - lave-linge = non
  type: value
  par défaut: 3
  suggestions:
    2 fois par mois: 0.5
    Une fois par semaine: 1
    Trois fois par semaine: 3
  unité: machine/semaine

direct . linge . consommation lavomatique:
  titre: Consommation d'une grosse machine à laver
  références: Augmentation cette fois de 40% par rapport à la moyenne, pour représenter leur taille souvent plus importante.
  valeur: 70
  unité: litre/machine

direct . vaisselle:
  titre: Vaisselle
  description_action: Un lave-vaisselle consomme en moyenne deux fois moins d'eau qu'une vaisselle à la main. 
  icônes: 🍽️
  formule:
    variations:
      - si: lave-vaisselle ou pas = oui
        alors: empreinte lave-vaisselle
      - sinon: empreinte vaisselle main
  unité: litre/jour
  arrondi: oui

direct . vaisselle . lave-vaisselle ou pas:
  question: Possédez-vous un lave-vaisselle ?
  description: Faire sa vaisselle à la main consomme en moyenne deux fois plus d'eau que d'utiliser un lave-vaisselle. Si vous faites plus souvent la vaisselle à la main, répondez "non". 
  type: bool
  par défaut: oui

direct . vaisselle . empreinte lave-vaisselle:
  formule: unité * fréquence lave-vaisselle * consommation lave-vaisselle / (unites . jours par semaine * nombre de personnes)
  unité: litre/jour

direct . vaisselle . lave-vaisselle eco ou pas:
  question: Votre lave-vaisselle est-il à faible consommation d'eau ?
  applicable si:
    toutes ces conditions:
      - lave-vaisselle ou pas = oui
  description: Les vieux lave-vaisselle (plus de 5 ans) consomment en moyenne deux fois plus d'eau. Si vous avez un lave-vaisselle vieux de plus de 5 ans, répondez "non". Si vous ne savez pas, répondez par défaut "non". 
  type: bool
  par défaut: non

direct . vaisselle . fréquence lave-vaisselle:
  question: Combien de fois par semaine faites-vous un cycle ?
  description: Entrez ici en moyenne combien de fois par semaine vous lancez votre lave-vaisselle rempli. Si vous êtes plusieurs dans votre logement,  répondez combien de fois par semaine le lave-vaisselle de votre logement est lancé, peu importe par qui.
  applicable si:
    toutes ces conditions:
      - lave-vaisselle ou pas = oui
  type: value
  par défaut: 4
  suggestions:
    Deux fois par jour: 14
    Une fois par jour: 7
    Une fois par semaine: 1
  unité: vaisselle/semaine

direct . vaisselle . consommation lave-vaisselle:
  titre: Consommation moyenne d'un lave-vaisselle selon l'âge
  références: En moyenne, on est entre 10 et 20 litres par vaisselle, on pénalise donc la vieillesse du lave-vaisselle par la fourchette haute et on récompense sa jeunesse en lui attribuant la fourchette basse. source https://www.kelwatt.fr/eau/consommation/lave-vaisselle
  formule:
    variations:
      - si: lave-vaisselle eco ou pas = non
        alors: 20
      - sinon: 10
  unité: litre/vaisselle

direct . vaisselle . empreinte vaisselle main:
  formule: fréquence vaisselle main * consommation vaisselle main / unites . jours par semaine
  unité: litre/jour

direct . vaisselle . fréquence vaisselle main:
  question: Combien de fois par semaine faites vous la vaisselle pour vous ?
  description: Entrez ici combien de fois par semaine vous faites la vaisselle, uniquement pour vous. Si vous faites la vaisselle pour vous-même après chaque repas, entrez "14". Si vous faites la vaisselle pour deux à chaque repas, répondez également "14". 
  applicable si:
    toutes ces conditions:
      - lave-vaisselle ou pas = non
  type: value
  par défaut: 14
  suggestions:
    Deux fois par jour: 14
    Une fois par jour: 7
    Une fois par semaine: 1
  unité: vaisselle/semaine

direct . vaisselle . consommation vaisselle main:
  titre: Consommation moyenne d'une vaisselle à la main
  références: la consommation normale est deux fois plus grande que la consommation d'un lave-vaisselle, mais on fait plus souvent la vaisselle à la main. La valeur indiquée permet donc que la consommation moyenne à la main vaille le double de celle au lave-vaisselle.
  formule: 11
  unité: litre/vaisselle

direct . cuisine:
  titre: Cuisine
  description: Empreinte eau liée à l'usage de la cuisine
  icônes: 🔪
  références: Calculée à partir de la moyenne française, identique pour tout le monde. voir https://agirpourlatransition.ademe.fr/particuliers/conso/conso-responsable/astuces-economiser-leau-a-maison-alleger-factures
  valeur: 8.88
  arrondi: oui
  unité: litre/jour

direct . animaux:
  titre: Animaux de compagnie
  description: Empreinte eau liée aux animaux de compagnie
  icônes: 🐕
  valeur: animaux . nombre
  
direct . animaux . nombre:
  titre: Animaux de compagnie
  description: Indiquez ici combien d'animaux vous avez selon leur taille. La question évaluera ensuite leur empreinte eau à partir de leur hydratation et de leurs besoins hygiéniques.
  unité: litre/jour
  type: mosaic_value
  mosaique:
    type: nombre
    options:
      - petit . nombre
      - moyen . nombre
      - grand . nombre
      - poisson . nombre
    suggestions:
      2 chats:
        moyen . nombre: 2
      1 poisson:
        poisson . nombre: 1
      3 chiens:
        moyen . nombre: 2
        grand . nombre: 1
      Aucun animal:
        petit . nombre: 0
        moyen . nombre: 0
        grand . nombre: 0
        poisson . nombre: 0
  question: Indiquez ici combien d'animaux de compagnie différents vous avez chez vous.
  formule:
    somme:
      - petit
      - moyen
      - grand
      - poisson

direct . animaux . nombre . petit:
  formule: conso * nombre
  unité: litre/jour

direct . animaux . nombre . petit . nombre:
  titre: Un petit animal (moins de 10 kg) 🐹
  par défaut: 0
  plancher: 0
  unité: animal

direct . animaux . nombre . petit . conso:
  formule:
    variations:
      - si: douche . pomme = oui
        alors:
          valeur: 3.21
      - sinon:
          valeur: 6.92
  références: On suppose 0,5 L/jour d'hydratation pour animal de 10kg et une douche de 5min toutes les 2 semaines, en prenant en compte le débit d'eau choisi au début
  unité: litre/jour/animal

direct . animaux . nombre . moyen:
  formule: conso * nombre
  unité: litre/jour

direct . animaux . nombre . moyen . nombre:
  titre: Un animal moyen (entre 10 et 20 kg) 🐱
  par défaut: 0
  plancher: 0
  unité: animal

direct . animaux . nombre . moyen . conso:
  formule:
    variations:
      - si: douche . pomme = oui
        alors:
          valeur: 4.815
      - sinon:
          valeur: 10.38
  références: On suppose 0,75 L/jour d'hydratation pour animal de 15kg et une douche de 7,5min toutes les 2 semaines, en prenant en compte le débit d'eau choisi au début
  unité: litre/jour/animal

direct . animaux . nombre . grand:
  formule: conso * nombre
  unité: litre/jour

direct . animaux . nombre . grand . nombre:
  titre: Un grand animal (plus de 20 kg) 🐶
  par défaut: 0
  plancher: 0
  unité: animal

direct . animaux . nombre . grand . conso:
  formule:
    variations:
      - si: douche . pomme = oui
        alors:
          valeur: 6.43
      - sinon:
          valeur: 13.85
  références: On suppose 1 L/jour d'hydratation pour animal de 20kg et une douche de 10min toutes les 2 semaines, en prenant en compte le débit d'eau choisi au début
  unité: litre/jour/animal

direct . animaux . nombre . poisson:
  formule: conso * nombre
  unité: litre/jour

direct . animaux . nombre . poisson . nombre:
  titre: Un poisson en bocal 🐟
  par défaut: 0
  plancher: 0
  unité: animal

direct . animaux . nombre . poisson . conso:
  formule: 7.14
  références: Il est recommandé d'avoir au moins 50 litres d'eau par poisson, et de changer l'eau toutes les semaines. https://www.lepoissonrouge.org/laquarium-pour-poisson-rouge/
  unité: litre/jour/animal

direct . voiture:
  titre: Voiture
  description: Empreinte eau liée au lavage de la voiture
  icônes: 🚗
  formule: 
    variations:
      - si: présence = oui
        alors: empreinte
      - sinon: 0
  unité: litre/jour
  arrondi: oui

direct . voiture . empreinte:
  formule: conso * fréquence
  unité: litre/an

direct . voiture . présence:
  question: Possédez-vous une voiture ?
  description: Le lavage d'une voiture peut représenter une grande consommation d'eau. Si vous avez une voiture mais que vous ne la lavez pas, répondez tout de même "oui". 
  type: bool
  par défaut: oui

direct . voiture . fréquence:
  question: Combien de fois par an lavez-vous votre voiture ?
  description: Entrez ici combien de fois par an en moyenne vous lavez votre voiture, que ce soit chez vous ou à un lave-auto. Si c’est une fois tous les deux ans, entrez "0,5". Si vous ne lavez jamais votre voiture, entrez "0". 
  applicable si:
    toutes ces conditions:
      - présence = oui
  type: value
  par défaut: 6
  suggestions:
    1 fois par an: 1
    2 fois par an: 2
    4 fois par an: 4
  arrondi: oui
  unité: lavage/an

direct . voiture . type lavage:
  question: Nettoyez-vous votre voiture chez vous ?
  description: Nettoyer sa voiture au lave-auto consomme plus de 2 fois moins d'eau en moyenne. Si vous la lavez majoritairement chez vous, répondez "oui". 
  applicable si:
    toutes ces conditions:
      - présence = oui
      - fréquence > 0
  type: bool
  par défaut: oui

direct . voiture . conso:
  titre: Consommation moyenne d'un lavage de voiture selon le type de lavage
  références: source https://watercalculator.org/footprint/outdoor-water-use-at-home/
  formule:
    variations:
      - si: type lavage = oui
        alors: 454
      - sinon: 216
  unité: litre/lavage

direct . jardin:
  titre: Jardin et plantes
  description_action: Vous arrosez votre jardin ou vos fleurs dans l'année avec de l'eau du réseau, mais saviez-vous qu'il existe des solutions alternatives ?
  icônes: 🌱
  formule: 
    variations:
      - si: présence = oui
        alors: surface * conso * unité / (nombre de personnes)
      - sinon: 0
  unité: litre/jour
  arrondi: oui

direct . jardin . présence:
  question: Arrosez-vous vos plantes ou votre jardin ?
  description: Si vous n’avez pas de jardin et pas de plantes que vous arrosez régulièrement, répondez "non". Si vous avez un jardin mais que vous ne l’arrosez pas, répondez "non". 
  type: bool
  par défaut: non

direct . jardin . surface:
  question: Quelle surface arrosez-vous (en m²) ?
  description: Entrez ici environ la surface que vous arrosez, que ce soit en intérieur ou en extérieur. Si vous ne savez pas exactement, aucun soucis, entrez la surface qui s’en rapproche grossièrement, l’idée ici est simplement d’avoir un ordre de grandeur.
  applicable si:
    toutes ces conditions:
      - présence = oui
  type: value
  par défaut: 10
  suggestions:
    Quelques pots en intérieur: 1
    Quelques fleurs dehors: 10
    Une pelouse: 50
  unité: m²

direct . jardin . récupérateur:
  question: Possédez-vous un récupérateur d'eau de pluie ?
  description: Si vous récupérez des eaux pluviales sur votre toit, ou avec une bassine pour ensuite l'utiliser pour l'arrosage, répondez "oui".
  applicable si:
    toutes ces conditions:
      - présence = oui
      - surface > 2
  type: bool
  par défaut: non

direct . jardin . conso:
  références: source https://lesbonstuyaux.homeserve.fr/jardin-exterieur-limiter-larrosage-du-jardin
  formule: 
    variations:
      - si: récupérateur = non
        alors: 200
      - sinon: 0
  unité: litre/m²/an

direct . piscine:
  titre: Piscine
  description_action: Avoir une piscine change considérablement la répartition de votre empreinte eau. Néanmoins, tout dépend de comment vous l'utilisez.
  icônes: 🩱
  formule: 
    variations:
      - si: présence = oui
        alors: conso * unité / nombre de personnes
      - sinon: 0
  unité: litre/jour
  arrondi: oui

direct . piscine . présence:
  question: Possédez-vous une piscine ?
  description: Si vous avez une piscine dans le sol, dans votre maison ou même dans une maison de vacances, entrez "oui". Si vous avez une petite piscine hors sol que vous remplissez l’été, entrez plutôt "non". 
  note: La présence d’une piscine augmente en moyenne de 10% les consommations d’eau d’un foyer. 
  type: bool
  par défaut: non

direct . piscine . conso:
  références: source https://watercalculator.org/footprint/outdoor-water-use-at-home/
  valeur: 50007
  unité: litre/an

direct . terrasse: 
  titre: Terrasse
  description: Empreinte eau due au lavage de la terrasse
  icônes: 🕶️
  formule: 
    variations:
      - si: présence = oui
        alors: conso * unité / nombre de personnes
      - sinon: 0
  unité: litre/jour
  arrondi: oui

direct . terrasse . présence:
  question: Avez-vous une terrasse que vous lavez ?
  description: Nous prenons ici en compte l’empreinte eau due au lavage d’une terrasse avec des outils utilisant de l’eau sous forte pression. Si vous ne lavez jamais votre terrasse, répondez "non". 
  type: bool
  par défaut: non

direct . terrasse . taille:
  question: Avez-vous plutôt une grande terrasse ?
  description: Si vous répondez "oui", nous considérerons que vous utilisez un karcher 2h par an. Sinon, nous considérerons que vous l'utilisez seulement 1h par an. Si vous lavez votre terrasse rarement, répondez plutôt "non". 
  applicable si:
    toutes ces conditions:
      - présence = oui
  type: bool
  par défaut: non

direct . terrasse . conso:
  formule: 
    variations:
      - si: taille = oui
        alors:
          valeur: 700
      - sinon:
          valeur: 350
  références: On suppose que grand = 2h au karcher/an, petit = 1h au karcher/an. Pour la consommation, source https://www.mister-jardin.fr/consommation-eau-nettoyeur-haute-pression/
  unité: litre/an

direct . hydratation:
  titre: Hydratation
  description: Empreinte eau liée à l'hydratation, on suppose que l'on boit 2 litres d'eau par jour.
  valeur: 2
  unité: litre/jour

indirect:
  titre: Empreinte eau indirecte
  description: Cette empreinte sera calculée à partir des besoins en eau de la fabrication des produits que vous consommez (aliments, vêtements, etc).
  icônes: 🌊
  unité: litre/jour
  arrondi: oui
  formule:
    somme:
      - alimentation
      - textile
      - numerique
      - nrj
      - construction
      - services societaux

indirect . alimentation:
  titre: Empreinte eau alimentaire
  références: Les produits que nous consommons tous les jours pour nous nourrir sont eux-aussi demandants en eau. 
    Toutes nos données sont issues de https://watercalculator.org/water-footprint-of-food-guide/
  icônes: 🍅
  description_action: L'alimentation représente la plus grosse part de l'empreinte eau, c'est donc grâce à celle-ci que nous pouvons changer la donne. Voici des actions qui vous permettraient de réduire votre score.
  unité: litre/jour
  arrondi: oui
  formule:
    somme:
      - repas
      - boisson

indirect . alimentation . bleu:
  formule:
    somme:
      - plats . végétalien . bleu * plats . végétalien
      - plats . végétarien . bleu * plats . végétarien
      - plats . viande blanche . bleu * plats . viande blanche
      - plats . viande rouge . bleu * plats . viande rouge
      - plats . poisson gras . bleu * plats . poisson gras
      - petit déjeuner . tartine . bleu * petit déjeuner . tartine
      - petit déjeuner . cereales . bleu * petit déjeuner . cereales
      - petit déjeuner . verre de lait . bleu * petit déjeuner . verre de lait
      - petit déjeuner . jambon . bleu * petit déjeuner . jambon
      - petit déjeuner . oeufs . bleu * petit déjeuner . oeufs
      - petit déjeuner . fruits . bleu * petit déjeuner . fruits
      - petit déjeuner . yaourt . bleu * petit déjeuner . yaourt
      - petit déjeuner . fromage . bleu * petit déjeuner . fromage
      - desserts . fruits . bleu * desserts . fruits
      - desserts . yaourt . bleu * desserts . yaourt
      - desserts . fromage . bleu * desserts . fromage
      - boisson . chaude . café . bleu * boisson . chaude . café / unites . jours par semaine
      - boisson . chaude . thé . bleu * boisson . chaude . thé / unites . jours par semaine
      - boisson . chaude . chocolat chaud . bleu * boisson . chaude . chocolat chaud / unites . jours par semaine
      - boisson . froide . non alcool . eau en bouteille . bleu * boisson . froide . non alcool . eau en bouteille / unites . jours par semaine
      - boisson . froide . non alcool . jus de fruit . bleu * boisson . froide . non alcool . jus de fruit / unites . jours par semaine
      - boisson . froide . non alcool . soda . bleu * boisson . froide . non alcool . soda / unites . jours par semaine
      - boisson . froide . alcool . biere . bleu * boisson . froide . alcool . biere / unites . jours par semaine
      - boisson . froide . alcool . vin . bleu * boisson . froide . alcool . vin / unites . jours par semaine
  unité: litre/jour
  arrondi: oui

indirect . alimentation . vert:
  formule:
    somme:
      - plats . végétalien . vert * plats . végétalien
      - plats . végétarien . vert * plats . végétarien
      - plats . viande blanche . vert * plats . viande blanche
      - plats . viande rouge . vert * plats . viande rouge
      - plats . poisson gras . vert * plats . poisson gras
      - petit déjeuner . tartine . vert * petit déjeuner . tartine
      - petit déjeuner . cereales . vert * petit déjeuner . cereales
      - petit déjeuner . verre de lait . vert * petit déjeuner . verre de lait
      - petit déjeuner . jambon . vert * petit déjeuner . jambon
      - petit déjeuner . oeufs . vert * petit déjeuner . oeufs
      - petit déjeuner . fruits . vert * petit déjeuner . fruits
      - petit déjeuner . yaourt . vert * petit déjeuner . yaourt
      - petit déjeuner . fromage . vert * petit déjeuner . fromage
      - desserts . fruits . vert * desserts . fruits
      - desserts . yaourt . vert * desserts . yaourt
      - desserts . fromage . vert * desserts . fromage
      - boisson . chaude . café . vert * boisson . chaude . café / unites . jours par semaine
      - boisson . chaude . thé . vert * boisson . chaude . thé / unites . jours par semaine
      - boisson . chaude . chocolat chaud . vert * boisson . chaude . chocolat chaud / unites . jours par semaine
      - boisson . froide . non alcool . eau en bouteille . vert * boisson . froide . non alcool . eau en bouteille / unites . jours par semaine
      - boisson . froide . non alcool . jus de fruit . vert * boisson . froide . non alcool . jus de fruit / unites . jours par semaine
      - boisson . froide . non alcool . soda . vert * boisson . froide . non alcool . soda / unites . jours par semaine
      - boisson . froide . alcool . biere . vert * boisson . froide . alcool . biere / unites . jours par semaine
      - boisson . froide . alcool . vin . vert * boisson . froide . alcool . vin / unites . jours par semaine
  unité: litre/jour
  arrondi: oui

indirect . alimentation . gris:
  formule:
    somme:
      - plats . végétalien . gris * plats . végétalien
      - plats . végétarien . gris * plats . végétarien
      - plats . viande blanche . gris * plats . viande blanche
      - plats . viande rouge . gris * plats . viande rouge
      - plats . poisson gras . gris * plats . poisson gras
      - petit déjeuner . tartine . gris * petit déjeuner . tartine
      - petit déjeuner . cereales . gris * petit déjeuner . cereales
      - petit déjeuner . verre de lait . gris * petit déjeuner . verre de lait
      - petit déjeuner . jambon . gris * petit déjeuner . jambon
      - petit déjeuner . oeufs . gris * petit déjeuner . oeufs
      - petit déjeuner . fruits . gris * petit déjeuner . fruits
      - petit déjeuner . yaourt . gris * petit déjeuner . yaourt
      - petit déjeuner . fromage . gris * petit déjeuner . fromage
      - desserts . fruits . gris * desserts . fruits
      - desserts . yaourt . gris * desserts . yaourt
      - desserts . fromage . gris * desserts . fromage
      - boisson . chaude . café . gris * boisson . chaude . café / unites . jours par semaine
      - boisson . chaude . thé . gris * boisson . chaude . thé / unites . jours par semaine
      - boisson . chaude . chocolat chaud . gris * boisson . chaude . chocolat chaud / unites . jours par semaine
      - boisson . froide . non alcool . eau en bouteille . gris * boisson . froide . non alcool . eau en bouteille / unites . jours par semaine
      - boisson . froide . non alcool . jus de fruit . gris * boisson . froide . non alcool . jus de fruit / unites . jours par semaine
      - boisson . froide . non alcool . soda . gris * boisson . froide . non alcool . soda / unites . jours par semaine
      - boisson . froide . alcool . biere . gris * boisson . froide . alcool . biere / unites . jours par semaine
      - boisson . froide . alcool . vin . gris * boisson . froide . alcool . vin / unites . jours par semaine
  unité: litre/jour
  arrondi: oui

indirect . alimentation . repas:
  titre: Repas
  description: Empreinte eau liée aux déjeuners, dîners, petits-déjeuners et autres
  icône: 🌮
  unité: litre/jour
  formule:
    somme:
      - plats
      - desserts
      - petit déjeuner

indirect . alimentation . plats:
  titre: Déjeuners et dîners
  références: données par défaut https://docs.google.com/spreadsheets/d/16OXJirGwEyuQL9IY1ylT8am-j5Q3zs1qa5tOwOalKa0/edit?gid=0#gid=0 venant de https://fr.statista.com/previsions/1357004/regimes-alimentaires-suivis-par-les-francais
  description: Entrez ici combien de repas de différents types vous prenez chaque semaine en moyenne. Un repas végétalien est un repas sans aucun produit d’origine animale (sans poisson, viande, lait ou oeuf), tandis qu’un repas végétarien est un repas sans chair animale (sans poisson ou viande). Si vous ne savez pas trop, ou que votre régime varie souvent, cliquez sur l’option “Moyen”. En tout, vous pouvez avoir plus de 14 repas par semaine ou moins selon vos habitudes alimentaires. 
  unité: litre/jour
  type: mosaic_value
  mosaique:
    type: nombre
    options:
      - végétalien . nombre
      - végétarien . nombre
      - viande blanche . nombre
      - viande rouge . nombre
      - poisson gras . nombre
    suggestions:
      Moyen:
        végétalien . nombre: 0
        végétarien . nombre: 5
        viande blanche . nombre: 4
        viande rouge . nombre: 3
        poisson gras . nombre: 2
      Régime végétalien:
        végétalien . nombre: 14
        végétarien . nombre: 0
        viande blanche . nombre: 0
        viande rouge . nombre: 0
        poisson gras . nombre: 0
      Régime végétarien:
        végétalien . nombre: 5
        végétarien . nombre: 9
        viande blanche . nombre: 0
        viande rouge . nombre: 0
        poisson gras . nombre: 0
      Régime un peu carné:
        végétalien . nombre: 1
        végétarien . nombre: 7
        viande blanche . nombre: 4
        viande rouge . nombre: 1
        poisson gras . nombre: 1
      Régime très carné:
        végétalien . nombre: 0
        végétarien . nombre: 1
        viande blanche . nombre: 6
        viande rouge . nombre: 6
        poisson gras . nombre: 1
  question: Choisissez les plats de vos déjeuners et dîners pour une semaine type 
  note: Un repas végétarien consomme 714 litres d’eau, pour un repas classique, c’est en moyenne 1 117 litres d’eau !
  formule:
    somme:
      - végétalien
      - végétarien
      - viande blanche
      - viande rouge
      - poisson gras

indirect . alimentation . plats . compteur de plats:
  description: Voici le nombre de repas que vous avez sélectionnés pour la semaine. 
  formule:
    somme:
      - végétalien . nombre
      - végétarien . nombre
      - viande blanche . nombre
      - viande rouge . nombre
      - poisson gras . nombre
  unité: repas/semaine

indirect . alimentation . plats . végétalien:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . plats . végétalien . empreinte:
  titre: Empreinte d'un repas végétalien
  formule: 575.51
  unité: litre/repas
 
indirect . alimentation . plats . végétalien . nombre:
  titre: Végétalien 🥗
  par défaut: 1
  plancher: 0
  unité: repas/semaine

indirect . alimentation . plats . végétalien . bleu:
  formule: 0.1

indirect . alimentation . plats . végétalien . vert:
  formule: 0.79

indirect . alimentation . plats . végétalien . gris:
  formule: 0.11

indirect . alimentation . plats . végétarien:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . plats . végétarien . empreinte:
  titre: Empreinte d'un repas végétarien
  formule: 714.135
  unité: litre/repas
 
indirect . alimentation . plats . végétarien . nombre:
  titre: Végétarien 🍳
  par défaut: 4
  plancher: 0
  unité: repas/semaine

indirect . alimentation . plats . végétarien . bleu:
  formule: 0.11

indirect . alimentation . plats . végétarien . vert:
  formule: 0.74

indirect . alimentation . plats . végétarien . gris:
  formule: 0.15

indirect . alimentation . plats . viande rouge:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . plats . viande rouge . empreinte:
  titre: Empreinte d'un repas viande rouge
  formule: 2482.547
  unité: litre/repas
 
indirect . alimentation . plats . viande rouge . nombre:
  titre: Viande rouge 🥩
  par défaut: 3
  plancher: 0
  unité: repas/semaine

indirect . alimentation . plats . viande rouge . bleu:
  formule: 0.06

indirect . alimentation . plats . viande rouge . vert:
  formule: 0.9

indirect . alimentation . plats . viande rouge . gris:
  formule: 0.04

indirect . alimentation . plats . viande blanche:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . plats . viande blanche . empreinte:
  titre: Empreinte d'un repas viande blanche
  formule: 972.336
  unité: litre/repas
 
indirect . alimentation . plats . viande blanche . nombre:
  titre: Viande blanche 🍗
  par défaut: 3
  plancher: 0
  unité: repas/semaine

indirect . alimentation . plats . viande blanche . bleu:
  formule: 0.1

indirect . alimentation . plats . viande blanche . vert:
  formule: 0.77

indirect . alimentation . plats . viande blanche . gris:
  formule: 0.13

indirect . alimentation . plats . poisson gras:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . plats . poisson gras . empreinte:
  titre: Empreinte d'un repas poisson gras
  formule: 371.732
  unité: litre/repas
 
indirect . alimentation . plats . poisson gras . nombre:
  titre: Poisson gras 🐟
  par défaut: 3
  plancher: 0
  unité: repas/semaine

indirect . alimentation . plats . poisson gras . bleu:
  formule: 0.14

indirect . alimentation . plats . poisson gras . vert:
  formule: 0.7

indirect . alimentation . plats . poisson gras . gris:
  formule: 0.16

indirect . alimentation . petit déjeuner:
  titre: Petits déjeuners
  description: Entrez ici environ combien de portions de chaque aliment vous prenez en une semaine au petit-déjeuner. Vous pouvez en avoir moins de 7, ou faire des petits déjeuners en combinant plusieurs options, comme oeuf et jambon par exemple. 
  unité: litre/jour
  type: mosaic_value
  mosaique:
    type: nombre
    options:
      - tartine . nombre
      - cereales . nombre
      - verre de lait . nombre
      - jambon . nombre
      - oeufs . nombre
      - fruits . nombre
      - yaourt . nombre
      - fromage . nombre
    suggestions:
      Tartines beurre confiture:
        tartine . nombre: 7
      Des fruits:
        fruits . nombre: 4
      Céréales et lait:
        cereales . nombre: 5
        verre de lait . nombre: 5
      Oeuf et jambon:
        jambon . nombre: 5
        oeufs . nombre: 5
      Aucun petit déjeuner:
        tartine . nombre: 0
        verre de lait . nombre: 0
        cereales . nombre: 0
        jambon . nombre: 0
        oeufs . nombre: 0
        fruits . nombre: 0
        fromage . nombre: 0
        yaourt . nombre: 0
  question: Choisissez combien de petits déjeuners différents vous prenez par semaine.
  formule:
    somme:
      - tartine
      - cereales
      - verre de lait
      - jambon
      - oeufs
      - fruits
      - yaourt
      - fromage

indirect . alimentation . petit déjeuner . tartine:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . tartine . empreinte:
  titre: Empreinte d'un petit déjeuner de ce type
  formule: 150.837
  unité: litre/repas
 
indirect . alimentation . petit déjeuner . tartine . nombre:
  titre: Tartines beurre-confiture 🍞
  par défaut: 5
  plancher: 0
  unité: repas/semaine

indirect . alimentation . petit déjeuner . tartine . bleu:
  formule: 0.15

indirect . alimentation . petit déjeuner . tartine . vert:
  formule: 0.75

indirect . alimentation . petit déjeuner . tartine . gris:
  formule: 0.1

indirect . alimentation . petit déjeuner . cereales:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . cereales . empreinte:
  titre: Empreinte d'un petit déjeuner de ce type
  formule: 110.05
  unité: litre/repas
 
indirect . alimentation . petit déjeuner . cereales . nombre:
  titre: Céréales 🥣
  par défaut: 1
  plancher: 0
  unité: repas/semaine

indirect . alimentation . petit déjeuner . cereales . bleu:
  formule: 0.19

indirect . alimentation . petit déjeuner . cereales . vert:
  formule: 0.7

indirect . alimentation . petit déjeuner . cereales . gris:
  formule: 0.11

indirect . alimentation . petit déjeuner . verre de lait:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . verre de lait . empreinte:
  titre: Empreinte d'un petit déjeuner de ce type
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: lait vache
      - si: type de lait = 'Lait végétal'
        alors: lait vegetal
  unité: litre/repas
 
indirect . alimentation . petit déjeuner . verre de lait . nombre:
  titre: Verre de lait 🥛
  par défaut: 1
  plancher: 0
  unité: repas/semaine

indirect . alimentation . petit déjeuner . lait vache:
  titre: Lait de vache
  formule: 198.62
  unité: litre/repas
  description: |
    Petit déjeuner avec un produit laitier (lait de vache) et une portion de céréales.

indirect . alimentation . petit déjeuner . lait vegetal:
  titre: Lait végétal (soja)
  formule: 59.09
  unité: litre/repas
  description: |
    Petit déjeuner avec un produit laitier (lait de soja) et une portion de céréales.

indirect . alimentation . petit déjeuner . verre de lait . bleu:
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: 0.12
      - si: type de lait = 'Lait végétal'
        alors: 0.14

indirect . alimentation . petit déjeuner . verre de lait . vert:
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: 0.8
      - si: type de lait = 'Lait végétal'
        alors: 0.78

indirect . alimentation . petit déjeuner . verre de lait . gris:
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: 0.08
      - si: type de lait = 'Lait végétal'
        alors: 0.08

indirect . alimentation . petit déjeuner . jambon:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . jambon . empreinte:
  titre: Empreinte d'un petit déjeuner de ce type
  formule: 621.58
  unité: litre/repas
 
indirect . alimentation . petit déjeuner . jambon . nombre:
  titre: Tranches de jambon 🥓
  par défaut: 0
  plancher: 0
  unité: repas/semaine

indirect . alimentation . petit déjeuner . jambon . bleu:
  formule: 0.08

indirect . alimentation . petit déjeuner . jambon . vert:
  formule: 0.82

indirect . alimentation . petit déjeuner . jambon . gris:
  formule: 0.1

indirect . alimentation . petit déjeuner . oeufs:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . oeufs . empreinte:
  titre: Empreinte d'un petit déjeuner de ce type
  formule: 206.82
  unité: litre/repas
 
indirect . alimentation . petit déjeuner . oeufs . nombre:
  titre: Un oeuf 🥚
  par défaut: 0
  plancher: 0
  unité: repas/semaine 

indirect . alimentation . petit déjeuner . oeufs . bleu:
  formule: 0.08

indirect . alimentation . petit déjeuner . oeufs . vert:
  formule: 0.8

indirect . alimentation . petit déjeuner . oeufs . gris:
  formule: 0.12

indirect . alimentation . petit déjeuner . fruits:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . fruits . empreinte:
  titre: Empreinte d'un petit déjeuner de ce type
  formule: 89.148
  unité: litre/repas
 
indirect . alimentation . petit déjeuner . fruits . nombre:
  titre: Une portion de fruits 🍎
  par défaut: 1
  plancher: 0
  unité: repas/semaine

indirect . alimentation . petit déjeuner . fruits . bleu:
  formule: 0.19

indirect . alimentation . petit déjeuner . fruits . vert:
  formule: 0.68

indirect . alimentation . petit déjeuner . fruits . gris:
  formule: 0.13

indirect . alimentation . petit déjeuner . yaourt:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . yaourt . empreinte:
  titre: Empreinte d'un petit déjeuner de ce type
  formule: 149.912
  unité: litre/repas
 
indirect . alimentation . petit déjeuner . yaourt . nombre:
  titre: Un pot de yaourt 🫙
  par défaut: 0
  plancher: 0
  unité: repas/semaine

indirect . alimentation . petit déjeuner . yaourt . bleu:
  formule: 0.08

indirect . alimentation . petit déjeuner . yaourt . vert:
  formule: 0.85

indirect . alimentation . petit déjeuner . yaourt . gris:
  formule: 0.07

indirect . alimentation . petit déjeuner . fromage:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . petit déjeuner . fromage . empreinte:
  titre: Empreinte d'un dessert de ce type
  formule: 222.22
  unité: litre/dessert
 
indirect . alimentation . petit déjeuner . fromage . nombre:
  titre: Une part de fromage 🧀
  par défaut: 0
  plancher: 0
  unité: dessert/semaine

indirect . alimentation . petit déjeuner . fromage . bleu:
  formule: 0.08

indirect . alimentation . petit déjeuner . fromage . vert:
  formule: 0.85

indirect . alimentation . petit déjeuner . fromage . gris:
  formule: 0.07

indirect . alimentation . type de lait:
  applicable si:
    une de ces conditions:
      - boisson . chaude . chocolat chaud . nombre > 0
      - petit déjeuner . verre de lait . nombre > 0
  question: Le lait que vous consommez est-il d'origine animale ou végétale ?
  type: qcm
  par défaut: "'Lait de vache'"
  description: Indiquez ici la provenance du lait que vous consommez. Si vous utilisez du lait de soja ou d'avoine régulièrement, répondez "lait végétal". Si vous consommez du lait animal, répondez "lait de vache".
  formule:
    une possibilité:
      choix obligatoire: oui
      possibilités:
        - Lait de vache
        - Lait végétal

indirect . alimentation . type de lait . Lait de vache:
  titre: Lait de vache
indirect . alimentation . type de lait . Lait végétal:
  titre: Lait végétal

indirect . alimentation . empreinte lait de vache:
  formule: 1.023
  unité: litre/g

indirect . alimentation . empreinte lait vegetal:
  formule: 0.304
  unité: litre/g

indirect . alimentation . boisson . tasse de chocolat chaud . quantité lait par tasse:
  formule: 200
  unité: g/tasse
  note: on fait l'hypothèse qu'une tasse de chocolat chaud contient en moyenne 200 grammes de lait.

indirect . alimentation . desserts:
  titre: Desserts
  références: valeurs par défaut https://www.franceagrimer.fr/fam/content/download/48044/document/A4-Les%20achats%20de%20dessert%20par%20les%20MF.pdf?version=6 et https://www.francetvinfo.fr/culture/cuisine-et-gastronomie/desserts-les-francais-en-raffolent-toujours_2235707.html
  description: Entrez ici environ combien de portions de chaque aliment présenté vous prenez par semaine durant le dessert. Prenez en compte à la fois les déjeuners et dîners. Vous pouvez en avoir moins de 14, ou faire des desserts en combinant plusieurs options, comme fruits et yaourt par exemple.
  unité: litre/jour
  type: mosaic_value
  mosaique:
    type: nombre
    options:
      - fruits . nombre
      - yaourt . nombre
      - fromage . nombre
    suggestions:
      Moyen:
        fruits . nombre: 10
        yaourt . nombre: 5
        fromage . nombre: 1
      Des fruits:
        fruits . nombre: 10
      Des yaourts:
        yaourt . nombre: 7
      Du fromage:
        fromage . nombre: 5
      Aucun dessert:
        fruits . nombre: 0
        yaourt . nombre: 0
        fromage . nombre: 0
  question: Choisissez combien de desserts différents vous prenez par semaine.
  formule:
    somme:
      - fruits
      - yaourt
      - fromage

indirect . alimentation . desserts . fruits:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . desserts . fruits . empreinte:
  titre: Empreinte d'un dessert de ce type
  formule: 89.148
  unité: litre/dessert
 
indirect . alimentation . desserts . fruits . nombre:
  titre: Une portion de fruits 🍎
  par défaut: 6
  plancher: 0
  unité: dessert/semaine

indirect . alimentation . desserts . fruits . bleu:
  formule: 0.19

indirect . alimentation . desserts . fruits . vert:
  formule: 0.68

indirect . alimentation . desserts . fruits . gris:
  formule: 0.13

indirect . alimentation . desserts . yaourt:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . desserts . yaourt . empreinte:
  titre: Empreinte d'un dessert de ce type
  formule: 149.912
  unité: litre/dessert
 
indirect . alimentation . desserts . yaourt . nombre:
  titre: Un pot de yaourt ou de fromage blanc 🫙
  par défaut: 3
  plancher: 0
  unité: dessert/semaine

indirect . alimentation . desserts . yaourt . bleu:
  formule: 0.08

indirect . alimentation . desserts . yaourt . vert:
  formule: 0.85

indirect . alimentation . desserts . yaourt . gris:
  formule: 0.07

indirect . alimentation . desserts . fromage:
  formule: empreinte * nombre / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . desserts . fromage . empreinte:
  titre: Empreinte d'un dessert de ce type
  formule: 222.22
  unité: litre/dessert
 
indirect . alimentation . desserts . fromage . nombre:
  titre: Une part de fromage 🧀
  par défaut: 1
  plancher: 0
  unité: dessert/semaine

indirect . alimentation . desserts . fromage . bleu:
  formule: 0.08

indirect . alimentation . desserts . fromage . vert:
  formule: 0.85

indirect . alimentation . desserts . fromage . gris:
  formule: 0.07

indirect . alimentation . boisson:
  titre: Boisson
  type: mosaic_value
  description: Indiquez ici votre consommation moyenne de boissons chaudes, alcoolisées et non alcoolisées pour une semaine type. Si vous prenez par exemple une tasse de café par jour, entrez "7" dans la case correspondante. Si vous prenez un verre de bière une fois par mois, entrez "0" dans la case correspondante. 
  mosaique:
    type: nombre
    options:
      - chaude . café . nombre
      - chaude . thé . nombre
      - chaude . chocolat chaud . nombre
      - froide . non alcool . eau en bouteille . nombre
      - froide . non alcool . jus de fruit . nombre
      - froide . non alcool . soda . nombre
      - froide . alcool . biere . nombre
      - froide . alcool . vin . nombre
    suggestions:
      Aucune:
        chaude . café . nombre: 0
        chaude . thé . nombre: 0
        chaude . chocolat chaud . nombre: 0
        froide . non alcool . eau en bouteille . nombre: 0
        froide . non alcool . jus de fruit . nombre: 0
        froide . non alcool . soda . nombre: 0
        froide . alcool . biere . nombre: 0
        froide . alcool . vin . nombre: 0
      Un café par jour:
        chaude . café . nombre: 7
      Un peu d'alcool:
        froide . alcool . biere . nombre: 2
        froide . alcool . vin . nombre: 1
      Jus de fruit et soda:
        froide . non alcool . jus de fruit . nombre: 7
        froide . non alcool . soda . nombre: 4
  icônes: ☕
  question: Quelle est votre consommation de boissons pour une semaine type ?
  note: La production d’un kilo de café nécessite 20 000 litres d’eau.
  formule: par semaine / unites . jours par semaine
  unité: litre/jour

indirect . alimentation . boisson . par semaine:
  titre: Empreinte boissons par semaine
  formule:
    somme:
      - chaude . café
      - chaude . thé
      - chaude . chocolat chaud
      - froide . non alcool . eau en bouteille
      - froide . non alcool . jus de fruit
      - froide . non alcool . soda
      - froide . alcool . biere
      - froide . alcool . vin
  unité: litre/semaine

indirect . alimentation . boisson . chaude:
  titre: Empreinte due aux boissons chaudes
  formule:
    somme:
      - café
      - thé
      - chocolat chaud
  unité: litre/semaine

indirect . alimentation . boisson . chaude . café:
  titre: Café
  icônes: ☕
  formule: tasse de café * nombre
  unité: litre/semaine

indirect . alimentation . boisson . chaude . café . bleu:
  formule: 0.01

indirect . alimentation . boisson . chaude . café . vert:
  formule: 0.96

indirect . alimentation . boisson . chaude . café . gris:
  formule: 0.03

indirect . alimentation . boisson . chaude . thé:
  titre: Thé
  icônes: 🫖
  formule: tasse de thé * nombre
  unité: litre/semaine

indirect . alimentation . boisson . chaude . thé . bleu:
  formule: 0.1

indirect . alimentation . boisson . chaude . thé . vert:
  formule: 0.82

indirect . alimentation . boisson . chaude . thé . gris:
  formule: 0.08

indirect . alimentation . boisson . chaude . chocolat chaud:
  titre: Chocolat chaud
  icônes: 🥛🍫
  formule: tasse de chocolat chaud * nombre
  unité: litre/semaine

indirect . alimentation . boisson . chaude . chocolat chaud . bleu:
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: 0.04
      - si: type de lait = 'Lait végétal'
        alors: 0.02

indirect . alimentation . boisson . chaude . chocolat chaud . vert:
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: 0.93
      - si: type de lait = 'Lait végétal'
        alors: 0.97

indirect . alimentation . boisson . chaude . chocolat chaud . gris:
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: 0.03
      - si: type de lait = 'Lait végétal'
        alors: 0.01

indirect . alimentation . boisson . chaude . café . nombre:
  titre: Tasse de café ☕
  par défaut: 10
  plancher: 0
  unité: tasse/semaine
  note: Un français moyen boit 500 tasses de café par an ([source](https://fr.statista.com/themes/10144/le-cafe-en-france/#topFacts))

indirect . alimentation . boisson . chaude . thé . nombre:
  titre: Tasse de thé 🫖
  par défaut: 3
  plancher: 0
  unité: tasse/semaine
  note: Un français moyen consomme 250g de thé par an ([source](https://www.francesoir.fr/lifestyle-vie-quotidienne/le-boom-de-la-consommation-en-france)), soit 5 grammes par semaine. Nous avons estimé que cela correspondait approximativement à 3 tasses par semaine.

indirect . alimentation . boisson . chaude . chocolat chaud . nombre:
  titre: Tasse de chocolat chaud 🥛🍫
  image: url
  par défaut: 0
  plancher: 0
  unité: tasse/semaine

indirect . alimentation . boisson . tasse de café:
  titre: Empreinte d'une tasse de café
  formule: empreinte café moulu * quantité café par tasse
  unité: litre/tasse

indirect . alimentation . boisson . tasse de café . empreinte café moulu:
  formule: 1.0567
  unité: litre/g

indirect . alimentation . boisson . tasse de café . quantité café par tasse:
  formule: 200
  unité: g/tasse
  note: on fait l'hypothèse qu'une tasse de café fait environ 200mL soit environ 200g. 

indirect . alimentation . boisson . tasse de thé:
  titre: Empreinte d'une tasse de thé
  formule: empreinte thé * quantité thé par tasse
  unité: litre/tasse

indirect . alimentation . boisson . tasse de thé . empreinte thé:
  formule: 0.1099
  unité: litre/g

indirect . alimentation . boisson . tasse de thé . quantité thé par tasse:
  formule: 200
  unité: g/tasse
  note: on fait l’hypothèse qu’une tasse de thé fait environ 200mL soit environ 200g.

indirect . alimentation . boisson . tasse de chocolat chaud:
  titre: Empreinte d'une tasse de chocolat chaud
  formule:
    somme:
      - empreinte chocolat * quantité chocolat par tasse
      - empreinte lait * quantité lait par tasse
  unité: litre/tasse

indirect . alimentation . boisson . tasse de chocolat chaud . empreinte chocolat:
  formule: 17.2222
  unité: litre/g

indirect . alimentation . boisson . tasse de chocolat chaud . quantité chocolat par tasse:
  formule: 20
  unité: g/tasse
  note: On fait l'hypothèse qu'une tasse de chocolat chaud contient en moyenne 20 grammes de chocolat.

indirect . alimentation . boisson . tasse de chocolat chaud . empreinte lait:
  formule:
    variations:
      - si: type de lait = 'Lait de vache'
        alors: empreinte lait de vache
      - si: type de lait = 'Lait végétal'
        alors: empreinte lait vegetal
  unité: litre/g

indirect . alimentation . boisson . froide:
  titre: Empreinte eau due aux boissons froides, alcoolisées et non alcoolisées. 
  icônes: 🍺🥤🧃
  formule:
    somme:
      - non alcool
      - alcool
  unité: litre/semaine

indirect . alimentation . boisson . froide . non alcool:
  titre: Empreinte eau due aux boissons non alcoolisées. 
  icônes: 🥤🧃
  formule:
    somme:
      - eau en bouteille
      - jus de fruit
      - soda
  unité: litre/semaine

indirect . alimentation . boisson . froide . non alcool . eau en bouteille:
  titre: Eau en bouteille 🥤
  icônes: 🥤
  formule: verre eau * nombre
  unité: litre/semaine

indirect . alimentation . boisson . froide . non alcool . eau en bouteille . bleu:
  formule: 0.04

indirect . alimentation . boisson . froide . non alcool . eau en bouteille . vert:
  formule: 0

indirect . alimentation . boisson . froide . non alcool . eau en bouteille . gris:
  formule: 0.96

indirect . alimentation . boisson . froide . non alcool . jus de fruit:
  titre: Jus de fruit 🧃
  icônes: 🧃
  formule: verre jus de fruit * nombre
  unité: litre/semaine

indirect . alimentation . boisson . froide . non alcool . jus de fruit . bleu:
  formule: 0.18

indirect . alimentation . boisson . froide . non alcool . jus de fruit . vert:
  formule: 0.7

indirect . alimentation . boisson . froide . non alcool . jus de fruit . gris:
  formule: 0.12

indirect . alimentation . boisson . froide . non alcool . soda:
  titre: Soda 🍹
  icônes: 🍹
  formule: verre soda * nombre
  unité: litre/semaine

indirect . alimentation . boisson . froide . non alcool . soda . bleu:
  formule: 0.24

indirect . alimentation . boisson . froide . non alcool . soda . vert:
  formule: 0.70

indirect . alimentation . boisson . froide . non alcool . soda . gris:
  formule: 0.06
 
indirect . alimentation . boisson . froide . non alcool . eau en bouteille . nombre:
  titre: Verre d'eau en bouteille 🥤
  par défaut: 10
  plancher: 0
  unité: verre/semaine

indirect . alimentation . boisson . froide . non alcool . jus de fruit . nombre:
  titre: Verre de jus de fruit 🧃
  par défaut: 3
  plancher: 0
  unité: verre/semaine

indirect . alimentation . boisson . froide . non alcool . soda . nombre:
  titre: Cannette de soda 🍹
  par défaut: 3
  plancher: 0
  unité: verre/semaine

indirect . alimentation . boisson . froide . non alcool . verre eau:
  titre: Empreinte d'un verre d'eau en bouteille
  formule: empreinte plastique * quantité plastique par verre
  unité: litre/verre

indirect . alimentation . boisson . froide . non alcool . verre eau . empreinte plastique:
  formule: 0.235
  unité: litre/g

indirect . alimentation . boisson . froide . non alcool . verre eau . quantité plastique par verre:
  formule: 5
  unité: g/verre

indirect . alimentation . boisson . froide . non alcool . verre jus de fruit:
  titre: Empreinte d'un verre de jus de fruit
  formule: empreinte jus de fruit * quantité jus par verre
  unité: litre/verre

indirect . alimentation . boisson . froide . non alcool . verre jus de fruit . empreinte jus de fruit:
  formule: 1.0799
  unité: litre/g

indirect . alimentation . boisson . froide . non alcool . verre jus de fruit . quantité jus par verre:
  formule: 250
  unité: g/verre
  note: on fait l'hypothèse qu'un verre de jus de fruit fait 25cL soit 250g si on suppose une masse volumique proche de celle de l'eau.

indirect . alimentation . boisson . froide . non alcool . verre soda:
  titre: Empreinte d'un verre de soda
  formule: empreinte soda * quantité soda par verre
  unité: litre/verre

indirect . alimentation . boisson . froide . non alcool . verre soda . empreinte soda:
  formule: 0.4142
  unité: litre/g

indirect . alimentation . boisson . froide . non alcool . verre soda . quantité soda par verre:
  formule: 250
  unité: g/verre
  note: on fait l'hypothèse qu'un verre de soda fait 25cL soit 250g si on suppose une masse volumique proche de celle de l'eau.

indirect . alimentation . boisson . froide . alcool:
  titre: Empreinte eau due aux boissons alcoolisées. 
  icônes: 🥤🧃
  formule:
    somme:
      - biere
      - vin
  unité: litre/semaine

indirect . alimentation . boisson . froide . alcool . biere:
  titre: biere
  icônes: 🍺
  formule: verre biere * nombre
  unité: litre/semaine

indirect . alimentation . boisson . froide . alcool . biere . bleu:
  formule: 0.05

indirect . alimentation . boisson . froide . alcool . biere . vert:
  formule: 0.86

indirect . alimentation . boisson . froide . alcool . biere . gris:
  formule: 0.09

indirect . alimentation . boisson . froide . alcool . vin:
  titre: vin
  icônes: 🍷
  formule: verre vin * nombre
  unité: litre/semaine

indirect . alimentation . boisson . froide . alcool . vin . bleu:
  formule: 0.16

indirect . alimentation . boisson . froide . alcool . vin . vert:
  formule: 0.7

indirect . alimentation . boisson . froide . alcool . vin . gris:
  formule: 0.14

indirect . alimentation . boisson . froide . alcool . biere . nombre:
  titre: Verre de bière 🍺
  par défaut: 2
  plancher: 0
  unité: verre/semaine

indirect . alimentation . boisson . froide . alcool . vin . nombre:
  titre: Verre de vin 🍷
  par défaut: 5
  plancher: 0
  unité: verre/semaine

indirect . alimentation . boisson . froide . alcool . verre biere:
  titre: Empreinte d'un verre de biere
  formule: empreinte biere * quantité biere par verre
  unité: litre/verre

indirect . alimentation . boisson . froide . alcool . verre biere . empreinte biere:
  formule: 0.3043
  unité: litre/g

indirect . alimentation . boisson . froide . alcool . verre biere . quantité biere par verre:
  formule: 330
  unité: g/verre
  note: on fait l'hypothèse qu'un verre de bière fait 33cL soit 330g si on suppose une masse volumique proche de celle de l'eau.

indirect . alimentation . boisson . froide . alcool . verre vin:
  titre: Empreinte d'un verre de vin
  formule: empreinte vin * quantité vin par verre
  unité: litre/verre

indirect . alimentation . boisson . froide . alcool . verre vin . empreinte vin:
  formule: 0.8927
  unité: litre/g

indirect . alimentation . boisson . froide . alcool . verre vin . quantité vin par verre:
  formule: 150
  unité: g/verre
  note: on fait l'hypothèse qu'un verre de vin fait 15cL soit 150g si on suppose une masse volumique proche de celle de l'eau.

indirect . textile:
  titre: Empreinte eau textile
  description: La majorité des textiles que nous utilisons provient de l'agriculture, nos vêtements peuvent donc vite avoir une forte empreinte eau, selon leur matière de fabrication. Entrez ici grossièrement votre consommation de vêtements neufs sur les 12 derniers mois. 
  références: Nos données proviennent de l’empreinte eau du coton et du polyester, les deux principaux textiles utilisés pour fabriquer nos vêtements. Pour les valeurs par défaut, nous avons utilisé https://refashion.fr/pro/sites/default/files/rapport-etude/REFASHION-RA22-WEB.pdf
  description_action: Si vous achetez beaucoup de vêtements dans l'année, vous pouvez réduire votre empreinte due au textile comme ceci. 
  unité: litre/jour
  arrondi: oui
  type: mosaic_value
  icônes: 👔
  mosaique:
    type: nombre
    options:
      - tshirt . nombre
      - pantalon . nombre
      - short . nombre
      - chemise . nombre
      - pull . nombre
      - manteau . nombre
      - sousv . nombre
      - chaussures . nombre
    suggestions:
      Que du seconde main:
        tshirt . nombre: 0
        pantalon . nombre: 0
        short . nombre: 0
        chemise . nombre: 0
        pull . nombre: 0
        manteau . nombre: 0
        sousv . nombre: 0
        chaussures . nombre: 0
      Les essentiels:
        tshirt . nombre: 5
        pantalon . nombre: 1
        short . nombre: 2
        chemise . nombre: 3
        pull . nombre: 3
        manteau . nombre: 0
        sousv . nombre: 5
        chaussures . nombre: 1
      Nouvelle garde-robe:
        tshirt . nombre: 7
        pantalon . nombre: 5
        short . nombre: 4
        chemise . nombre: 6
        pull . nombre: 5
        manteau . nombre: 2
        sousv . nombre: 7
        chaussures . nombre: 3
  question: Indiquez le nombre de vêtements neufs que vous avez achetés dans la dernière année.
  note: Les matières textiles naturelles n’ont pas toutes le même impact sur l’eau.  Saviez-vous que 1 650 litres d’eau sont nécessaires pour produire un t-shirt en coton alors que seulement 325 litres d’eau sont suffisants pour la fabrication d’un pull en laine. La culture du coton biologique quant à elle demande 10 à 20 fois moins d’eau. 
  formule:
    somme:
      - tshirt
      - pantalon
      - short
      - chemise
      - pull
      - manteau
      - sousv
      - chaussures

indirect . textile . bleu:
  formule: (polyester . bleu * 0.25 + coton . bleu * 0.75) * textile
  unité: litre/jour

indirect . textile . vert:
  formule: (polyester . vert * 0.25 + coton . vert * 0.75) * textile
  unité: litre/jour

indirect . textile . gris:
  formule: (polyester . gris * 0.25 + coton . gris * 0.75) * textile
  unité: litre/jour
  
indirect . textile . polyester:
  
indirect . textile . polyester . bleu:
  formule: 0.01

indirect . textile . polyester . vert:
  formule: 0

indirect . textile . polyester . gris:
  formule: 0.99

indirect . textile . coton:
  
indirect . textile . coton . bleu:
  formule: 0.45

indirect . textile . coton . vert:
  formule: 0.41

indirect . textile . coton . gris:
  formule: 0.14

indirect . textile . tshirt:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . tshirt . empreinte:
  titre: Empreinte d'un t-shirt
  formule: 3519
  unité: litre/article
 
indirect . textile . tshirt . nombre:
  titre: T-shirt 👚
  par défaut: 5
  plancher: 0
  unité: article/an

indirect . textile . pantalon:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . pantalon . empreinte:
  titre: Empreinte d'un pantalon
  formule: 14075
  unité: litre/article
 
indirect . textile . pantalon . nombre:
  titre: Pantalon 👖
  par défaut: 3
  plancher: 0
  unité: article/an

indirect . textile . short:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . short . empreinte:
  titre: Empreinte d'un short
  formule: 9383
  unité: litre/article
 
indirect . textile . short . nombre:
  titre: Short 🩳
  par défaut: 2
  plancher: 0
  unité: article/an

indirect . textile . chemise:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . chemise . empreinte:
  titre: Empreinte d'une chemise
  formule: 4692
  unité: litre/article
 
indirect . textile . chemise . nombre:
  titre: Chemise 👔
  par défaut: 1
  plancher: 0
  unité: article/an

indirect . textile . pull:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . pull . empreinte:
  titre: Empreinte d'un pull
  formule: 9383
  unité: litre/article
 
indirect . textile . pull . nombre:
  titre: Pull 👘
  par défaut: 3
  plancher: 0
  unité: article/an

indirect . textile . manteau:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . manteau . empreinte:
  titre: Empreinte d'un manteau
  formule: 22285
  unité: litre/article
 
indirect . textile . manteau . nombre:
  titre: Manteau 🧥
  par défaut: 1
  plancher: 0
  unité: article/an

indirect . textile . sousv:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . sousv . empreinte:
  titre: Empreinte d'un sous-vêtements
  formule: 1877
  unité: litre/article
 
indirect . textile . sousv . nombre:
  titre: Sous-vêtements 🩲
  par défaut: 5
  plancher: 0
  unité: article/an

indirect . textile . chaussures:
  formule: empreinte * nombre
  unité: litre/jour

indirect . textile . chaussures . empreinte:
  titre: Empreinte d'une paire de chaussures
  formule: 8000
  unité: litre/article
 
indirect . textile . chaussures . nombre:
  titre: Chaussures 👠
  par défaut: 2
  plancher: 0
  unité: article/an

indirect . numerique:
  titre: Empreinte eau numérique
  description: Entrez ici combien d'objets électroniques que vous utilisez régulièrement. Si vous avez acheté un produit reconditionné, ne l'entrez pas ici. Ne prenez pas non plus en compte les produits que vous utilisez pour votre travail. Afin de repartir l'empreinte eau en litres par jour, on prendra en compte la durée de vie moyenne de chaque produit.
  références: Nos données proviennent de l’empreinte eau d’un téléphone, réalisée par Water Footprint Network. Pour les valeurs par défaut, c'est ici https://labo.societenumerique.gouv.fr/documents/23/Barom%C3%A8tre_du_num%C3%A9rique_2023_-_Rapport.pdf
  description_action: Certains produits numériques peuvent avoir une forte empreinte eau à cause de leurs métaux rares ou leurs méthodes de fabrication. Vous pouvez réduire votre empreinte avec ces actions ci-dessous. 
  unité: litre/jour
  arrondi: oui
  type: mosaic_value
  icônes: 💻
  mosaique:
    type: nombre
    options:
      - telephone . nombre
      - tv led . nombre
      - tv plasma . nombre
      - ordi portable . nombre
      - ordi fixe . nombre
      - tablette . nombre
      - console . nombre
      - imprimante . nombre
      - liseuse . nombre
      - caméra . nombre
    suggestions:
      Aucun:
        telephone . nombre: 0
        tv led . nombre: 0
        tv plasma . nombre: 0
        ordi portable . nombre: 0
        ordi fixe . nombre: 0
        tablette . nombre: 0
        console . nombre: 0
        imprimante . nombre: 0
        liseuse . nombre: 0
        caméra . nombre: 0
      Quelques produits:
        telephone . nombre: 1
        tv led . nombre: 0
        tv plasma . nombre: 0
        ordi portable . nombre: 1
        ordi fixe . nombre: 0
        tablette . nombre: 1
        console . nombre: 1
        imprimante . nombre: 0
        liseuse . nombre: 1
        caméra . nombre: 1
      Beaucoup de produits:
        telephone . nombre: 2
        tv led . nombre: 0
        tv plasma . nombre: 1
        ordi portable . nombre: 1
        ordi fixe . nombre: 1
        tablette . nombre: 1
        console . nombre: 2
        imprimante . nombre: 1
        liseuse . nombre: 1
        caméra . nombre: 2
  question: Indiquez le nombre de produits numériques neufs que vous utilisez au quotidien.
  formule:
    somme:
      - telephone
      - tv led
      - tv plasma
      - ordi portable
      - ordi fixe
      - tablette
      - console
      - imprimante
      - liseuse
      - caméra

indirect . numerique . bleu:
  formule:
    somme:
      - telephone . bleu * telephone
      - tv led . bleu * tv led
      - tv plasma . bleu * tv plasma
      - ordi portable . bleu * ordi portable
      - ordi fixe . bleu * ordi fixe
      - tablette . bleu * tablette
      - console . bleu * console
      - imprimante . bleu * imprimante
      - liseuse . bleu * liseuse
      - caméra . bleu * caméra
  arrondi: oui
  unité: litre/jour

indirect . numerique . vert:
  formule:
    somme:
      - telephone . vert * telephone
      - tv led . vert * tv led
      - tv plasma . vert * tv plasma
      - ordi portable . vert * ordi portable
      - ordi fixe . vert * ordi fixe
      - tablette . vert * tablette
      - console . vert * console
      - imprimante . vert * imprimante
      - liseuse . vert * liseuse
      - caméra . vert * caméra
  arrondi: oui
  unité: litre/jour

indirect . numerique . gris:
  formule:
    somme:
      - telephone . gris * telephone
      - tv led . gris * tv led
      - tv plasma . gris * tv plasma
      - ordi portable . gris * ordi portable
      - ordi fixe . gris * ordi fixe
      - tablette . gris * tablette
      - console . gris * console
      - imprimante . gris * imprimante
      - liseuse . gris * liseuse
      - caméra . gris * caméra
  arrondi: oui
  unité: litre/jour

indirect . numerique . telephone:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . telephone . empreinte:
  titre: Empreinte d'un téléphone
  formule: 12760
  unité: litre/produit

indirect . numerique . telephone . nombre:
  titre: Téléphone 📱
  par défaut: 1
  plancher: 0
  unité: produit

indirect . numerique . telephone . durée de vie:
  titre: Durée de vie du produit
  formule: 2.5
  unité: an
  références: https://fr.statista.com/infographie/12458/duree-de-vie-moyenne-appareils-electroniques-et-electromenagers/

indirect . numerique . telephone . bleu:
  formule: 0.113

indirect . numerique . telephone . vert:
  formule: 0.294

indirect . numerique . telephone . gris:
  formule: 0.593

indirect . numerique . tv led:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . tv led . empreinte:
  titre: Empreinte d'une tv led
  formule: 212007
  unité: litre/produit
 
indirect . numerique . tv led . nombre:
  titre: Télévision LED 📺
  par défaut: 0
  plancher: 0
  unité: produit

indirect . numerique . tv led . durée de vie:
  titre: Durée de vie du produit
  formule: 8.5
  unité: an
  références: https://fr.statista.com/infographie/12458/duree-de-vie-moyenne-appareils-electroniques-et-electromenagers/

indirect . numerique . tv led . bleu:
  formule: 0.217

indirect . numerique . tv led . vert:
  formule: 0.286

indirect . numerique . tv led . gris:
  formule: 0.497

indirect . numerique . tv plasma:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . tv plasma . empreinte:
  titre: Empreinte d'une tv plasma
  formule: 2020353
  unité: litre/produit
 
indirect . numerique . tv plasma . nombre:
  titre: Télévision Plasma 🎞️
  par défaut: 0
  plancher: 0
  unité: produit

indirect . numerique . tv plasma . durée de vie:
  titre: Durée de vie du produit
  formule: 8.5
  unité: an
  références: https://fr.statista.com/infographie/12458/duree-de-vie-moyenne-appareils-electroniques-et-electromenagers/

indirect . numerique . tv plasma . bleu:
  formule: 0.18

indirect . numerique . tv plasma . vert:
  formule: 0.26

indirect . numerique . tv plasma . gris:
  formule: 0.56

indirect . numerique . ordi portable:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . ordi portable . empreinte:
  titre: Empreinte d'un ordi portable
  formule: 231513
  unité: litre/produit
 
indirect . numerique . ordi portable . nombre:
  titre: Ordinateur portable 💻
  par défaut: 1
  plancher: 0
  unité: produit

indirect . numerique . ordi portable . durée de vie:
  titre: Durée de vie du produit
  formule: 3.5
  unité: an
  références: https://fr.statista.com/infographie/12458/duree-de-vie-moyenne-appareils-electroniques-et-electromenagers/

indirect . numerique . ordi portable . bleu:
  formule: 0.13

indirect . numerique . ordi portable . vert:
  formule: 0.28

indirect . numerique . ordi portable . gris:
  formule: 0.59

indirect . numerique . ordi fixe:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . ordi fixe . empreinte:
  titre: Empreinte d'un ordi fixe
  formule: 570929
  unité: litre/produit
 
indirect . numerique . ordi fixe . nombre:
  titre: Ordinateur fixe 🖥️
  par défaut: 0
  plancher: 0
  unité: produit

indirect . numerique . ordi fixe . durée de vie:
  titre: Durée de vie du produit
  formule: 5
  unité: an
  références: https://www.selfdirection.org/quelle-est-la-duree-de-vie-dun-pc-fixe/

indirect . numerique . ordi fixe . bleu:
  formule: 0.145

indirect . numerique . ordi fixe . vert:
  formule: 0.238

indirect . numerique . ordi fixe . gris:
  formule: 0.617

indirect . numerique . tablette:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . tablette . empreinte:
  titre: Empreinte d'une tablette
  formule: 34797
  unité: litre/produit
 
indirect . numerique . tablette . nombre:
  titre: Tablette 📼
  par défaut: 1
  plancher: 0
  unité: produit

indirect . numerique . tablette . durée de vie:
  titre: Durée de vie du produit
  formule: 4
  unité: an
  références: https://fr.statista.com/statistiques/1014054/distribution-obsolescence-technologique-tablettes-france/

indirect . numerique . tablette . bleu:
  formule: 0.145

indirect . numerique . tablette . vert:
  formule: 0.36

indirect . numerique . tablette . gris:
  formule: 0.495

indirect . numerique . console:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . console . empreinte:
  titre: Empreinte d'une console
  formule: 270997
  unité: litre/produit
 
indirect . numerique . console . nombre:
  titre: Console de jeux 🎮
  par défaut: 0
  plancher: 0
  unité: produit

indirect . numerique . console . durée de vie:
  titre: Durée de vie du produit
  formule: 5
  unité: an
  références: https://www.minitool.com/fr/disque-partition/duree-de-vie-ps4.html

indirect . numerique . console . bleu:
  formule: 0.125

indirect . numerique . console . vert:
  formule: 0.226

indirect . numerique . console . gris:
  formule: 0.649

indirect . numerique . imprimante:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . imprimante . empreinte:
  titre: Empreinte d'une imprimante
  formule: 190382
  unité: litre/produit
 
indirect . numerique . imprimante . nombre:
  titre: Imprimante 🖨️
  par défaut: 1
  plancher: 0
  unité: produit

indirect . numerique . imprimante . durée de vie:
  titre: Durée de vie du produit
  formule: 5
  unité: an
  références: https://fr.statista.com/infographie/12458/duree-de-vie-moyenne-appareils-electroniques-et-electromenagers/

indirect . numerique . imprimante . bleu:
  formule: 0.217

indirect . numerique . imprimante . vert:
  formule: 0.286

indirect . numerique . imprimante . gris:
  formule: 0.497

indirect . numerique . liseuse:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . liseuse . empreinte:
  titre: Empreinte d'une liseuse
  formule: 29261
  unité: litre/produit
 
indirect . numerique . liseuse . nombre:
  titre: Liseuse numérique 📖
  par défaut: 0
  plancher: 0
  unité: produit

indirect . numerique . liseuse . durée de vie:
  titre: Durée de vie du produit
  formule: 10
  unité: an
  références: https://www.leconseilmalin.fr/actu-conseils/quelle-est-la-duree-de-vie-dune-liseuse/

indirect . numerique . liseuse . bleu:
  formule: 0.136

indirect . numerique . liseuse . vert:
  formule: 0.305

indirect . numerique . liseuse . gris:
  formule: 0.559

indirect . numerique . caméra:
  formule: empreinte * nombre / durée de vie
  unité: litre/jour

indirect . numerique . caméra . empreinte:
  titre: Empreinte d'une caméra
  formule: 26576
  unité: litre/produit
 
indirect . numerique . caméra . nombre:
  titre: Caméra 📷
  par défaut: 1
  plancher: 0
  unité: produit

indirect . numerique . caméra . durée de vie:
  titre: Durée de vie du produit
  formule: 5
  unité: an
  références: https://lumiere-argentique.fr/duree-vie-d-appareil-photo-comprendre-cyc-vie-du-materiel-photo/

indirect . numerique . caméra . bleu:
  formule: 0.179

indirect . numerique . caméra . vert:
  formule: 0.267

indirect . numerique . caméra . gris:
  formule: 0.554

indirect . nrj:
  titre: Empreinte eau de l'énergie
  références: L'énergie que nous utilisons dans notre quotidien consomme elle aussi de l'eau pour être produite.
    Les données pour les empreintes eau sont proviennent de https://www.waterfootprint.org/resources/Report29-WaterFootprintBioenergy.pdf
    Les données pour les consommations moyennes d'énergie en france https://www.statistiques.developpement-durable.gouv.fr/consommation-denergie-par-usage-du-residentiel?rubrique=&dossier=168
    La répartition de la production d'énergie des différentes sources d'énergie en France en 2022 https://fr.wikipedia.org/wiki/%C3%89nergie_en_France#Secteur_de_l%27%C3%A9lectricit%C3%A9
    Pour la 100% électrique, on considère une batterie de 50 kWh, l'hybride rechargeable consomme alors moitié de la thermique et moitié de l'électrique.
  icônes: ⚡
  formule:
    somme:
      - elec
      - gaz
      - petrole
  unité: litre/jour
  arrondi: oui

indirect . nrj . bleu:
  formule: nrj
  unité: litre/jour

indirect . nrj . vert:
  formule: 0
  unité: litre/jour

indirect . nrj . gris:
  formule: 0
  unité: litre/jour

indirect . nrj . elec:
  titre: Empreinte eau liée à l'électricité
  description: Dans cette partie, nous allons utiliser l'empreinte eau de chaque source d'électricité (m3/GJ) et l'usage de chacune pour donner une relative empreinte eau globale liée à l'électricité
  formule:
    somme:
      - nucleaire
      - biomasse
      - charbon
      - eolien
      - solaire
      - hydro
      - gaz
  unité: litre/an

indirect . nrj . gaz:
  titre: Empreinte eau liée au gaz
  description: Dans cette partie, nous allons utiliser l'empreinte eau du gaz (m3/GJ) et l'usage du gaz pour donner une relative empreinte eau globale liée au gaz
  formule: conso totale * nrj . gaz . empreinte
  unité: litre/an

indirect . nrj . petrole:
  titre: Empreinte eau liée au pétrole
  description: Dans cette partie, nous allons utiliser l'empreinte eau du pétrole (m3/GJ) et l'usage du pétrole pour donner une relative empreinte eau globale liée au pétrole
  formule: conso totale * empreinte
  unité: litre/an

indirect . nrj . conso connue ou pas:
  question: Connaissez-vous la consommation moyenne d'électricité et de gaz par mois de votre foyer ?
  description: Si vous ne la connaissez pas, pas de problème, nous vous attribuerons la consommation moyenne française, et nous vous poserons quelques questions pour affiner votre empreinte eau.
  type: bool
  par défaut: non

indirect . nrj . elec . conso elec connue:
  question: Entrez la consommation d'électricité mensuelle moyenne de votre foyer en kWh.
  description: Cette consommation sera ensuite répartie au nombre de personnes vivant dans votre foyer. Si vous possédez des panneaux photovoltaïques, entrez uniquement votre consommation d'électricité venant du réseau. Si vous avez une voiture électrique, n'oubliez pas de prendre en compte sa consommation !
  type: value
  applicable si:
    toutes ces conditions:
      - conso connue ou pas = oui
  par défaut: 203
  suggestions:
    150 kWh/mois: 150
    200 kWh/mois: 200
  unité: kWh/mois

indirect . nrj . gaz . conso gaz connue:
  question: Entrez la consommation de gaz mensuelle moyenne de votre foyer en kWh.
  type: value
  applicable si:
    toutes ces conditions:
      - conso connue ou pas = oui
  par défaut: 186
  suggestions:
    150 kWh/mois: 150
    200 kWh/mois: 200
  unité: kWh/mois

indirect . nrj . chauffage et plaques:
  question: Avez-vous un chauffage et des plaques de cuisson au gaz ou à l'électricité ?
  description: Indiquez ici si vous utilisez majoritairement du gaz ou de l'électricité dans votre foyer. Si vous utilisez une source d'énergie différente pour le chauffage et la cuisine, répondez "Gaz".
  type: qcm
  applicable si:
    toutes ces conditions:
      - conso connue ou pas = non
  formule:
    une possibilité:
      choix obligatoire: oui
      possibilités:
        - Gaz
        - Electricité
  par défaut: Gaz

indirect . nrj . chauffage et plaques . Gaz:
  titre: Chauffage et plaques au gaz
  description: Je me chauffe et je cuisine au gaz

indirect . nrj . chauffage et plaques . Electricité:
  titre: Chauffage et plaques à l'électricité
  description: Je me chauffe et je cuisine à l'électricité

indirect . nrj . type voiture:
  question: Quel type de voiture possédez-vous ?
  description: Si vous n'avez pas une voiture électrique ou hybride, entrez "thermique". Si votre voiture est hybride non rechargeable, entrez "thermique". 
  type: qcm
  applicable si:
    toutes ces conditions:
      - conso connue ou pas = non
      - direct . voiture . présence = oui
  formule:
    une possibilité:
      choix obligatoire: oui
      possibilités:
        - Thermique
        - Hybride
        - Electrique
  par défaut: "'Thermique'"

indirect . nrj . type voiture . Thermique:
  titre: Voiture thermique
  description: J'ai une voiture thermique

indirect . nrj . type voiture . Hybride:
  titre: Voiture hybride
  description: J'ai une voiture hybride rechargeable

indirect . nrj . type voiture . Electrique:
  titre: Voiture électrique
  description: J'ai une voiture 100% électrique

indirect . nrj . elec . bonus chauffage et plaques:
  formule:
    variations:
      - si: indirect . nrj . chauffage et plaques = 'Electricité'
        alors: gaz . conso moyenne france
      - sinon: 0
  par défaut: 0
  unité: kWh/mois

indirect . nrj . elec . bonus voiture:
  applicable si:
    toutes ces conditions:
      - direct . voiture . présence = oui
      - conso connue ou pas = non
  formule:
    variations:
      - si: direct . voiture . présence = non
        alors: 0
      - si: indirect . nrj . type voiture = 'Thermique'
        alors: 0
      - si: indirect . nrj . type voiture = 'Hybride'
        alors: 750
      - si: indirect . nrj . type voiture = 'Electrique'
        alors: 1500
  par défaut: 0
  unité: kWh/mois

indirect . nrj . petrole . bonus:
  applicable si:
    toutes ces conditions:
      - direct . voiture . présence = oui
  formule:
    variations:
      - si: indirect . nrj . type voiture = 'Thermique'
        alors: 535.5
      - si: indirect . nrj . type voiture = 'Hybride'
        alors: 267.75
      - si: indirect . nrj . type voiture = 'Electrique'
        alors: 0
  par défaut: 0
  unité: kWh/mois

indirect . nrj . elec . conso totale:
  titre: Consommation électrique de l'utilisateur
  formule:
    variations:
      - si: indirect . nrj . conso connue ou pas = oui
        alors: conso elec connue * direct . unité * unites . kWh to GJ / (direct . nombre de personnes)
      - sinon:
          somme:
            - conso moyenne france * unites . kWh to GJ
            - indirect . nrj . elec . bonus chauffage et plaques * unites . kWh to GJ
            - indirect . nrj . elec . bonus voiture * unites . kWh to GJ
  unité: GJ/mois

indirect . nrj . elec . conso moyenne france:
  titre: Consommation moyenne d'électricité par français en 2021
  valeur: 203
  unité: kWh/mois

indirect . nrj . gaz . conso totale:
  titre: Consommation de gaz de l'utilisateur
  formule:
    variations:
      - si: indirect . nrj . conso connue ou pas = oui
        alors: conso gaz connue * unites . kWh to GJ * direct . unité / (direct . nombre de personnes)
      - si: indirect . nrj . chauffage et plaques = 'Gaz'
        alors: gaz . conso moyenne france * unites . kWh to GJ
      - si: indirect . nrj . chauffage et plaques = 'Electricité'
        alors: 0
  par défaut: gaz . conso moyenne france * unites . kWh to GJ
  unité: GJ/mois

indirect . nrj . gaz . conso moyenne france:
  titre: Consommation moyenne de gaz par français en 2021
  valeur: 186
  unité: kWh/mois

indirect . nrj . petrole . conso totale:
  titre: Consommation de pétrole de l'utilisateur
  formule:
    variations:
      - si: direct . voiture . présence = oui
        alors: indirect . nrj . petrole . bonus * unites . kWh to GJ
      - sinon: 0
  unité: GJ/mois

indirect . nrj . gaz . empreinte:
  valeur: 109
  unité: litre/GJ

indirect . nrj . petrole . empreinte:
  valeur: 1058
  unité: litre/GJ

indirect . nrj . elec . nucleaire:
  titre: Empreinte eau liée au nucleaire de l'utilisateur
  formule: empreinte * conso
  unité: litre/an

indirect . nrj . elec . nucleaire . empreinte:
  titre: Empreinte eau du nucleaire
  valeur: 86
  unité: litre/GJ

indirect . nrj . elec . nucleaire . conso:
  formule: pourcentage * conso totale
  unité: GJ/an

indirect . nrj . elec . nucleaire . pourcentage:
  titre: Pourcentage du nucleaire dans le mix énergétique
  valeur: 0.62

indirect . nrj . elec . biomasse:
  titre: Empreinte eau liée à la biomasse de l'utilisateur
  formule: empreinte * conso
  unité: litre/an

indirect . nrj . elec . biomasse . empreinte:
  titre: Empreinte eau de la biomasse
  valeur: 24200
  unité: litre/GJ

indirect . nrj . elec . biomasse . conso:
  formule: pourcentage * conso totale
  unité: GJ/an

indirect . nrj . elec . biomasse . pourcentage:
  titre: Pourcentage de la biomasse dans le mix énergétique
  valeur: 0.022

indirect . nrj . elec . charbon:
  titre: Empreinte eau liée au charbon de l'utilisateur
  formule: empreinte * conso
  unité: litre/an

indirect . nrj . elec . charbon . empreinte:
  titre: Empreinte eau du charbon
  valeur: 164
  unité: litre/GJ

indirect . nrj . elec . charbon . conso:
  formule: pourcentage * conso totale
  unité: GJ/an

indirect . nrj . elec . charbon . pourcentage:
  titre: Pourcentage du charbon dans le mix énergétique
  valeur: 0.033

indirect . nrj . elec . eolien:
  titre: Empreinte eau liée à l’éolien de l'utilisateur
  formule: empreinte * conso
  unité: litre/an

indirect . nrj . elec . eolien . empreinte:
  titre: Empreinte eau de l’éolien
  valeur: 0
  unité: litre/GJ

indirect . nrj . elec . eolien . conso:
  formule: pourcentage * conso totale
  unité: GJ/an

indirect . nrj . elec . eolien . pourcentage:
  titre: Pourcentage de l’éolien dans le mix énergétique
  valeur: 0.08

indirect . nrj . elec . solaire:
  titre: Empreinte eau liée au solaire de l'utilisateur
  formule: empreinte * conso
  unité: litre/an

indirect . nrj . elec . solaire . empreinte:
  titre: Empreinte eau du solaire
  valeur: 265
  unité: litre/GJ

indirect . nrj . elec . solaire . conso:
  formule: pourcentage * conso totale
  unité: GJ/an

indirect . nrj . elec . solaire . pourcentage:
  titre: Pourcentage du solaire dans le mix énergétique
  valeur: 0.043

indirect . nrj . elec . hydro:
  titre: Empreinte eau liée à l’hydraulique de l'utilisateur
  formule: empreinte * conso
  unité: litre/an

indirect . nrj . elec . hydro . empreinte:
  titre: Empreinte eau de l’hydraulique
  valeur: 4300
  unité: litre/GJ

indirect . nrj . elec . hydro . conso:
  formule: pourcentage * conso totale
  unité: GJ/an

indirect . nrj . elec . hydro . pourcentage:
  titre: Pourcentage de l’hydraulique dans le mix énergétique
  valeur: 0.107

indirect . nrj . elec . gaz:
  titre: Empreinte eau liée au gaz de l'utilisateur
  formule: empreinte * conso
  unité: litre/an

indirect . nrj . elec . gaz . empreinte:
  titre: Empreinte eau du gaz
  valeur: 109
  unité: litre/GJ

indirect . nrj . elec . gaz . conso:
  formule: pourcentage * conso totale
  unité: GJ/an

indirect . nrj . elec . gaz . pourcentage:
  titre: Pourcentage du gaz dans le mix énergétique
  valeur: 0.095

indirect . logement:
  titre: Empreinte eau due à la construction du logement
  description: La construction génère une forte empreinte eau, chaque logement représente donc une part du gâteau plus ou moins importante. 
  icônes: 🏠
  formule: empreinte . par personne * taille / ancienneté
  unité: litre/jour
  arrondi: oui

indirect . logement . empreinte:
  formule: 248695
  unité: litre/mètrecarré

indirect . logement . empreinte . par personne:
  formule: empreinte * direct . unité / direct . nombre de personnes
  unité: litre/mètrecarré

indirect . logement . taille: 
  question: Quelle est la taille de votre logement ?
  type: value
  description: La taille de votre logement nous permet de trouver l'empreinte eau due à la construction de celui-ci. 
  par défaut: 50
  suggestions:
    Appartement: 30
    Maison: 100
  unité: mètrecarré

indirect . logement . ancienneté:
  formule: 70
  unité: an
  description: Ancienneté moyenne d'un logement en France
  références: https://www.swisslife.com/fr/home/blog/combien-de-temps-peut-vivre-un-batiment.html#:~:text=gaines%20techniques%20ouvertes%C2%BB.-,On%20estime%20que%20la%20dur%C3%A9e%20de%20vie%20des%20immeubles,de%2070%20%C3%A0%20100%20ans.

indirect . logement . bleu:
  formule: 0.04 * logement
  unité: litre/jour

indirect . logement . vert:
  formule: 0 * logement
  unité: litre/jour

indirect . logement . gris:
  formule: 0.96 * logement
  unité: litre/jour

indirect . construction:
  titre: Empreinte eau construction
  formule:
    somme:
      - indirect . logement
      - indirect . services societaux . construction
  unité: litre/jour

indirect . services societaux:
  titre: Empreinte eau des services societaux
  description: Nous répartissons également une empreinte eau due aux différents services societaux.
  icônes: 🏛️
  formule:
    somme:
      - fuites
      - sante
  unité: litre/jour
  arrondi: oui

indirect . services societaux . bleu:
  formule: 
    somme:
      - fuites
      - 0.04 * construction
      - 0.5 * sante . medicaments
      - sante . hopitaux
  unité: litre/jour

indirect . services societaux . vert:
  formule:
    somme:
      - 0 * construction
  unité: litre/jour

indirect . services societaux . gris:
  formule:
    somme:
      - 0.96 * construction
      - 0.5 * sante . medicaments
  unité: litre/jour

indirect . services societaux . nombre de personnes en France:
  valeur: 68400000

indirect . services societaux . departement:
  question: Dans quel département habitez-vous ?
  type: department
  par défaut: 75
  note: En moyenne en France les réseaux d'eau potable ont un rendement de 80%. Autrement dit, 1 litre d'eau sur 5 est perdu par fuite dans les canalisations et n'arrive donc pas à notre robinet.
  description: Cette information nous permettra de vous répartir une empreinte eau due à certains facteurs dépendants de l'endroit où vous habitez, comme par exemple les fuites dans les canalisations.

indirect . services societaux . fuites:
  titre: Empreinte eau due aux fuites dans les canalisations
  formule: direct * (1 - rendement) / rendement
  unité: litre/jour
  arrondi: oui
  références: Sispea, rendement moyen des réseaux de distribution d’eau potable en France en 2021

indirect . services societaux . fuites . rendement:
  titre: Rendement du réseau de distribution d'eau potable selon la catégorie
  par défaut : 0.8

indirect . services societaux . construction:
  titre: Empreinte eau liée à la construction de bâtiments publics
  icônes: 🏠
  formule: ( consommation / nombre de personnes en France ) + empreinte * taille / ( ancienneté * nombre de personnes en France )
  unité: litre/jour
  arrondi: oui

indirect . services societaux . construction . empreinte:
  formule: 248380
  unité: litre/mètrecarré

indirect . services societaux . construction . taille: 
  description: Surface totale des bâtiments publics en France 
  formule: 280000000
  références: https://aides-territoires.beta.gouv.fr/aides/3e7d-realiser-la-renovation-energetique-des-batime/#:~:text=Avec%20un%20patrimoine%20b%C3%A2ti%20de,d%27%C3%A9nergie%20du%20parc%20tertiaire.
  unité: mètrecarré

indirect . services societaux . construction . ancienneté:
  formule: 70
  unité: an
  description: Ancienneté moyenne d'un logement en France
  références: https://www.swisslife.com/fr/home/blog/combien-de-temps-peut-vivre-un-batiment.html#:~:text=gaines%20techniques%20ouvertes%C2%BB.-,On%20estime%20que%20la%20dur%C3%A9e%20de%20vie%20des%20immeubles,de%2070%20%C3%A0%20100%20ans.

indirect . services societaux . construction . consommation:
  formule: 330 * 280000000
  unité: litre/an
  description: consommation d'eau d'un bâtiment public, multipliée par la surface des bâtiments publics en France
  références: https://resources.taloen.fr/resources/documents/3034_191204_Fiche_Enjeu_Eau.pdf

indirect . services societaux . sante:
  titre: Empreinte eau due à la santé
  formule:
    somme:
      - medicaments
      - hopitaux
  unité: litre/jour
  arrondi: oui

indirect . services societaux . sante . medicaments:
  titre: Empreinte eau liée aux médicaments répartie à chaque personne en France
  formule: empreinte * conso
  unité: litre/jour

indirect . services societaux . sante . medicaments . empreinte:
  formule: 5000000
  références: Donnée issue de https://www.activeau.fr/besoin-eau-production-bien.htm
  unité: litre/kg

indirect . services societaux . sante . medicaments . conso:
  titre: Consommation moyenne d'une personne française de médicaments en kg par an.
  formule: 0.1
  références: Donnée issue de https://laconsciencedesetudiants.fr/2021/04/28/limpact-du-cycle-de-vie-dun-medicament-sur-lenvironnement/
  unité: kg/an

indirect . services societaux . sante . hopitaux:
  titre: Empreinte eau liée aux hôpitaux répartie à chaque personne en France
  formule: empreinte / nombre de personnes en France
  unité: litre/jour

indirect . services societaux . sante . hopitaux . empreinte:
  titre: Empreinte eau des hopitaux
  description: empreinte eau directe des hopitaux en France dans une année
  formule: nombre de lits * empreinte lit
  unité: litre/an

indirect . services societaux . sante . hopitaux . empreinte . nombre de lits:
  références: Nombre de lits d'hopitaux en France en 2020, donnée issue de https://drees.solidarites-sante.gouv.fr/sites/default/files/2021-09/ER1208.pdf
  formule: 387000
  unité: lit

indirect . services societaux . sante . hopitaux . empreinte . empreinte lit:
  références: Eau utilisée par lit en France, calculée en divisant l'eau utilisée par l'hôpital de Morlaix dans une année par son nombre de lits
    Donnée issue de https://www.techopital.com/story?ID=6478   https://www.ch-morlaix.fr/presentation
  formule: 83871
  unité: litre/lit/an

equivalent:
  titre: Equivalents de l'empreinte eau

equivalent . volume bouteille cristaline:
  formule: 1.5
  unité: litre/bouteille

equivalent . bouteille cristaline:
  titre: bouteilles par jour
  type: equivalent
  formule: empreinte eau / volume bouteille cristaline
  description: Voici le nombre de bouteilles de 1,5 litres dont vous avez besoin chaque jour pour votre mode de vie. 
  arrondi: oui
  unité: bouteille/jour

equivalent . bain:
  titre: bains par jour
  type: equivalent
  formule: empreinte eau / direct . bain . consommation
  description: Voici combien de baignoires remplies cela représenterait par jour.
  arrondi: oui
  unité: bain/jour

equivalent . volume piscine:
  formule: 43000
  unité: litre/piscine
  références: https://www.liberation.fr/checknews/secheresse-combien-les-piscines-privees-consomment-elles-deau-20220826_GOYIKE2XFZDNPCVUV3PZZIKHYY/

equivalent . piscine:
  titre: piscines individuelles par an
  type: equivalent
  formule: empreinte eau * unites . jours par an / volume piscine
  description: Voici combien de piscines moyennes remplies cela représenterait par an. 
  arrondi: oui
  unité: piscine/an

comparaison pays:
  titre: Empreinte équivalente pays
  références: https://www.waterfootprint.org/resources/interactive-tools/national-water-footprint-explorer/

comparaison pays . rdc:
  titre: L'empreinte moyenne d'un habitant en RDC
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau < 2000
  formule: 1500
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen en République Démocratique du Congo.

comparaison pays . chine:
  titre: L'empreinte moyenne d'un habitant en Chine
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 2000
      - empreinte eau < 3000
  formule: 2900
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen en Chine.

comparaison pays . uk:
  titre: L'empreinte moyenne d'un habitant au Royaume-Uni
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 3000
      - empreinte eau < 3500
  formule: 3400
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen au Royaume-Uni.

comparaison pays . japon:
  titre: L'empreinte moyenne d'un habitant au Japon
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 3500
      - empreinte eau < 4000
  formule: 3800
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen au Japon.

comparaison pays . algérie:
  titre: L'empreinte moyenne d'un habitant en Algérie
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 4000
      - empreinte eau < 4500
  formule: 4400
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen en Algérie.

comparaison pays . soudan:
  titre: L'empreinte moyenne d'un habitant en France ou au Soudan
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 4500
      - empreinte eau < 5000
  formule: 4800
  unité: litre/jour
  description: Vous êtes dans la moyenne française, mais aussi dans celle du Soudan par exemple.

comparaison pays . mexique:
  titre: L'empreinte moyenne d'un habitant au Mexique
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 5000
      - empreinte eau < 5500
  formule: 5400
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen au Mexique.

comparaison pays . syrie:
  titre: L'empreinte moyenne d'un habitant en Syrie
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 5500
      - empreinte eau < 6000
  formule: 5800
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen en Syrie.

comparaison pays . grèce:
  titre: L'empreinte moyenne d'un habitant en Grèce
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 6000
      - empreinte eau < 6500
  formule: 6400
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen en Grèce.

comparaison pays . usa:
  titre: L'empreinte moyenne d'un habitant aux USA
  type: comparaison
  applicable si:
    toutes ces conditions:
      - empreinte eau > 6500
  formule: 7800
  unité: litre/jour
  description: Vous avez une empreinte proche de celle de l'habitant moyen aux Etats-Unis, 3e pays avec la plus grande empreinte eau par habitant, derrière la Bolivie et le Niger.

direct . description:
  titre: Description de l'empreinte directe
  description: Votre empreinte directe comprend toute l'utilisation d'eau que vous faites au jour le jour chez vous (douche, toilettes, etc).

indirect . description:
  titre: Description de l'empreinte indirecte
  description: Votre empreinte indirecte comprend toute l'eau nécessaire pour produire nos aliments, nos vêtements, produits numériques ou encore notre énergie. 

indirect . alimentation . reduire viande rouge:
  titre: Réduire sa consommation de viande rouge

indirect . alimentation . reduire viande rouge . max 1 par jour:
  type: action
  applicable si: plats . viande rouge . nombre > 7
  titre: Réduire la viande rouge à un repas par jour
  icônes: 🥩1️⃣
  formule: (plats . viande rouge . nombre - 7) * ( plats . viande rouge . empreinte - plats . végétarien . empreinte ) / unites . jours par semaine
  arrondi: oui
  unité: litre/jour
  description: |
    Vous consommez de la viande rouge presque à tous les repas ?


    Sachant que la viande rouge demande beaucoup d'eau à être produite, pour réduire facilement votre empreinte eau, vous pouvez limiter votre consommation de viande rouge à une fois par jour.

indirect . alimentation . reduire viande rouge . max 3 par semaine:
  type: action
  applicable si:
    toutes ces conditions:
      - plats . viande rouge . nombre > trois par semaine
      - plats . viande rouge . nombre < sept par semaine
  titre: Réduire la viande rouge à 3 fois par semaine
  icônes: 🥩
  formule: (plats . viande rouge . nombre - trois par semaine) * ( plats . viande rouge . empreinte - plats . végétarien . empreinte )  / unites . jours par semaine
  arrondi: oui
  unité: litre/jour
  description: |
    Vous consommez de la viande rouge presque une fois par jour ?


    Sachant que la viande rouge demande beaucoup d'eau pour être produite, pour réduire facilement votre empreinte eau, vous pouvez limiter votre consommation de viande rouge à seulement quelques fois par semaine.

trois par semaine:
  formule: 3
  unité: repas/semaine

sept par semaine:
  formule: 7
  unité: repas/semaine

indirect . alimentation . reduire cafe:
  type: action
  applicable si: boisson . chaude . café . nombre > 7
  titre: Réduire sa consommation de café
  icônes: ☕
  formule: ( boisson . chaude . café . nombre - 7 ) * ( boisson . tasse de café ) / unites . jours par semaine
  arrondi: oui
  unité: litre/jour
  description: Le café peut rapidement devenir un élément important de votre empreinte eau. Réduire sa consommation de caféine peut donc faire baisser votre score. Vous pouvez commencer en réduisant par exemple votre consommation à un café par jour.

indirect . alimentation . reduire fromage:
  type: action
  applicable si: desserts . fromage . nombre + petit déjeuner . fromage . nombre > 4
  titre: Réduire sa consommation de fromage
  icônes: 🧀
  formule: ( petit déjeuner . fromage . nombre + desserts . fromage . nombre - 4 ) * ( desserts . fromage . empreinte ) / unites . jours par semaine
  arrondi: oui
  unité: litre/jour
  description: Le fromage, tout comme le lait, nécessite beaucoup d'eau pour être fabriqué car il implique l'élevage d'animaux laitiers. Réduire sa consommation de fromage peut donc faire baisser votre score. Vous pouvez commencer en réduisant par exemple votre consommation à seulement quelques portions par semaine.

direct . douche . changer de pommeau:
  type: action
  applicable si: douche . pomme = non
  titre: Opter pour un pommeau de douche économe
  description:
    Pour réduire votre consommation d'eau directe, vous pouvez réduire votre débit de douche en utilisant un pommeau qui utilise moins d'eau.
  icônes: 🔄
  formule: douche . fréquence * douche . durée moyenne * (douche . fort debit - douche . faible debit) / unites . jours par semaine
  unité: litre/jour
  arrondi: oui

direct . vaisselle . acheter lave vaisselle:
  type: action
  applicable si: vaisselle . lave-vaisselle ou pas = non
  titre: Investir dans un lave-vaisselle
  description: Plus besoin de faire la vaisselle à la main, et une empreinte eau diminuée !
  icônes: 🧽
  formule: vaisselle / 2
  unité: litre/jour
  arrondi: oui

direct . douche . kit économie eau:
  type: action
  titre: Acheter un kit économies d'eau
  icônes: ❕
  applicable si: pomme = oui
  description:
    Avec ce kit vous pouvez réduire jusqu'à 60% de votre consommation d'eau venant de la douche et du robinet.
  formule: ( direct . douche ) * 0.5
  arrondi: oui
  unité: litre/jour

indirect . textile . acheter occasion:
  type: action
  applicable si:
    une de ces conditions:
      - tshirt . nombre > 8
      - pull . nombre > 4
      - pantalon . nombre > 4
  titre: Acheter plus de vêtements d'occasion
  description:
    Vous pouvez réduire votre empreinte eau en achetant certains de vos t-shirts, pulls ou pantalons en friperie par exemple.
  icônes: 🔁
  formule: tshirt . empreinte * tshirt . nombre / 2  + pantalon . empreinte * pantalon . nombre / 2  + pull . empreinte * pull . nombre / 2
  arrondi: oui
  unité: litre/jour

indirect . numerique . acheter reconditionné:
  type: action
  applicable si:
    une de ces conditions:
      - telephone . nombre > 1
      - tv plasma . nombre > 1
      - ordi portable . nombre > 1
  titre: Acheter plus de produits numériques reconditionnés
  description:
    Vous pouvez réduire votre empreinte eau en achetant des produits numériques reconditionnés. Les portables, télévisions ou ordinateurs reconditionnés sont facilement trouvables sur des sites spécialisés.
  icônes: 🔁
  formule: (telephone . empreinte * ( telephone . nombre / 2 )  + tv plasma . empreinte * ( tv plasma . nombre / 2 )  + ordi portable . empreinte * ( ordi portable . nombre / 2 )) / durée de vie moyenne
  arrondi: oui
  unité: litre/jour

indirect . numerique . acheter reconditionné . durée de vie moyenne:
  titre: Durée de vie moyenne des trois produits
  formule: 5
  unité: an

direct . jardin . recuperateur eau de pluie:
  type: action
  applicable si:
    toutes ces conditions:
     - jardin . présence = oui
     - jardin . surface > 2
     - jardin . récupérateur = non
  titre: Installer un récupérateur d'eau de pluie
  description: Récupérer l'eau de pluie peut vous être utile pour arroser votre jardin lorsqu'il ne pleut pas, ou pour laver votre voiture si vous en avez une.
  arrondi: oui
  formule: jardin
  icônes: 🌧️
  unité: litre/jour

direct . piscine . ne pas reremplir:
  type: action
  applicable si:
    toutes ces conditions:
     - piscine . présence = oui
  titre: Ne pas vider et re-remplir sa piscine chaque année.
  description: En ne remplissant votre piscine que tous les deux ans par exemple, vous économisez environ 30% de votre empreinte eau due à celle-ci.
  arrondi: oui
  formule: piscine * 0.3
  icônes: ⬇️
  unité: litre/jour

carte:
  titre: Textes de description pour la carte

carte . pays:
  titre: textes pour les différents pays

carte . pays . france:
  description: L'empreinte eau directe du quotidien vient de l'intérieur du pays, de nos principaux bassins, tout comme la majorité de l'eau due aux services societaux. On notera aussi qu'une grande partie de l'eau pour notre alimentation est française et plus on consomme local, moins cette eau virtuelle voyage. 

carte . pays . chine:
  description: Beaucoup de nos produits de consommation viennent de Chine, ce qui explique que ce soit l'un des principaux pays représentant notre empreinte eau. L'assemblage de nos produits électroniques, mais aussi la fabrication de polyester pour nos vêtements, tout cela a une empreinte élevée à cause des eaux polluées que cela rejette. Sur la carte, on peut observer pourtant que le pays a ses ressources en eau menacées. 

carte . pays . états-unis:
  description: Notre empreinte est aussi issue des Etats-Unis car ils nous exportent beaucoup de produits (électroniques, textiles, etc). Pour nos écrans par exemple, la majorité du marché du verre plat est américain.

carte . pays . belgique:
  description: La Belgique est le premier pays étranger qui nous fournit de l'eau virtuelle via nos produits alimentaires. En effet, de par la proximité géographique entre nos deux pays, il y a beaucoup d'échanges de nourriture, comme de la viande bovine à forte empreinte eau par exemple. 

carte . pays . espagne:
  description: Notre empreinte eau alimentaire est aussi représentée par l'Espagne, notamment avec les nombreux fruits et légumes qui viennent de là-bas. Pourtant, comme on le voit sur la carte, le pays subit de nombreuses sécheresses durant l'année, fragilisant ses ressources en eau. 

carte . pays . brésil:
  description: Le Brésil compte parmi les premiers pays qui nous fournissent en eau virtuellement, notamment à cause de leur exportation de nourriture comme le café ou les légumes. 

unites:
  titre: Unités
  description: Unités utilisées dans le simulateur

unites . mois par an:
  titre: Nombre de mois par an
  formule: 12
  unité: mois

unites . semaines par an:
  titre: Nombre de semaines par an
  formule: 52
  unité: semaine

unites . jours par semaine:
  titre: Nombre de jours par semaine
  formule: 7
  unité: jour/semaine

unites . jours par an:
  titre: Nombre de jours par an
  formule: 365
  unité: jour/an

unites . kWh to GJ:
  titre:
  formule: 0.0036
  unité: GJ/kWh


    `;

export default rules;




