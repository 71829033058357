import React, { useState } from "react";
import ActionComponent from "./ActionComponent";
import { numStr } from "../utils/FormatNumber";
import LoadingAnimation from "../utils/LoadingAnimation";

const ActionSection = ({ sectionName, actions, engine }) => {
  const hiddenElementsRef = LoadingAnimation();
  const [isInformationVisible, setIsInformationVisible] = useState(false);
  const [generalInfo, setGeneralInfo] = useState(
    engine.publicParsedRules[sectionName].rawNode
  );

  const handleInformation = () => { 
    setIsInformationVisible(!isInformationVisible);
    // console.log(engine.evaluate("direct"), 'actions');
    // console.log(sectionName, 'name');
  }


  const isDirect = sectionName.startsWith("direct .");


  const percentage = (
    (engine.evaluate(sectionName).nodeValue * 100) /
    engine.evaluate(isDirect ? "direct" : "indirect").nodeValue
  ).toFixed(0);

  const width = `${
    (engine.evaluate(sectionName).nodeValue * 100) /
    engine.evaluate(isDirect ? "direct" : "indirect").nodeValue * 2
  }%`;

  return (
    <div className="action-section hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
      <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 5px #b91c1c', borderRadius: '25px', padding: '1rem' }}>
        <div className="agir">
          <div className="comment-agir-titre">
            <div className="agir-percentage-container">
              <strong className="agir-blue-title">
                <div className="">{generalInfo.icônes}</div> {generalInfo.titre} 
                <img 
                  src='/img/info.svg' 
                  className='info-action' 
                  onClick={handleInformation} 
                  alt="Info icon"
                />
              </strong> 
              <br />
              <div className="percentage-text-agir">
                {percentage} % de mon empreinte { isDirect ? "directe" : "indirecte"}
                <br />
                <div
                  className="agir-percentage"
                  style={{
                    width: width,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="action-valeur">
            <div className="valeur-agir">{numStr(engine.evaluate(sectionName).nodeValue, ' ')} L/jour</div>
          </div>
        </div>
        {isInformationVisible && (
          <div>
            <p className="empreinte-info">
              <img src='/img/idee.png' className="idee-img" alt="Idea icon"></img>
              {generalInfo.description_action}
            </p>
          </div>
        )}
      </div>
      <div className="agir-content">
        <h2 className="action-title">Les actions</h2>
        <div className="actions-list">
          {actions.map((actionName, index) => (
            <ActionComponent
              key={index}
              actionName={actionName}
              engine={engine}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionSection;
