import React from "react";
import { numStr } from "./utils/FormatNumber";

// Define image and color mappings
const imageMap = {
  numerique: '/img/numerique.png',
  textile: '/img/textile.png',
  "services societaux": '/img/services_societaux.png',
  alimentation: '/img/alimentation.png',
  logement : '/img/construction.png',
  energie: '/img/nrj.png',
  direct: '/img/robinet.png'
};

const EmpreintePaysDetails = ({ service_country, alim_country, textile_country, numerique_country, logement_country, energie_country, direct_country, total }) => {
  const categories = [
    { key: 'services societaux', value: service_country },
    { key: 'alimentation', value: alim_country },
    { key: 'textile', value: textile_country },
    { key: 'numerique', value: numerique_country },
    { key: 'logement', value: logement_country },
    { key: 'energie', value: energie_country},
    { key: 'direct', value: direct_country}
  ];

  // Sort categories by value in descending order and limit to top 4
  const sortedCategories = categories
    .filter(category => imageMap[category.key] && category.value != 0)
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);

  return (
    <div className="country-footprint-details" style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
      {sortedCategories.map((category, index) => {
        const imgSrc = imageMap[category.key];
        const proportionalWidth = index === 0 ? 100 : (category.value / total) * 95;

        return (
          <div className="footprint-item" key={category.key}>
            <div className="comprendre-empreinte-pays-map">
              <img src={imgSrc} className="comprendre-empreinte-pays-img" />
              <div className="graph-title-and-results-container">
                <div className="graph-results-title">
                  <strong>{category.key}</strong>
                </div>
                <div
                  className="page-map-percentage-bar"
                  style={{
                    width: `${proportionalWidth}%`,

                  }}
                ></div>
                {numStr(category.value)} L/Jour
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmpreintePaysDetails;
