import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSituation } from "./SituationManager";
import { getInitSituation } from "./utils/InitialSituation";


const Header = () => {
  const navigate = useNavigate();

  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const { situation, updateSituation } = useSituation();


  var userStatus = 'first_time'
  if (situation) {
    if (situation.simulation.is_finish) {
      userStatus = 'finish'
    } else {
      userStatus = 'in_progress'
    }
  };

  const initSituation = getInitSituation();


  const handleEmpreinteClick = () => {
    resetMenu();
    if (userStatus=='finish') {
      navigate("/end_result");
    } else if (userStatus=='in_progress') {
      navigate("/question/"+situation.simulation.foldedSteps[situation.simulation.foldedSteps.length-1]); 
    } else if (userStatus=='first_time') {
      updateSituation(initSituation);
      navigate("/question/direct . nombre de personnes");
    }
  };

  const handleLogoClick = () => {
    resetMenu();
    navigate("/");
  };

  const handleGlobeClick = () => {
      resetMenu();
      navigate("/map");
    
  }

  const handleProposClick = () => {
    resetMenu();
    navigate("/propos");
  }

  const resetMenu = () => {
    setIsSliderVisible(false);
    document.body.classList.remove("nav-open");
  };
  

  useEffect(() => {
    const menuToggle = document.getElementById("menu-toggle");

    const handleMenuToggle = () => {
      document.body.classList.toggle("nav-open");
      setIsSliderVisible((prevState) => !prevState);
    };

    if (menuToggle) {
      menuToggle.addEventListener("click", handleMenuToggle);
    }

    // Cleanup function to run when the component unmounts
    return () => {
      if (menuToggle) {
        menuToggle.removeEventListener("click", handleMenuToggle);
      }
      // Reset the state and remove the class when unmounting
      setIsSliderVisible(false);
      document.body.classList.remove("nav-open");
    };
  }, []);



  return (
    <div className="header-container">
      <header className="header">
        <div className="header-left">
          <img
            src='/img/logo-white.png'
            alt="Logo Mon Empreinte eau : Le calculateur d'empreinte eau individuelle"
            className="compte-gouttes-icon"
            onClick={handleLogoClick}
          ></img>

          <div className="header-content" onClick={handleEmpreinteClick}>
            <img src='/img/liste_icon.svg' alt="logo calculatrice" className="liste-icon" ></img>Mon empreinte
          </div>

          <a className="header-content no-link" href="/map">
            <img src='/img/globe.svg' alt="logo carte" className="liste-icon" ></img>Ma carte
          </a>

          <a className="header-content no-link" href = 'https://blog.mon-empreinte-eau.fr'>
            <img src='/img/blog.png' alt="logo blog" className="liste-icon" ></img>Blog
          </a>
        </div>

        <div className="header-right">
          <a className="header-content no-link" href="/a-propos">
            À propos
          </a>

          <a id="menu-toggle" className="menu-toggle">
            <span className="menu-toggle-bar menu-toggle-bar--top"></span>
            <span className="menu-toggle-bar menu-toggle-bar--middle"></span>
            <span className="menu-toggle-bar menu-toggle-bar--bottom"></span>
          </a>
        </div>
      </header>

      <div
        className="header-slider"
        style={{ display: isSliderVisible ? "block" : "none" }}
      >
        <div className="header-slider-container">
          <div className="header-slider-item" onClick={handleEmpreinteClick}>
            <img
              src='/img/liste_icon.svg'
              alt="logo calculatrice"
              className="profile-icon-phone"
            ></img>
            Empreinte
          </div>
          <a className="header-slider-item no-link" href='https://blog.mon-empreinte-eau.fr'>
            <img
              src='/img/blog.png'
              alt="logo blog"
              className="profile-icon-phone"
            ></img>
            Blog
          </a>
          <a className="header-slider-item no-link"  href="/map">
            <img
              src='/img/globe.svg'
              alt="logo carte"
              className="profile-icon-phone"
            ></img>
            Ma carte
          </a>
          <a className="header-slider-item no-link" href="/a-propos">
            À propos
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
