import React, { useState } from 'react';
import { useEngine } from './EngineManager';





const PublicodeComponent = () => {
  const [result, setResult] = useState(null);
  const [duree, setDuree] = useState('');
  const [douchesParSemaine, setDouchesParSemaine] = useState('');
  const [economiseur, setEconomiseur] = useState('non');
  const engine = useEngine()

  const calculateConsumption = () => {
    
    engine.setSituation({
      'douche . durée moyenne': `${duree}`,
      'douche . fréquence': `${douchesParSemaine}`,
      'douche . pomme de douche économe': economiseur,
    });
    const evaluation = engine.evaluate('douche');
    setResult(evaluation.nodeValue);
    const questions = engine.getParsedRules()
    //console.log(questions)
  };

  return (
    <div>
      <h1>Calculateur de Consommation d'Eau pour la Douche</h1>
      <div>
        <label>
          Durée de la douche (minutes) :
          <input type="number" value={duree} onChange={(e) => setDuree(e.target.value)} />
        </label>
      </div>
      <div>
        <label>
          Nombre de douches par semaine :
          <input type="number" value={douchesParSemaine} onChange={(e) => setDouchesParSemaine(e.target.value)} />
        </label>
      </div>
      <div>
        <label>
          Économiseur d'eau :
          <select value={economiseur} onChange={(e) => setEconomiseur(e.target.value)}>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </select>
        </label>
      </div>
      <button onClick={calculateConsumption}>Calculer</button>
      {result !== null && (
        <div>
          <h2>Résultat:</h2>
          <p>{result} L/semaine</p>
        </div>
      )}
    </div>
  );
};

export default PublicodeComponent;

