import React, { useState } from 'react';
import { numStr } from '../utils/FormatNumber';

const EquivalentSection = ({ footprintResult, equivalentList }) => {
    const [equivalent, setEquivalent] = useState({
        "equivalent . bouteille cristaline": "cristaline",
        "equivalent . bain": "bain",
        "equivalent . piscine": "piscine",
        "equivalent . rdc": "rdc",
        "equivalent . chine": "chine",
        "equivalent . uk": "uk",
        "equivalent . japon": "japon",
        "equivalent . algérie": "algérie",
        "equivalent . soudan": "soudan",
        "equivalent . mexique": "mexique",
        "equivalent . syrie": "syrie",
        "equivalent . grèce": "grèce",
        "equivalent . usa": "usa",
    });

    return (
        <div className="results-info">
            <div className="results-equivalent" style={{fontWeight:'bold'}}>
                {numStr(footprintResult['globalFootprint'], ' ')} L/jour c'est l'équivalent de :
            </div>
            <div>
                {equivalentList.slice(0, 3).map((elem) => (
                    <div key={elem.id} className="results-equivalent">
                      
                        <img
                            src={`/img/${equivalent[elem.key]}.png`}
                            className="page-results-img"
                            alt="Icon"
                        />
                        <div>{numStr(elem.value,' ')} {elem.rawNode.titre}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EquivalentSection;
