import React from "react";
import { numStr } from "./utils/FormatNumber";

const imageMap = {
  numerique: "/img/numerique.png",
  nrj: "/img/nrj.png",
  textile: "/img/textile.png",
  services_societaux: "/img/services_societaux.png",
  alimentation: "/img/alimentation.png",
  wc: "/img/wc.png",
  douche: "/img/douche.png",
  cuisine: "/img/cuisine.png",
  vaisselle: "/img/vaisselle.png",
  bain: "/img/bain.jpeg",
  robinet: "/img/robinet.png",
  linge: "/img/linge.png",
  voiture: "/img/voiture.png",
  animaux: "/img/animaux.png",
  jardin: "/img/jardin.png",
  piscine: "/img/piscine.png",
  terrasse: "/img/terrasse.png",
  hydratation: "/img/hydratation.png",
};

const Title = {
    numerique: "Numerique",
    nrj: "Energie",
    textile: "Textile",
    services_societaux: "Services sociétaux",
    alimentation: "Alimentation",
  };

const GraphiqueVague = ({ type, result, height }) => {


  function removePrefix(key) {
    return key.replace(/^(direct|indirect)\s*\.\s*/, "");
  }

  function isIndirect(key) {
    const excludedKeys = [
      "direct . moyenne",
      "indirect . services societaux . construction",
      "indirect . services societaux . sante",
      "indirect . services societaux . fuites",
    ];
    //console.log(height,'HEIGHT');
    return key.startsWith("indirect .") && !excludedKeys.includes(key);
  }

  function isDirect(key) {
    const excludedKeys = ["direct . moyenne"];
    return key.startsWith("direct .") && !excludedKeys.includes(key);
  }

  return (
    <div className="graphique-vague-container">
      <div
  className="results-en-vague" style={{display:'flex',flexDirection:'column',gap:'1rem',alignItems:'center'}}
  
>
  {Object.entries(result).map(([key, value]) => {
    const isMatchingType =
      type === "direct" ? isDirect(key) : isIndirect(key);
    if (isMatchingType) {
      const shortKey = removePrefix(key);
      const imgSrc = imageMap[shortKey.replace(/\s+/g, "_")];
      const title = Title[shortKey.replace(/\s+/g, "_")];

      // Fonction pour calculer la valeur de la pseudo-margin-top
      const calculatePseudoMarginTop = (value) => {
        if (value >= 2500) {
          return 95; 
        } else if (value === 0) {
          return 0; 
        } else {
        
          return Math.max((value / 2500) * 95, 30);
        }
      };
      

      const pseudoMarginTop = calculatePseudoMarginTop(value);
      //console.log(pseudoMarginTop,'pseudomargintop',key)

      return (
        <div className="detail-footprint-question-container">
          {imgSrc && (type !== "direct" || value > 1) && (
            <div className={``}>
              
              <div className="tooltip-box">
              <div className="tooltip" style={{ '--pseudo-margin-top': `${(pseudoMarginTop )}%` }}>
                <div className="empreinte-eau-type">
                  {title}<br/>{numStr(value)} L
                </div></div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  })}
</div>


     

      
    </div>
  );
};

export default GraphiqueVague;
