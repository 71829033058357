import React, { useState, useEffect } from "react";
import { useSituation } from "./SituationManager";
import { csv } from "d3-fetch";
import Flag from 'react-world-flags';
import EmpreintePaysDetails from "./EmpreintePaysDetails";
import { numStr } from "./utils/FormatNumber";
import { useEngine } from "./EngineManager";

const getDetailByKey = (details, key) => {
  const detail = details.find((detail) => detail.key === key);
  return detail ? detail.value : 0;
};

const calculateTotalValue = (countryData, footprint) => {
  const alimentationFootprint = getDetailByKey(footprint["detail"], "alimentation");
  const textileFootprint = getDetailByKey(footprint["detail"], "textile");
  const numeriqueFootprint = getDetailByKey(footprint["detail"], "numerique");
  const serviceFootprint = getDetailByKey(footprint["detail"], "services societaux");
  const nrjFootprint = getDetailByKey(footprint["detail"], "nrj");
  const directFootprint = footprint["directFootprint"];
  // const interieureFootprint = getDetailByKey(footprint["detail"], "services societaux") + footprint["directFootprint"] + getDetailByKey(footprint["detail"], "nrj");
  const constructionFootprint = getDetailByKey(footprint["detail"], "construction");
  const parseValue = (value) => {
    if (typeof value === "string") {
      return parseFloat(value.replace(",", "."));
    }
    return parseFloat(value || 0);
  };

  const alimentation = parseValue(countryData.Alimentation);
  const textile = parseValue(countryData.Textile);
  const numerique = parseValue(countryData.Numérique);
  const interieure = parseValue(countryData.Intérieure);
  const logement = parseValue(countryData.Construction);

  const alim_country = alimentation * alimentationFootprint;
  const textile_country = textile * textileFootprint;
  const numerique_country = numerique * numeriqueFootprint;
  const service_country = interieure * serviceFootprint;
  const logement_country = logement * constructionFootprint;
  const energie_country = interieure * nrjFootprint;
  const direct_country = interieure * directFootprint;


  const total = alim_country + textile_country + numerique_country + energie_country + logement_country + direct_country + service_country;

  return { service_country, alim_country, textile_country, numerique_country, logement_country, energie_country, direct_country, total };
};

const getTopCountries = async (footprintResult) => {
  const data = await csv(`/Parts_des_pays_dans_wf.csv`);
  const countryFootprints = data.map((country) => {
    const { service_country, alim_country, textile_country, numerique_country, logement_country, energie_country, direct_country, total } = calculateTotalValue(country, footprintResult);
    return { pays: country.Pays ,country: country.Pays2, code: country.Code, service_country, alim_country, textile_country, numerique_country, logement_country, energie_country, direct_country, total };
  });

  const sortedCountries = countryFootprints.sort((a, b) => b.total - a.total);
  return sortedCountries.slice(0, 5);
};

const ComprendreEmpreintePays = ({ mapType }) => {
  const engine = useEngine();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const { situation } = useSituation();
  const [topCountries, setTopCountries] = useState([]);

  useEffect(() => {
    const fetchTopCountries = async () => {
      const topCountries = await getTopCountries(situation.simulation.footprintResult);
      setTopCountries(topCountries);
    };

    fetchTopCountries();
  }, [situation.simulation.footprintResult, mapType]);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="comprendre-results-container">
      {topCountries.map((element, index) => (
        <div className="comprendre-results-box" key={index}>
          <div className="comprendre-results">
          <Flag
            code={element.code} 
            alt={element.pays}
            style={{ width: '50px', height: '30px' }} 
            onClick={ () => console.log(engine.publicParsedRules,'TEST')}
          />

            <div className="graph-title-and-results-container">
              <div className="graph-results-title"><strong>{element.pays}</strong></div>
              <div>{numStr(element.total.toFixed(0))} L/Jour</div>
            </div>
            <img
              src='img/info.svg'
              className="graph-results-img"
              onClick={() => handleToggle(index)}
              alt="More Info"
            />
          </div>

          {expandedIndex === index && (
            <div>
              { engine.publicParsedRules[`carte . pays . ${element.pays.toLowerCase()}`] && <div className="comprendre-plus-intro">{engine.publicParsedRules[`carte . pays . ${element.pays.toLowerCase()}`]?.rawNode.description}</div>}
              <EmpreintePaysDetails 
                service_country={element.service_country}
                alim_country={element.alim_country}
                textile_country={element.textile_country}
                numerique_country={element.numerique_country}
                logement_country={element.logement_country}
                energie_country={element.energie_country}
                direct_country={element.direct_country}
                total={element.total}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const EmpreintePays = ({ mapType }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const { situation } = useSituation();
  const [topCountries, setTopCountries] = useState([]);

  useEffect(() => {
    const fetchTopCountries = async () => {
      const topCountries = await getTopCountries(situation.simulation.footprintResult);
      setTopCountries(topCountries);
      // console.log(topCountries,'TEST')
    };

    fetchTopCountries();
  }, [situation.simulation.footprintResult, mapType]);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="empreinte-par-pays-container">
      
        
          

          <div className="empreinte-par-pays-div">
            <Flag
              code='IN'
              alt='Inde'
              style={{ width: '50px', height: '30px' }} 
            />
            <div className="graph-title-and-results-container">
              <div className="graph-results-title"><strong>Inde</strong></div>
              <div>3 000 L/Jour</div>
            </div>
            
          </div>

          <div className="empreinte-par-pays-div">
            <Flag
              code='US'
              alt='Etats-Unis'
              style={{ width: '45px', height: '30px' }} 
            />
            <div className="graph-title-and-results-container">
              <div className="graph-results-title"><strong>États-Unis</strong></div>
              <div>7 800 L/Jour</div>
            </div>
            
          </div>

        
       
     
    </div>
  );
};

export { ComprendreEmpreintePays, EmpreintePays };
