import React, { useState, useEffect } from 'react';

const BoolQuestion = ({ questionId, questionData, answer, setAnswer, situation, updateSituation, engine, updateResult}) => {
    // const [answer, setAnswer] = useState(situation.simulation.simulationAnswers[questionId] || '');

    useEffect(() => {
        setAnswer(situation.simulation.simulationAnswers[questionId] || '')},
        [questionId]
    )

    
    const UpsertAnswerSituation = (answerValue) => {   
        setAnswer(answerValue); 

        // Update situation
        const newSituation = { ...situation };
        const key = questionId;
        newSituation.simulation.simulationAnswers[key] = answerValue;   
        //console.log(newSituation); 
        updateSituation(newSituation);

        // Update engine
        engine.setSituation(situation.simulation.simulationAnswers);
        updateResult()

    };

    return (
        <div>
            <div className='choices_container'>
                <button
                    key='oui'
                    onClick={() => UpsertAnswerSituation('oui')}
                    className={answer === 'oui' ? 'selected' : 'not-selected'}
                >
                    <input
                id="les-plus-recent"
                type="checkbox"
                value=""
                className='checkbox-round'
                checked={answer === 'oui'}
                
              />
                    Oui
                </button>

                <button
                    key='non'
                    onClick={() => UpsertAnswerSituation('non')}
                    className={answer === 'non' ? 'selected' : 'not-selected'}
                >
                    <input
                id="les-plus-recent"
                type="checkbox"
                value=""
                className='checkbox-round'
                checked={answer === 'non'}
                
              />
                    Non
                </button>
            </div>
        </div>
    );
};

export default BoolQuestion;
