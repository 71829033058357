import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { csv } from "d3-fetch";
import { useSituation } from "../SituationManager";
import Map from "../graph/Map";
import { ComprendreEmpreintePays } from "../ComprendreEmpreintePays";
import AdvancedMap from "../graph/AdvancedMap";
import { numStr } from "../utils/FormatNumber";
import { getInitSituation } from "../utils/InitialSituation";

const MapPage = () => {
  const [content, setContent] = useState({ Pays: '', Empreinte: '', Stress: '' });
  const { situation, updateSituation } = useSituation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [franceFootprint,setFranceFootprint] = useState(50);
  const [footprintResult, setFootprintResult] = useState({
    globalFootprint: 0,
    directFootprint: 0,
    indirectFootprint: 0,
    detail: [],
  });

  var userStatus = 'first_time'
  if (situation) {
    if (situation.simulation.is_finish) {
      userStatus = 'finish'
    } else {
      userStatus = 'in_progress'
    }
  };

  const initSituation = getInitSituation();


  const handleQuestionnaireClick = () => {
    if (userStatus=='finish') {
      navigate("/end_result");
    } else if (userStatus=='in_progress') {
      navigate("/question/"+situation.simulation.foldedSteps[situation.simulation.foldedSteps.length-1]); 
    } else if (userStatus=='first_time') {
      updateSituation(initSituation);
      navigate("/question/direct . nombre de personnes");
    }
  };

  useEffect(() => {
    csv(`/Parts_des_pays_dans _wf.csv`).then((data) => {
      setData(data);
    });
  }, []);

  useEffect(() => {
    if (situation) {
      setFootprintResult(situation.simulation.footprintResult)
    }
  }, [situation]);

  const handleMouseMove = (event) => {
    setMouseX(event.pageX);
    setMouseY(event.pageY);
  };

  return (
    <div>{ !situation || !situation.simulation.is_finish ? (
      <div className="blurmap-background-container">
      <div className="content">
        <h1>Vous n'avez pas fini le questionnaire.</h1>
        <p style={{color:'var(--hydrosblue)', fontWeight:'bold'}}>Répondez à toutes les questions pour avoir votre carte exacte !</p>
        <button className='boutton-commencer' onClick={handleQuestionnaireClick}>Aller au questionnaire</button>
      </div>
    </div>
    )
    :
    (<div className="map" data-tooltip-id="map-tooltip" id='map'>
      <div
        style={{ width: "90%", marginLeft: "auto", marginRight: "auto", display:'flex',flexDirection:'column',alignItems:'center'}}
        onMouseMove={handleMouseMove}
      >
        <h1 className="paragraph1-title">La carte de votre empreinte eau</h1>
        <p className="introduction-map">
          Chaque jour à travers vos achats, ce que vous mangez, ce que vous
          portez... vous utilisez de l’eau à travers le monde. Explorez vos
          données pour comprendre dans quels pays vous avez un impact sur la
          ressource en eau.
        </p>

        <div className="percentage-box">
          <div style={{ fontSize: "30px", textAlign: "center",gap:'2rem' }}>
            <strong>{numStr(footprintResult["globalFootprint"], ' ')} L/Jour</strong>
          </div>

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div className="results-map">
              <img src="/img/flags/france.png" className="graph-results-img" />
              <div className="graph-title-and-results-container">
                <div className="graph-results-title">
                  <strong>{franceFootprint}% en France</strong>
                </div>
                <div style={{width :`${franceFootprint * 1.5}%`}}className="page-map-percentage-bar"></div>
              </div>
            </div>
            <div className="results-map">
              <img src="/img/étranger.png" className="graph-results-img" />
              <div className="graph-title-and-results-container">
                <div className="graph-results-title">
                  <strong>{(100 - franceFootprint).toFixed(0)}% à l'étranger</strong>
                </div>
                <div style={{width :`${(100 - franceFootprint) * 1.5}%`}} className="page-map-percentage-bar"></div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
        <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
      <h3 className="map-title">Mon empreinte eau par pays</h3>
        <Map   /></div>
      
      <div style={{ backgroundColor: "#205673",  color:'white', paddingBottom:'0.5rem', paddingTop:'0.5rem'}}>
        <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
          <h1 style={{ color: "white" }} className="paragraph1-title">
            Comment ça marche ?
          </h1>
          <p className="white-paragraph">Afin de déterminer la provenance de l’eau que nous utilisons, nous regardons l’origine des composants de nos produits consommateurs d’eau ainsi que les différentes étapes de fabrication. 
            Nous prenons ici l'exemple d'un t-shirt, nous regardons sa composition moyenne, l’origine des matériaux qui le composent et les lieux de fabrication.</p>
          <img src="/img/tshirt-empreinte-map.jpg" className="tshirt-map"></img>
        </div>
      </div>

      <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto"}}>
      <h1 className="map-title">Voici le détail par catégorie de votre empreinte.</h1>
      <div className='green-yellow-purple-gray-map-container'>
        <div className='green-yellow-purple-gray-map'>
        <h3 className="map-title">Votre empreinte eau alimentaire</h3>
        <Map   mapType='alimentation' ></Map></div>
        <div className='green-yellow-purple-gray-map'>
        <h3 className="map-title" >Votre empreinte eau textile</h3>
        <Map   mapType='textile'  ></Map></div>
      </div>
      <div className='green-yellow-purple-gray-map-container'>
        <div  className='green-yellow-purple-gray-map'>
        <h3 className="map-title" calculateType='CalculateService'>Votre empreinte eau liée au logement</h3>
        <Map   mapType='logement'   ></Map></div>
        <div className='green-yellow-purple-gray-map'>
        <h3 className="map-title">Votre empreinte eau numérique</h3>
        <Map   mapType='numerique'  ></Map></div>
      </div>
      <div><p className="empreinte-info">
        <img src='/img/idee.png' className="idee-img"></img>
        Consommer de l'eau n'est pas grave en soi, l'eau sur terre fonctionne en cycle fermé. Ainsi l'eau consommée à un endroit sera par exemple évaporée et reviendra plus tard et à un autre endroit sous forme de pluie.
        Ce qui est problématique pour l'homme, c'est de consommer de l'eau dans des endroits où il y en a très peu. Ainsi vous privez les autres usagers d'eau avec votre action.
        Pour visualiser cela, nous avons croisé dans la carte ci-dessous, votre empreinte eau par pays et leur indice de stress hydrique. Cet indicateur fourni par le World Ressource Institute permet de quantifier le manque d'eau dans les différents pays du globe.</p>
      </div>
      </div>
      <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" ,marginBottom:'30px'}}>
      <h1 className="paragraph1-title">Comprendre les pays avec la plus grosse empreinte</h1>

      <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
      <h3 className="map-title">Mon empreinte eau par pays</h3>
      <div className="legend-container"><span>Stress hydrique  </span> 0<div id="progress-bar-container">
		<div class="progress-bar-child progress"></div>
</div> 90</div>

<div className="legend-container"><span>Empreinte eau  </span>30<div class="small-circle-legend"></div><div class="circle-legend"></div>
 250 L/jour</div>

        <AdvancedMap setFranceFootprint={setFranceFootprint} doNotShow='France' tailleMarkers={2.5}/></div>

      <ComprendreEmpreintePays footprintResult={footprintResult}></ComprendreEmpreintePays>
      <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
        <a href="https://blog.mon-empreinte-eau.fr/notre-methodologie">
          <button className="boutton-commencer">
            Notre Méthodologie
          </button>
        </a>
      </div>
      </div>

      
    </div>
    )}
  </div>
  );
};

export default MapPage;
