// PublicodeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Engine from 'publicodes';
import { parse } from 'yaml';
import rules from './rules';


const EngineContext = createContext();

export const EngineProvider = ({ children }) => {
  const [engine, setEngine] = useState(new Engine());


  useEffect(() => {
    // Fonction pour charger le fichier data.publicode
    const parsed_rules = parse(rules)
    const newEngine = new Engine(parsed_rules);
    setEngine(newEngine);
  }, []);


  return (
    <EngineContext.Provider value={engine}>
      {children}
    </EngineContext.Provider>
  );
};

export const useEngine = () => {
  return useContext(EngineContext);
};
