import React from 'react';

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-title">FAQ - Vos questions sur l'outil et l'empreinte eau</h1>
      <div className='faq-button-container'>
        <a className="no-link" href="https://mon-empreinte-eau.fr/"> 
          <div className="boutton-commencer">Accéder au calculateur d'empreinte eau</div>
        </a>
      </div>
      
      <div className="faq-item">
        <h2 className="faq-question">Qu'est-ce que c'est l'empreinte eau ?</h2>
        <p className="faq-answer">
        L'<b>empreinte eau</b> est la <b>quantité totale d'eau utilisée, directement et indirectement, pour produire les biens et services qu'une personne consomme chaque jour</b>. Elle peut être mesurée à l'échelle de l'individu, d'une entreprise, ou d'un pays. Elle se divise généralement entre <b>empreinte directe</b> (eau pour la douche, du robinet, ou pour arroser les plantes) et <b>empreinte indirecte</b> (eau nécessaire pour faire pousser les aliments qu'on mange, ou pour les vêtements qu'on porte). De plus en plus utilisée comme critère pour mesurer l'impact environnemental, l'<b>empreinte eau</b> est un bon indicateur de l'utilisation des ressources en eau.
        </p>
        <a href="https://blog.mon-empreinte-eau.fr/comprendre-l-empreinte-eau" className="faq-link">Pour plus d'informations sur l'empreinte eau, voici un article qui en fait une introduction plus détaillée.</a>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Comment calculer son empreinte eau ?</h2>
        <p className="faq-answer">
          Il existe différentes méthodes pour mesurer l'<b>empreinte eau</b>. On peut par exemple prendre en compte la rareté de l'eau où elle a été consommée, ou bien regarder la pollution de l'eau douce induite par notre consommation. Ces deux méthodes sont respectivement celles créées par WULCA et par le <b>Water Footprint Network</b>. Pour obtenir en quelques minutes votre empreinte eau, vous pouvez aller sur notre outil <a href='https://mon-empreinte-eau.fr/'>Mon Empreinte Eau</a> !
        </p>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Quelle est l'empreinte eau moyenne d’un français ? Pourquoi n'est-ce pas 5 000 litres par jour comme on peut l’entendre ?</h2>
        <p className="faq-answer">
          En France, l'empreinte eau moyenne obtenue avec <b>notre calculateur</b> est d'environ <b>6 000 litres par jour par personne</b>. Celle-ci est composée de 150 litres pour l'empreinte directe et de 5 850 litres pour l'empreinte indirecte. D'autres études penchent plutôt vers une empreinte moyenne de 5 000 litres par jour par personne, mais elles ne prennent pas en compte l'empreinte des services sociétaux.
        </p>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Comment réduire son empreinte eau ?</h2>
        <p className="faq-answer">
          Pour l'<b>empreinte directe</b>, on peut consommer l'eau de manière plus responsable, en privilégiant par exemple un lave-linge ou un lave-vaisselle à faible consommation d'eau. Pour la partie indirecte, on peut limiter sa consommation de viande rouge et de produits animaliers, ou encore acheter moins de produits neufs.
        </p>
        <a href="https://blog.mon-empreinte-eau.fr/empreinte-eau-directe-et-indirecte" className="faq-link">Pour en savoir plus sur les leviers d'action possibles pour réduire son empreinte eau, découvrez cet article pour mieux comprendre l'eau directe et indirecte !</a>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Pourquoi calculer son empreinte eau ?</h2>
        <p className="faq-answer">
          Contrairement à l'empreinte carbone, l'empreinte eau ne connaît pas d'objectif individuel de réduction. L'objectif d'un calculateur d'empreinte eau est de <b>prendre conscience de notre utilisation quotidienne de l'eau</b> et de voir quelles actions consomment plus d'eau que d'autres.
        </p>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Limites de notre outil</h2>
        <p className="faq-answer">
          Le <b>calculateur "Mon Empreinte Eau"</b> a été créé pour sensibiliser le grand public à l'impact de l'être humain sur l'eau et aux enjeux liés à l'eau dans le monde. Cet outil est une approche de la vraie <b>empreinte eau</b> individuelle de chacun, mais il pourrait toujours être amélioré en affinant nos questions.
        </p>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Qu'est-ce que l'eau bleue ?</h2>
        <p className="faq-answer">
          L'<b>eau bleue</b> fait référence à <b>l'eau douce prélevée dans les ressources en surface ou souterraines</b>, comme les rivières, les lacs ou les aquifères. Elle est généralement utilisée pour l'irrigation dans l'agriculture, les usages domestiques (comme l'eau potable ou l'eau de douche) et les usages industriels. 
          La gestion de l'<b>eau bleue</b> est essentielle pour garantir la durabilité des ressources en eau, en particulier dans les régions où ces ressources sont limitées ou soumises à un stress hydrique important. Une gestion inefficace de l'eau bleue peut entraîner des <b>pénuries d'eau</b>, affectant ainsi les écosystèmes et les populations humaines.
        </p>
        <a href="https://blog.mon-empreinte-eau.fr/les-couleurs-de-lempreinte-eau" className="faq-link">Pour en svaoir plus sur les différentes couleurs de l'empreinte eau, voici un article détaillant ce sujet !</a>

      </div>

      <div className="faq-item">
        <h2 className="faq-question">Qu'est-ce que l'eau verte ?</h2>
        <p className="faq-answer">
          L'<b>eau verte</b> correspond à l'eau de pluie qui est stockée dans le sol sous forme d'humidité et qui est ensuite absorbée par les plantes. Elle est principalement utilisée dans l'agriculture pluviale, où les cultures dépendent de l'eau de pluie plutôt que de l'irrigation. 
          L'<b>empreinte eau verte</b> est particulièrement importante pour les <b>productions agricoles et sylvicoles</b>. En comprenant et en gérant l'eau verte, on peut mieux optimiser l'utilisation des ressources en eau disponibles et réduire la pression sur les réserves d'eau bleue.
        </p>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Qu'est-ce que l'eau grise ?</h2>
        <p className="faq-answer">
          L'<b>eau grise</b> désigne l'eau polluée qui résulte de l'utilisation domestique, industrielle ou agricole, et qui nécessite un traitement avant de pouvoir être réutilisée ou rejetée dans l'environnement. Dans le contexte de l'<b>empreinte eau</b>, l'eau grise est utilisée pour quantifier <b>la quantité d'eau nécessaire pour diluer les polluants afin de respecter les normes de qualité de l'eau</b>. 
          Une <b>empreinte eau grise</b> élevée indique une <b>pollution</b> significative de l'eau, nécessitant un traitement important pour éviter d'endommager les écosystèmes aquatiques. Réduire son empreinte eau grise est donc crucial pour minimiser l'impact environnemental de notre consommation d'eau.
        </p>
      </div>

    </div>
  );
};

export default FAQ;
