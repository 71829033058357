import { useEffect, useRef } from "react";

const LoadingAnimation = () => {
  const hiddenElementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });

    const hiddenElements = hiddenElementsRef.current;
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return hiddenElementsRef;
};

export default LoadingAnimation;
