import React, { useState, useEffect } from 'react';

const QcmQuestion = ({ questionId, questionData, answer, setAnswer, situation, updateSituation, engine, updateResult }) => {
    // const [answer, setAnswer] = useState(situation.simulation.simulationAnswers[questionId] || '');

    useEffect(() => {
        setAnswer(situation.simulation.simulationAnswers[questionId] || '')},
        [questionId]
    )

    const UpsertAnswerSituation = (answerValue) => {
        //console.log(answerValue)
        setAnswer(answerValue);

        // Update situation
        const newSituation = { ...situation };
        newSituation.simulation.simulationAnswers[questionId] = answerValue;
        updateSituation(newSituation);

        // Update engine
        engine.setSituation(newSituation.simulation.simulationAnswers);
        updateResult();
    };

    return (
        <div>
            <div className='choices_container'>
                {/* <p>{questionData.question}</p> */}
                {questionData.formule['une possibilité'].possibilités.map((option, index) => (
                    
                    <button
                        key={index}
                        onClick={() => UpsertAnswerSituation(`'`+option+`'`)}
                        className={`button-qcm ${answer === `'`+option+`'` ? 'selected' : 'not-selected'}`}
                    >
                        <input
                id={`option-${index}`}
                type="checkbox"
                max="100000"
                value=""
                className='checkbox-round'
                checked={answer === `'`+option+`'`}
                
              />
                        {option}
                    </button>
                ))}
            </div>
            
        </div>
    );
};

export default QcmQuestion;
