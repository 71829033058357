import React, { useState, useEffect } from 'react';

const MosaicChoicesQuestion = ({ questionId, questionData, answer, setAnswer, situation, updateSituation, engine, updateResult }) => {
    // const [answer, setAnswer] = useState({});

    useEffect(() => {
        const initialAnswers = questionData.mosaique.options.reduce((acc, option) => {
            const fullOptionKey = questionId + ' . ' + option;
            acc[option] = situation.simulation.simulationAnswers[fullOptionKey] || 0;
            return acc;
        }, {});
        //console.log('test')
        //console.log(initialAnswers)
        answer(initialAnswers)},
        [questionData]
    )

    const handleSelection = (option) => {
        const newSelection = { ...answer, [option]: answer[option] === 'oui' ? 'non' : 'oui' };
        //console.log(newSelection)
        //console.log(questionId)
        setAnswer(newSelection);

        // Update situation
        const newSituation = { ...situation };
        Object.keys(newSelection).forEach(key => {
            newSituation.simulation.simulationAnswers[questionId + ' . ' + key] = newSelection[key];
        });
        updateSituation(newSituation);

        // Update engine
        engine.setSituation(newSituation.simulation.simulationAnswers);
        updateResult();
    };

    const handleSuggestion = (suggestion) => {
        const newSelection = { ...questionData.mosaique.options.reduce((acc, option) => {
            acc[option] = suggestion[option] || 'non';
            return acc;
        }, {}) };

        setAnswer(newSelection);

        // Update situation
        const newSituation = { ...situation };
        newSituation.simulation.simulationAnswers[questionId] = newSelection;
        updateSituation(newSituation);

        // Update engine
        engine.setSituation(newSituation.simulation.simulationAnswers);
        updateResult();
    };

    return (
        <div>
            <div className='mosaic-options'>
                {questionData.mosaique.options.map((option, index) => (
                    <button
                        key={index}
                        onClick={() => handleSelection(option)}
                        className={`button-mosaic ${answer[option] === 'oui' ? 'selected' : ''}`}
                    >
                        {option}
                    </button>
                ))}
            </div>
            <div className='suggestions'>
                {Object.entries(questionData.mosaique.suggestions).map(([suggestion, values]) => (
                    <button
                        key={suggestion}
                        onClick={() => handleSuggestion(values)}
                        className='button-suggestion'
                    >
                        {suggestion}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default MosaicChoicesQuestion;
