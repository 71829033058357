// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Marianne-Thin.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Marianne-Thin.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Marianne-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Marianne-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Marianne-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Marianne-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Marianne-ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Marianne-ExtraBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./Marianne-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./Marianne-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./Marianne-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./Marianne-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'Marianne';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB;wDAC2C;CAC3C,gBAAgB;CAChB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB;wDAC2C;CAC3C,iBAAiB;CACjB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB;wDAC4C;CAC5C,gBAAgB;CAChB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB;wDACgD;CAChD,gBAAgB;CAChB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB;wDAC8C;CAC9C,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB;yDAC6C;CAC7C,gBAAgB;CAChB,kBAAkB;CAClB,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: 'Marianne';\n\tsrc: url('./Marianne-Thin.woff2') format('woff2'),\n\t\turl('./Marianne-Thin.woff') format('woff');\n\tfont-weight: 100;\n\tfont-style: normal;\n\tfont-display: swap;\n}\n\n@font-face {\n\tfont-family: 'Marianne';\n\tsrc: url('./Marianne-Bold.woff2') format('woff2'),\n\t\turl('./Marianne-Bold.woff') format('woff');\n\tfont-weight: bold;\n\tfont-style: normal;\n\tfont-display: swap;\n}\n\n@font-face {\n\tfont-family: 'Marianne';\n\tsrc: url('./Marianne-Light.woff2') format('woff2'),\n\t\turl('./Marianne-Light.woff') format('woff');\n\tfont-weight: 300;\n\tfont-style: normal;\n\tfont-display: swap;\n}\n\n@font-face {\n\tfont-family: 'Marianne';\n\tsrc: url('./Marianne-ExtraBold.woff2') format('woff2'),\n\t\turl('./Marianne-ExtraBold.woff') format('woff');\n\tfont-weight: 800;\n\tfont-style: normal;\n\tfont-display: swap;\n}\n\n@font-face {\n\tfont-family: 'Marianne';\n\tsrc: url('./Marianne-Regular.woff2') format('woff2'),\n\t\turl('./Marianne-Regular.woff') format('woff');\n\tfont-weight: normal;\n\tfont-style: normal;\n\tfont-display: swap;\n}\n\n@font-face {\n\tfont-family: 'Marianne';\n\tsrc: url('./Marianne-Medium.woff2') format('woff2'),\n\t\turl('./Marianne-Medium.woff') format('woff');\n\tfont-weight: 500;\n\tfont-style: normal;\n\tfont-display: swap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
