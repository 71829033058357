import { useEffect, useState } from 'react';

const useTypingEffect = (textArray, typingSpeed = 100, deletingSpeed = 25, pauseTime = 1000) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    let timeout;
    if (!isDeleting && charIndex < textArray[currentTextIndex].length) {
      timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + textArray[currentTextIndex][charIndex]);
        setCharIndex(charIndex + 1);
      }, typingSpeed);
    } else if (isDeleting && charIndex > 0) {
      timeout = setTimeout(() => {
        setDisplayedText((prev) => prev.slice(0, -1));
        setCharIndex(charIndex - 1);
      }, deletingSpeed);
    } else if (charIndex === textArray[currentTextIndex].length) {
      timeout = setTimeout(() => setIsDeleting(true), pauseTime);
    } else if (isDeleting && charIndex === 0) {
      timeout = setTimeout(() => {
        setIsDeleting(false);
        setCurrentTextIndex((prev) => (prev + 1) % textArray.length);
      }, pauseTime);
    }

    return () => clearTimeout(timeout);
  }, [charIndex, isDeleting, textArray, typingSpeed, deletingSpeed, pauseTime, currentTextIndex]);

  return displayedText;
};

export default useTypingEffect;
