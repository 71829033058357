import React from "react";
import { numStr } from "../utils/FormatNumber";
import LoadingAnimation from "../utils/LoadingAnimation";

const imageMap = {
    numerique: '/img/numerique.png',
    nrj: '/img/nrj.png',
    textile: '/img/textile.png',
    "services societaux": '/img/services_societaux.png',
    alimentation: '/img/alimentation.png',
    wc: '/img/wc.png',
    douche: '/img/douche.png',
    cuisine: '/img/cuisine.png',
    vaisselle: '/img/vaisselle.png',
    bain: '/img/bain.png',
    robinet: '/img/robinet.png',
    linge: '/img/linge.png',
    voiture: '/img/voiture.png',
    animaux: '/img/animaux.png',
    jardin: '/img/jardin.png',
    piscine: '/img/piscine.png',
    terrasse: '/img/terrasse.png',
    hydratation: '/img/hydratation.png',
    construction: '/img/construction.png',
  };


const colorMap = {
  numerique: 'rgb(162 210 253)',
  nrj: 'rgb(107 196 123)',
  textile: 'rgb(249 205 73)',
  "services societaux": 'rgb(158 142 245)',
  alimentation: 'rgb(253 163 84)',
};

const colorLowOpacity = {
    numerique: 'rgba(162, 210, 253, 0.7)',
    nrj: 'rgba(107, 196, 123, 0.7)',
    textile: 'rgba(249, 205, 73, 0.7)',
    services_societaux: 'rgba(158, 142, 245, 0.7)',
    alimentation: 'rgba(253, 163, 84, 0.7)',
  };

const MainResults = ({ type, footprintResult }) => {
  const hiddenElementsRef = LoadingAnimation();
  const dataEmpreinte = footprintResult.detail.filter(item => item.category === type)
  .sort((a, b) => b.value - a.value)
  .slice(0, 3); 


  return (
    <div className="main-results">
      {dataEmpreinte.map((element, index) => {
        const shortKey = element.key;
        const imgSrc = imageMap[shortKey];
        //console.log('imgSrc', imgSrc)
        const proportionalWidth = (element.value / dataEmpreinte[0].value) * 95; // Calculate proportional width

        return (
          <div className="results-one hidden" key={element.key} ref={(el) => hiddenElementsRef.current.push(el)}>
            <div className="circle">{index + 1}</div>
            <img src={imgSrc} alt={element.key} className="graph-results-img" />
            <div className="graph-title-and-results-container">
              <div className="graph-results-title"><strong>{element.rawNode.titre}</strong></div>
              <div className="grands-postes-results" style={{ background: colorLowOpacity[shortKey], width: `${proportionalWidth}%`, borderColor:colorMap[shortKey] }}>
                {numStr(element.value,' ')} L/Jour
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MainResults;
