import React, { useEffect, useRef } from "react";
import LoadingAnimation from "../utils/LoadingAnimation";

const Apropos = () => {
  const hiddenElementsRef = LoadingAnimation();

  
  return (
    <div style={{ overflowX: "hidden" }}>
      <h1 className="hidden" ref={(el) => hiddenElementsRef.current.push(el)} style={{ marginLeft: '5vw', fontSize: 'xx-large' }}>
        Qui sommes-nous ?
      </h1>
      <div className="a-propos-paragraphe">
        <div className="qui-sommes-nous hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
          <p>
          Mon Empreinte Eau est un <b>calculateur d'empreinte eau</b> développé par la start-up Hydros dans le but de sensibiliser le grand public à l'importance de la gestion de l'eau et de les familiariser avec le concept d'empreinte eau. Cet outil interactif permet à chacun de prendre conscience de l'impact de ses activités quotidiennes sur les ressources en eau et de découvrir des moyens de réduire sa consommation.

          </p>
          <p>
          Hydros a été fondée par deux ingénieurs de CentraleSupélec, animés par la volonté de renforcer la résilience des entreprises et des territoires face au changement climatique, en particulier en ce qui concerne la raréfaction des ressources en eau. Nous travaillons sur des solutions innovantes pour aider les organisations à s'adapter aux défis climatiques et à adopter des pratiques durables pour la gestion de l'eau.
          </p>
          
        </div>
        <div className='qui-sommes-nous-img-container '  style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/img/contact_img.png" style={{ maxWidth: "85%" }} alt="Contact" />
        </div>
      </div>

      <div className="hydros-pour-agir-container">
        <div style={{display:'flex',paddingBottom:'25px',alignItems:'center', justifyContent:'space-between', paddingTop: '25px', paddingLeft: '5vw', paddingRight:'10vw'}}>
        <h2 className="hidden" ref={(el) => hiddenElementsRef.current.push(el)} style={{ color:'white',marginTop: '0px', fontSize: 'xx-large' , marginBottom:'-10px'}}>
          Hydros pour agir
        </h2>
        <img src='/img/logo_hydros_white.png' style={{width:'6rem'}} className="logo-compte-gouttes-bleu"></img></div>
        <div className="hydros-pour-agir-paragraphe">
          <div className="hydros-pour-agir hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            <p>
            Hydros est un cabinet de conseil spécialisé sur la question de l’eau. Nous accompagnons nos clients pour agir sur leurs risques et impacts sur la ressource en eau. Notre offre s’articule en trois axes :
              <ul>
                <li>
                La sensibilisation, pour comprendre les enjeux autour de la ressource eau avec l’Atelier Compte-Gouttes
                </li>
                <li>
                La mesure d’impact, avec la notion d’empreinte eau qui permet d’évaluer les consommations directes et indirectes à l’échelle d’un groupe, d’un produit, d’un site ou d’un procédé
                </li>
                <li>
                L’action avec la mise en place de plan de sobriété hydrique, d’une stratégie eau de l’entreprise, d’outils de pilotage et de suivi de consommation…
                </li>
              </ul>
            </p>
          </div>
          <div className="qui-sommes-nous-img-container hidden" ref={(el) => hiddenElementsRef.current.push(el)} style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/img/qui-sommes-nous.png"
              style={{ maxWidth: "100%" }}
              alt="Qui Sommes Nous"
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <button className="boutton-commencer boutton-a-propos" onClick={() => {window.location.href = 'https://hydros-consulting.com/'}}>
            En savoir plus
          </button>
        </div>
      </div>

      <div className="sensibiliser-container">
        <div style={{display:'flex',alignItems:'center', justifyContent:'space-between', paddingTop: '25px', paddingLeft: '5vw', paddingRight:'10vw'}}>
        <h2 className="hidden sensibiliser-titre" ref={(el) => hiddenElementsRef.current.push(el)} style={{ marginTop: '0px', fontSize: 'xx-large' , marginBottom:'-10px'}}>
          Compte-Gouttes pour sensibiliser
        </h2>
        <img src='/img/logo_compte_gouttes_bleu.png' style={{width:'6rem'}} className="logo-compte-gouttes-bleu"></img></div>
        <div className="sensibiliser-paragraphe">
          <div className="sensibiliser hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            <p>
              L’Atelier Compte-Gouttes est un atelier de sensibilisation sur la
              thématique eau. Il permet aux participants de se plonger dans un
              territoire soumis à une situation de stress hydrique et d’incarner
              les différents usagers de l’eau (agriculteurs, industrie, élus...)
            </p>
            <p>
              On se projette ainsi à horizon 2035, avec pour objectif de réduire
              la consommation d’eau de son territoire.
            </p>
          </div>
          <div className="sensibiliser-img-container hidden" ref={(el) => hiddenElementsRef.current.push(el)} style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/img/sensibiliser.png" style={{ maxWidth: '90%' }} alt="Sensibiliser" />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <button className="boutton-commencer boutton-sensibiliser" onClick={() => {window.location.href = 'https://compte-gouttes.fr'}}>
            En savoir plus
          </button>
        </div>
      </div>

      <div className="mesurer-container">
        <h2 className="hidden" ref={(el) => hiddenElementsRef.current.push(el)} style={{ marginTop: '0px', paddingTop: '25px', paddingLeft: '5vw', fontSize: 'xx-large', color: 'white' }}>
          Mon Empreinte eau pour mesurer
        </h2>
        <div className="mesurer-paragraphe">
          <div className="mesurer-empreinte-img-container hidden" ref={(el) => hiddenElementsRef.current.push(el)} style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/img/mesurer-empreinte.png" style={{ maxWidth: "100%" }} alt="Mesurer Empreinte" />
          </div>
          <div className="mesurer hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            <p>
              L’outil Mon Empreinte Eau est destiné au plus grand nombre, nous
              avions à coeur de proposer un outil permettant à toutes et tous de
              mesurer son empreinte eau et d’identifier les pistes d’action pour
              la réduire.
            </p>
            <p>
              La pression sur la ressource en eau est une des premières
              conséquences palpables du changement climatique. Notre mission c’est
              de permettre à chacun d’agir sur ce sujet à son échelle.
            </p>
            <p>
              Vous êtes une entreprise, vous souhaitez mesurer votre empreinte
              eau, contactez-nous pour en discuter !
            </p>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <a href='/contact' className="no-link">
          <button className="boutton-commencer boutton-a-propos">
            En savoir plus
          </button></a>
        </div>
      </div>

      <div className="inspiration-container">
        <h2 className="hidden" ref={(el) => hiddenElementsRef.current.push(el)} style={{ marginTop: '0px', paddingTop: '25px', paddingLeft: '5vw', fontSize: 'xx-large' }}>
          Nos inspirations et partenaires
        </h2>
        <div className="inspiration-paragraphe">
          <div className="inspiration hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            <p>
              Le calculateur d'empreinte eau "Mon empreinte eau" a été développé par Hydros. Nous avons, pour le modèle sous-jaccent, utilisé la librairie <a href="https://publi.codes/">Publicodes</a>. Nous nous sommes inspirés dans les modèles d'utilisation existante de cette librairie, notamment Mon Entreprise, Nos Gestes Climat, Impact CO2 et ekofest.
            </p>
            <p>
              Concernant le design du site et la structure des questions, nous nous sommes inspirés du questionnaire Nos Gestes Climat avec les formats mosaïques et les suggestions de questions. Nous les remercions pour les temps d'échanges ainsi que toutes les ressources mises à disposition en open source.
            </p>
           
          </div>
          <div className="inspiration-img-container">
            <img src="/img/logo_ngc.png" alt="Logo NGC" className="inspiration-logo"/>
            <img src="/img/logo_publicodes.png" alt="Logo Publicodes" className="inspiration-logo"/>
            <img src="/img/logo_impactco2.png" alt="Logo Impact CO2" className="inspiration-logo"/>
            <img src="/img/logo_monentreprise.png" alt="Logo Mon entreprise" className="inspiration-logo"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apropos;
