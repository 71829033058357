import React, { useState, useEffect, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { numStr } from '../utils/FormatNumber';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, title }) => {
  const [fontSize, setFontSize] = useState(16); 
  const containerRef = useRef(null);

  const calculateFontSize = () => {
    const containerWidth = containerRef.current ? containerRef.current.offsetWidth : window.innerWidth;
    return containerWidth * 0.05; // 5% of container width as font size
  };

  useEffect(() => {
    const handleResize = () => {
      setFontSize(calculateFontSize());
    };

    // Initial font size calculation
    setFontSize(calculateFontSize());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define your set of colors
  const colors = [
    "#295384",
    '#2F5F98',
    '#2D8BBA',
    '#41B8D5',
    '#9ADFF8',
    '#6CE5E8',
    '#895273',
    '#B97286',
  ];

  // Sort the data by value in descending order
  const sortedData = [...data].sort((a, b) => b.value - a.value);

  // Calculate the total value
  const totalValue = sortedData.reduce((sum, item) => sum + item.value, 0);

  // Filter out items that are less than 10% of the total
  const mainCategories = sortedData.filter(item => item.value / totalValue >= 0.05);
  const otherCategories = sortedData.filter(item => item.value / totalValue < 0.05);

  const otherTotalValue = otherCategories.reduce((sum, item) => sum + item.value, 0);

  const chartDataLabels = [
    ...mainCategories.map(item => item.rawNode.titre),
    'autres',
  ];

  const chartDataValues = [
    ...mainCategories.map(item => item.value),
    otherTotalValue,
  ];

  // Map colors to the sorted data
  const getColorForSegment = (chartDataLabels, colors) => {
    const numberOfColors = colors.length;
    return chartDataLabels.map((_, index) => colors[index % numberOfColors]);
  };

  const chartData = {
    labels: chartDataLabels,
    datasets: [{
      data: chartDataValues,
      backgroundColor: getColorForSegment(chartDataLabels, colors),
    }],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const value = context.raw || '';
            return `${numStr(value)} litres/jour\n\n`;
          },
        },
        bodyFont: {
          size: fontSize,
        },
        titleFont: {
          size: fontSize,
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div ref={containerRef} className="pie-chart-container">
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default PieChart;
