import React, { useState } from 'react';
import { numStr } from '../utils/FormatNumber';

const ActionComponent = ({ actionName, engine }) => {
  const [action] = useState(engine.publicParsedRules[actionName].rawNode);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const handleInfoAction = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
    
  }

  return (
    <div className="action-component">
      <div className="action">
        <div className="comment-agir-titre">
          <div className="agir-percentage-container">
            <strong className="agir-blue-title">
              <div className="">{action.icônes}</div> 
              {""}
              {action.titre}
          
                <img 
                  src='/img/info.svg' 
                  className='info-action' 
                  onClick={handleInfoAction} 
                  alt="Info icon"
                />
              
            </strong>
          
            {isDescriptionVisible && (
              <div className='action-description'>
                {action.description}
              </div>
            )}
          </div>
        </div>

        <div className="action-valeur">
          <div> - {numStr(engine.evaluate(actionName).nodeValue)} L/jour</div>
        </div>
      </div>
    </div>
  );
};

export default ActionComponent;
