import React, { useState, useEffect } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
  Marker,
} from "react-simple-maps";
import { useSituation } from "../SituationManager";
import { Tooltip } from "react-tooltip";
import { numStr } from "../utils/FormatNumber";

const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";

const colorScales = {
    alimentation: scaleLinear().domain([1, 150]).range(["#86efac", "#166534"]),
    textile: scaleLinear().domain([1, 100]).range(["#facc15", "#ea580c"]),
    numerique: scaleLinear().domain([1, 75]).range(["#e5e7eb", "#4b5563"]),
    logement: scaleLinear().domain([1, 75]).range(["#ddd6fe", "#7c3aed"]),
    default: scaleLinear().domain([1, 75]).range(["#bfdbfe", "#1d4ed8"]),
  };
  
const getColorScale = (mapType) => {
    return colorScales[mapType] || colorScales.default;
};

const Map = ({mapType}) => {
  const [content, setContent] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    csv(`/Parts_des_pays_dans_wf.csv`).then((data) => {
      setData(data);
    });
  }, []);

  const { situation } = useSituation();
 
  
  const getDetailByKey = (details, key) => {
    const detail = details.find((detail) => detail.key === key);
    return detail.value;
  };

  const [footprintResult, setFootprintResult] = useState(
    situation.simulation.footprintResult
  );

  const calculateTotalValue = (countryData, footprint, mapType) => {
    const alimentationFootprint = getDetailByKey(footprint["detail"], "alimentation");
    const textileFootprint = getDetailByKey(footprint["detail"], "textile");
    const numeriqueFootprint = getDetailByKey(footprint["detail"], "numerique");
    const constructionFootprint = getDetailByKey(footprint["detail"], "construction");
    const interieurFootprint =
      footprint["directFootprint"] +
      getDetailByKey(footprint["detail"], "nrj") +
      getDetailByKey(footprint["detail"], "services societaux");

    const parseValue = (value) => {
      if (typeof value === "string") {
          return parseFloat(value.replace(",", "."));
      }
      return parseFloat(value || 0);
    };
    const alimentation = parseValue(countryData.Alimentation);
    const textile = parseValue(countryData.Textile);
    const numerique = parseValue(countryData.Numérique);
    const construction = parseValue(countryData.Construction);
    const interieure = parseValue(countryData.Intérieure);

    const alim_country = alimentation * alimentationFootprint;
    const textile_country = textile * textileFootprint;
    const numerique_country = numerique * numeriqueFootprint;
    const service_country = interieure * interieurFootprint;
    const construction_country = construction * constructionFootprint;

    const total = alim_country + textile_country + numerique_country + service_country + construction_country;


    switch (mapType) {
      case "alimentation": return alim_country;
      case "textile": return textile_country;
      case "numerique": return numerique_country;
      case "service": return service_country;
      case "logement": return construction_country
      default: return total;
    }
};

const colorScale = getColorScale(mapType);  




  return (
    <div>
      <ComposableMap
        width={800}
        height={400}
        style={{ width: "100%" }}
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
      >
        <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        {data.length > 0 && (
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data.find((s) => s.Pays2 === geo.properties.name);
                const frenchName = d ? d.Pays : geo.properties.name;
                const totalValue = d
                  ? calculateTotalValue(d, footprintResult,mapType).toFixed(0)
                  : 0;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      setContent(
                        `${frenchName} : ${numStr(totalValue,' ')} L/Jour`
                      );
                    }}
                    onMouseLeave={() => {
                      setContent("");
                    }}
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none", fill: colorScale(100) },
                      pressed: { outline: "none", fill: colorScale(100)},
                    }}
                    fill={d ? colorScale(totalValue) : "#F5F4F6"}
                  />
                );
              })
            }
          </Geographies>
        )}
      
      
      </ComposableMap>
      <Tooltip anchorId="map" content={content} float style={{ backgroundColor: "white", color: "#205673" }} />
    </div>
  );
};

export default Map;
