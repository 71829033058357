import React, { useState, useEffect } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
  Marker,
} from "react-simple-maps";
import { useSituation } from "../SituationManager";
import { Tooltip } from "react-tooltip";
import { geoCentroid } from "d3-geo";

const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";

const colorScales = {
  alimentation: scaleLinear().domain([1, 150]).range(["#86efac", "#166534"]),
  textile: scaleLinear().domain([1, 100]).range(["#facc15", "#ea580c"]),
  numerique: scaleLinear().domain([1, 75]).range(["#e5e7eb", "#4b5563"]),
  service: scaleLinear().domain([1, 75]).range(["#ddd6fe", "#7c3aed"]),
  default: scaleLinear().domain([1, 75]).range(["#ffedd5", "#ea580c"]),
};

const getColorScale = (mapType) => colorScales[mapType] || colorScales.default;

const AdvancedMap = ({ mapType , tailleMarkers,setFranceFootprint, doNotShow}) => {
  const [content, setContent] = useState({ Pays: '', Empreinte: '', Stress: '' });
  const [data, setData] = useState([]);

  useEffect(() => {
    csv(`/Parts_des_pays_dans_wf.csv`).then((data) => setData(data));
  }, []);

  const { situation } = useSituation();
  const [footprintResult] = useState(situation.simulation.footprintResult);

  const getDetailByKey = (details, key) => {
    const detail = details.find((detail) => detail.key === key);
    return detail ? detail.value : 0;
  };

  const calculateTotalValue = (countryData, footprint, mapType) => {
    const alimentationFootprint = getDetailByKey(footprint["detail"], "alimentation");
    const textileFootprint = getDetailByKey(footprint["detail"], "textile");
    const numeriqueFootprint = getDetailByKey(footprint["detail"], "numerique");
    const constructionFootprint = getDetailByKey(footprint["detail"], "construction");
    const interieurFootprint =
      footprint["directFootprint"] +
      getDetailByKey(footprint["detail"], "nrj") +
      getDetailByKey(footprint["detail"], "services societaux");

    const parseValue = (value) => {
      if (typeof value === "string") {
          return parseFloat(value.replace(",", "."));
      }
      return parseFloat(value || 0);
    };
    const alimentation = parseValue(countryData.Alimentation);
    const textile = parseValue(countryData.Textile);
    const numerique = parseValue(countryData.Numérique);
    const construction = parseValue(countryData.Construction);
    const interieure = parseValue(countryData.Intérieure);

    const alim_country = alimentation * alimentationFootprint;
    const textile_country = textile * textileFootprint;
    const numerique_country = numerique * numeriqueFootprint;
    const service_country = interieure * interieurFootprint;
    const construction_country = construction * constructionFootprint;

    const total = alim_country + textile_country + numerique_country + service_country + construction_country;


    switch (mapType) {
      case "alimentation": return alim_country;
      case "textile": return textile_country;
      case "numerique": return numerique_country;
      case "service": return service_country;
      case "construction": return construction_country
      default: return total;
    }
  };

  const colorScale = getColorScale(mapType);

  const handleMouseEnter = (name, totalValue, coefAware) => {
    setContent({
      Pays: `${name}`,
      Empreinte: `Empreinte : ${totalValue.toFixed(0)} L/Jour`,
      Stress: `Stress hydrique: ${coefAware}`
    });
  };

  const handleMouseLeave = () => {
    setContent({ Pays: '', Empreinte: '', Stress: '' });
  };

  const contentString = Object.values(content).join('<br />');

  useEffect(() => {
    if (data.length > 0) {
      const france = data.find((s) => s.Pays2 === "France");
      const totalValue = france
        ? calculateTotalValue(france, footprintResult).toFixed(0)
        : 0;
      setFranceFootprint(((parseFloat(totalValue) / footprintResult["globalFootprint"]) * 100).toFixed(0));
      // console.log(totalValue)
      // console.log(footprintResult["globalFootprint"])
      // console.log(((parseFloat(totalValue) / footprintResult["globalFootprint"]) * 100).toFixed(1),'test')
      // console.log(france)
    }
    
  }, [data]);

  

  return (
    <div>
      <ComposableMap
        width={800}
        height={400}
        style={{ width: "100%" }}
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
      >
        <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        
        {data.length > 0 && (
          <Geographies geography={geoUrl}>
            {({ geographies }) => (
              <>
                <g style={{ position: 'relative', zIndex: 1 }}>
                  {geographies.map((geo) => {
                    const d = data.find((s) => s.Pays2 === geo.properties.name);
                    const frenchName = d ? d.Pays : geo.properties.name;
                    const totalValue = d && geo.properties.name !== "France"
                      ? calculateTotalValue(d, footprintResult, mapType)
                      : 0;
                    const coefAware = d ? parseInt(d.Coefficient_AWARE, 10) : 0;

                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        onMouseEnter={() => handleMouseEnter(frenchName, totalValue, coefAware)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          default: {
                            outline: "none",
                            fill: d ? colorScale(coefAware) : "#F5F4F6",
                            stroke: "white",
                            strokeWidth: 0.6,
                          },
                          hover: {
                            outline: "none",
                            fill: d ? colorScale(coefAware) : "#F5F4F6",
                            stroke: "white",
                            strokeWidth: 0.6,
                          },
                          pressed: {
                            outline: "none",
                            fill: d ? colorScale(coefAware) : "#F5F4F6",
                            stroke: "white",
                            strokeWidth: 0.6,
                          },
                        }}
                      />
                    );
                  })}
                </g>
                <g style={{ position: 'relative', zIndex: 2 }}>
                  {geographies.map((geo) => {
                    const d = data.find((s) => s.Pays2 === geo.properties.name);
                    const frenchName = d ? d.Pays : geo.properties.name;
                    if (!d) return null;

                    const totalValue = geo && geo.properties.name !== doNotShow
                      ? calculateTotalValue(d, footprintResult, mapType)
                      : 0;
                    const coefAware = d ? parseInt(d.Coefficient_AWARE, 10) : 0;
                    const centroid = geoCentroid(geo);
                    const radius = Math.sqrt(totalValue) * 2;

                    return (
                      totalValue > 10 && (
                        <Marker key={geo.rsmKey} coordinates={centroid}>
                          <circle
                            r={radius / tailleMarkers}
                            fill={"rgba(32, 86, 115, 0.5)"}
                            stroke={"#205673"}
                            strokeWidth={0.5}
                            onMouseEnter={() => handleMouseEnter(frenchName, totalValue, coefAware)}
                            onMouseLeave={handleMouseLeave}
                          />
                        </Marker>
                      )
                    );
                  })}
                </g>
              </>
            )}
          </Geographies>
        )}
      </ComposableMap>

      <Tooltip
        anchorId="map"
        content={contentString}
        float
        render={({ content }) => 
          content.includes("Empreinte :") ? <div dangerouslySetInnerHTML={{ __html: contentString }} /> : null
        }
        style={{ backgroundColor: "white", color: "#205673", fontWeight:'bold' }}
      />
    </div>
  );
};

export default AdvancedMap;
