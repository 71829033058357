import React from 'react';
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    const handleLogoClick = () => {
        navigate("/"); 
      };

    return (

        <div className='footer-container'>
        <footer className='footer'>
            <div>
            <div className='header-logo-div'></div>
            </div>

            <div className='footer-navigation'>
                <div className='navigation-links'>
                <a className='footer-content no-link' href='/'>Accueil</a>
                <a className='footer-content no-link' href='/a-propos'>À propos</a>
                <a className='footer-content no-link' href = 'https://blog.mon-empreinte-eau.fr'>Blog</a>
                <a className='footer-content no-link' onClick={() => window.location.href = 'https://blog.mon-empreinte-eau.fr/notre-methodologie'}>Méthodologie</a>
                <a className='footer-content no-link' href='/contact'>Contact </a>
                <a className='footer-content no-link' href='/faq'>FAQ </a>
              </div>

                <div className='footer-logo'>
                <a className='no-link' href='/'><img src='/img/logo-white.png' alt='logo' className='ademe-icon' ></img></a>
         </div>
                </div>
        </footer></div>
    );
};

export default Footer;