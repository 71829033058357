import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ValueQuestion from "../subQuestions/valueQuestion";
import BoolQuestion from "../subQuestions/boolQuestion";
import MultipleChoiceQuestion from "../subQuestions/multipleChoicesQuestion";
import MosaicChoicesQuestion from "../subQuestions/mosaicChoicesQuestion";
import MosaicValueQuestion from "../subQuestions/mosaicValueQuestion";
import GraphiqueVague from "../GraphiqueVague";
import { saveAnswer, saveFootprint } from "../Analytics";
import { useEngine } from "../EngineManager";
import { useSituation } from "../SituationManager";
import GraphiqueEmpreinte from "../GraphiqueEmpreinte";
import { numStr } from "../utils/FormatNumber";

const QuestionTEST = ({ QuestionId }) => {
  const progressRef = useRef(null);
  const [percentageCompleted, setPercentageCompleted] = useState(1);
  const [height, setHeight] = useState(0);
  const [dialogue, setDialogue] = useState(['clique pour en savoir plus', 'Des ateliers adaptés à vos besoins pour comprendre et à agir pour préserver la ressource eau Des ateliers adaptés à vos besoins pour comprendre et à agir pour préserver la ressource eau Des ateliers adaptés à vos besoins pour comprendre et à agir pour préserver la ressource eau ']);
  const [showFirstDialogue, setShowFirstDialogue] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [answer, setAnswer] = useState({});
  const [footprintResult, setFootprintResult] = useState({
    globalFootprint: 0,
    directFootprint: 0,
    indirectFootprint: 0,
    detail: []
  });
  const [result, setResult] = useState({
    "direct . moyenne": 0,
    direct: 0,
    "direct . douche": 0,
    "direct . vaisselle": 0,
    "direct . bain": 0,
    "direct . wc": 0,
    "direct . robinet": 0,
    "direct . linge": 0,
    "direct . voiture": 0,
    "direct . animaux": 0,
    "direct . jardin": 0,
    "direct . piscine": 0,
    "direct . terrasse": 0,
    "direct . cuisine": 0,
    "direct . hydratation": 0,
    indirect: 0,
    "indirect . alimentation": 0,
    "indirect . numerique": 0,
    "indirect . textile": 0,
    "indirect . nrj": 0,
    "indirect . services societaux": 0,
    "indirect . services societaux . fuites": 0,
    "indirect . services societaux . construction": 0,
    "indirect . services societaux . sante": 0,
  });

  const navigate = useNavigate();
  const engine = useEngine();
  const { situation, updateSituation } = useSituation();
  
  useEffect(() => {
    const progress = progressRef.current;
    if (progress) {
      progress.style.width = percentageCompleted + '%';
      progress.style.opacity = 1;
    }
  }, [percentageCompleted]); 

  const handledialogue = () => {
    setShowFirstDialogue(!showFirstDialogue);
  };

  const handleshowMore = () => { setShowMore(!showMore); }

  const getFootprintResult = () => {
    const detail = [];

    const directKeys = engine.publicParsedRules['direct'].rawNode.formule.somme;
    const indirectKeys = engine.publicParsedRules['indirect'].rawNode.formule.somme;

    directKeys.forEach((key) => {
      const dottedKey = 'direct . ' + key;
      detail.push({
        key: key,
        category: 'direct',
        dottedName: dottedKey,
        value: engine.evaluate(dottedKey).nodeValue,
        rawNode: engine.publicParsedRules[dottedKey].rawNode
      });
    });

    indirectKeys.forEach((key) => {
      const dottedKey = 'indirect . ' + key;
      detail.push({
        key: key,
        category: 'indirect',
        dottedName: dottedKey,
        value: engine.evaluate(dottedKey).nodeValue,
        rawNode: engine.publicParsedRules[dottedKey].rawNode
      });
    });

    const res = {
      globalFootprint: engine.evaluate('empreinte eau').nodeValue,
      indirectFootprint: engine.evaluate('indirect').nodeValue,
      directFootprint: engine.evaluate('direct').nodeValue,
      detail: detail
    };

    return res;
  };

  if (!situation) {
    navigate('/');
  }

  const toggleInfo = () => {
    setShowInfo((prev) => !prev);
  };

  const closeInfo = () => {
    setShowInfo(false);
    setShowFirstDialogue(false);
  };

  const goToNextQuestionId = () => {
    const foldedQuestions = situation.simulation.foldedSteps;
    const index = foldedQuestions.indexOf(QuestionId);
    var nextQuestion = "";
    if (index !== -1 && index !== foldedQuestions.length - 1) {
      nextQuestion = foldedQuestions[index + 1];
    } else {
      const selectedQuestions = [];
      Object.entries(engine.publicParsedRules).map(([key, value]) => {
        if (
          value.rawNode.question &&
          engine.evaluate({ "est applicable": key }).nodeValue
        ) {
          if (!foldedQuestions.includes(key)) {
            selectedQuestions.push(key);
          }
        }
      });

      if (selectedQuestions.length == 0) {
        nextQuestion = undefined;
        const newSituation = { ...situation };
        newSituation.simulation.results = result;
        newSituation.simulation.footprintResult = footprintResult;
        updateSituation(newSituation);
      } else {
        nextQuestion = selectedQuestions[0];
        const newSituation = { ...situation };
        newSituation.simulation.foldedSteps.push(nextQuestion);
        updateSituation(newSituation);
      }
    }

    saveAnswer({
      question_id: QuestionId,
      user_id: situation.userId,
      answer: answer
    });
    if (nextQuestion) {
      navigate("/question/" + nextQuestion);
    } else {
      saveFootprint({
        user_id: situation.userId,
        footprint: footprintResult
      });

      const newSituation = { ...situation };
      newSituation.simulation.is_finish = true;
      updateSituation(newSituation);

      navigate("/end_result");
    }
  };

  const goToPreviousQuestionId = () => {
    const foldedQuestions = situation.simulation.foldedSteps;
    const index = foldedQuestions.indexOf(QuestionId);
    const reducedFoldedQuestions = foldedQuestions.slice(0, -1);

    if (index !== -1 && index !== 0) {
      navigate("/question/" + foldedQuestions[index - 1]);
      const newSituation = { ...situation };
      newSituation.simulation.foldedSteps = reducedFoldedQuestions;
      updateSituation(newSituation);
    } else {
      navigate("/question/" + foldedQuestions[0]);
    }
  };

  const updateResult = () => {
    var newResult = {};
    Object.entries(result).map(([key, value]) => {
      newResult[key] = engine.evaluate(key).nodeValue;
    });
    const newFootprintResult = getFootprintResult();
    setResult(newResult);
    setFootprintResult(newFootprintResult);

    const newSituation = { ...situation };
    newSituation.simulation.results = newResult;
    newSituation.simulation.footprintResult = newFootprintResult;
    updateSituation(newSituation);
  };

  useEffect(() => {
    if (!situation) {
      navigate('/');
    }
    if (engine.publicParsedRules["direct"]) {
      engine.setSituation(situation.simulation.simulationAnswers);
      const key = QuestionId;
      const newQuestionData = engine.publicParsedRules[key].rawNode;
      setQuestionData(newQuestionData);
      updateResult();

      const totalQuestions = 37;
      const questionsCompleted = situation.simulation.foldedSteps.length;
      setPercentageCompleted(Math.round(((questionsCompleted + 1) / totalQuestions) * 100));
      setHeight((Math.round((questionsCompleted / totalQuestions) * 100)) * 30 / 100);
    }
  }, [engine, QuestionId]);

  // Reset showFirstDialogue and showInfo when QuestionId changes
  useEffect(() => {
    setShowFirstDialogue(true);
    setShowInfo(false);
  }, [QuestionId]);

  const handleNextQuestion = () => {
    goToNextQuestionId();
  };

  const handlePreviousQuestion = async () => {
    goToPreviousQuestionId();
  };

  if (!questionData) {
    return <div>Loading...</div>;
  }

  const renderQuestionComponent = () => {
    switch (questionData.type) {
      case "value":
        return (
          <ValueQuestion
            questionId={QuestionId}
            questionData={questionData}
            answer={answer}
            setAnswer={setAnswer}
            situation={situation}
            updateSituation={updateSituation}
            engine={engine}
            updateResult={updateResult}
          />
        );
      case "bool":
        return (
          <BoolQuestion
            questionId={QuestionId}
            questionData={questionData}
            answer={answer}
            setAnswer={setAnswer}
            situation={situation}
            updateSituation={updateSituation}
            engine={engine}
            updateResult={updateResult}
          />
        );
      case "qcm":
        return (
          <MultipleChoiceQuestion
            questionId={QuestionId}
            questionData={questionData}
            answer={answer}
            setAnswer={setAnswer}
            situation={situation}
            updateSituation={updateSituation}
            engine={engine}
            updateResult={updateResult}
          />
        );
      case "mosaic_choice":
        return (
          <MosaicChoicesQuestion
            questionId={QuestionId}
            questionData={questionData}
            answer={answer}
            setAnswer={setAnswer}
            situation={situation}
            updateSituation={updateSituation}
            engine={engine}
            updateResult={updateResult}
          />
        );
      case "mosaic_value":
        return (
          <MosaicValueQuestion
            questionId={QuestionId}
            questionData={questionData}
            answer={answer}
            setAnswer={setAnswer}
            situation={situation}
            updateSituation={updateSituation}
            engine={engine}
            updateResult={updateResult}
          />
        );
      default:
        return <div>Unknown question type</div>;
    }
  };

  const calculatePseudoMarginTop = (value) => {
    if (value >= 5000) {
      return 45;
    } else if (value === 0) {
      return 0;
    } else {
      return (value / 5000) * 45;
    }
  };

  const pseudoMarginTop = calculatePseudoMarginTop(footprintResult['globalFootprint']);

  return (
    <div className="global-question-container" >
              <div style={{paddingTop:'20px' ,textAlign:'right', display:'flex', justifyContent:'space-between'}}><div><h1 style={{margin:'0px'}}>{QuestionId.startsWith('direct') ? 'Consommation directe' : 'Consommation indirecte'}
              </h1></div> <div><img src='/img/powered_by_hydros.jfif' style={{width:'5rem'}} ></img></div></div>

      <div className="progression"><div className="progress-done"
          ref={progressRef}> &nbsp;</div></div>

          <div className="question-total-container">
          <div className="question-box-inner" 
        style={{ '--pseudo-margin-top': `${(pseudoMarginTop + 35)}%` }}
        >
            <h3 className="question-total" onClick={() => console.log(QuestionId,'answer')}>{QuestionId.startsWith('indirect') ? numStr(footprintResult["indirectFootprint"]) : numStr(footprintResult["directFootprint"])}
            L/Jour</h3></div></div>
          
      <div className="question_container">
        <div className="question_title_container">
          <div className="question_title">{questionData.question}</div>
          <div className="question_info_icon">
            <img
              src='/img/info.svg'
              alt="info"
              className="info-img"
              onClick={toggleInfo}
            ></img>
          </div>
        </div>
        {showInfo === true && (
          <div className="question_info_container">
            <div className="text_info">{questionData.description}</div>
            <button
              className="close_text_info_button button-hydros"
              onClick={closeInfo}
            >
              Fermer
            </button>
          </div>
        )}

        {renderQuestionComponent()}

        <div className="know_more_container"></div>

        <div className="navigation_container">
          <div className="next_button_container">
            <button
              className="next_button button-hydros"
              onClick={() => {
                handlePreviousQuestion();
                closeInfo();
              }}
            >
              <img
                src='/img/fleche_gauche.svg'
                alt="fleche_gauche"
                className="arrow-img"
              ></img>
              Précédent
            </button>
          </div>
          <div className="previous_button_container">
            <button
              className="previous_button button-hydros"
              onClick={() => {
                handleNextQuestion();
                closeInfo();
              }}
            >
              Suivant
              <img
                src='/img/fleche_droite.svg'
                alt="fleche_gauche"
                className="arrow-img"
              ></img>
            </button>
          </div>
        </div>
        {questionData.note ? (
          <div style={{ display: 'flex', paddingTop: '20px' }}>
            <div>
              <div className="dialogue_clic" onClick={handledialogue}>
                <img src="/img/walter_test_5.png" className="walter-icon-behind"></img>
                {questionData.note}
              </div>
            </div>
          </div>
        ) : (<div></div>)}

      </div>


      <div className="graphique_empreinte_div">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h3 className="question-graphique-title">Empreinte directe</h3> <p className="question-graphique-title">{numStr(footprintResult['directFootprint'])} L/jour</p></div>
        <GraphiqueEmpreinte type="direct" footprintResult={footprintResult} />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h3 className="question-graphique-title">Empreinte indirecte</h3> <p className="question-graphique-title">{numStr(footprintResult['indirectFootprint'])} L/jour</p></div>
        <GraphiqueEmpreinte type="indirect" footprintResult={footprintResult} />
        <h3 className="question-graphique-title" style={{ textAlign: 'center', marginBottom: '5vh', cursor: 'pointer' }} onClick={handleshowMore}>Voir plus</h3>
        {showMore ? (
          <div className="component-graphique-vague-container">
            <GraphiqueVague type="indirect" result={result} height={height} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default QuestionTEST;
