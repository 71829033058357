import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useSituation } from "../SituationManager";
import { useState } from "react";
import useTypingEffect from "../utils/typing_effect";
import LoadingAnimation from "../utils/LoadingAnimation";
import { getInitSituation } from "../utils/InitialSituation";

const Home = () => {
  
  const hiddenElementsRef = LoadingAnimation();

  const navigate = useNavigate();
  const [carousel] = useState([
    "bain",
    "eau",
    "douche",
    "eau",
    "vaisselle",
    "eau",
    "textile",
    "eau",
    "robinet",
    "eau",
    "cafe",
    "eau",
    "cuisine",
    "eau",
    "jardin",
    "eau",
    "numerique",
    "eau",
    "piscine",
    "eau",
    "alimentation",
    "eau",
    "wc",
    "eau",
    "animaux",
    "eau",
    "services_societaux",
    "eau",
  ]);
  const { situation, updateSituation } = useSituation();
  const textOptions = ["à comprendre.", "à construire.", "à agir."];
  const changingText = useTypingEffect(textOptions, 100, 15, 1000); // Example adjustments

  var userStatus = 'first_time'
  if (situation) {
    if (situation.simulation.is_finish) {
      userStatus = 'finish'
    } else {
      userStatus = 'in_progress'
    }
  }


  const initSituation = getInitSituation();

  const handleStartClick = () => {
    updateSituation(initSituation);
    navigate("/question/direct . nombre de personnes"); // Rediriger vers l'URL du calculateur
  };

  const handleResultClick = () => {
    navigate("/end_result"); // Rediriger vers l'URL du calculateur
  };

  const handleInProgressClick = () => {
    navigate("/question/"+situation.simulation.foldedSteps[situation.simulation.foldedSteps.length-1]); // Rediriger vers l'URL du calculateur
  };

  const handleContactClick = () => {
    navigate("/contact"); 
  };


  const renderStartButton = () => {
    switch (userStatus) {
      case 'first_time':
        return (
          <button onClick={handleStartClick} className="boutton-commencer">
            Passer le test
          </button>
        );
      case 'in_progress':
        return (
          <div>
            <button onClick={handleInProgressClick} className="boutton-commencer">
              Reprendre votre test
            </button>
            <div onClick={handleStartClick} className="home-button-new-form">Commencer un nouveau test</div>
          </div>
        );
      case 'finish':
        return (
          <div>
            <button onClick={handleResultClick} className="boutton-commencer">
              Voir les résultats
            </button>
            <div onClick={handleStartClick} className="home-button-new-form">Commencer un nouveau test</div>
          </div>
        );
      default:
        return (
          <button onClick={handleStartClick} className="boutton-commencer">
            Passer le test
          </button>
        );
    }
  };
      

  return (
    <div>
      <div className="paragraph1-flex-container">

        {/* <div className="splash-img">
        <img src='/img/11.svg' className=""></img></div> */}
        <div className="paragraph1-text" >
          <div class="container-carousel">
            <span className="text">
              {carousel.map((item, index) => {
                const src = `/img/${item}.png`;
                const className = item === "eau" ? "eau-img" : "carousel-img";
                return (
                  <img src={src} className={className} alt={item} key={item + index}/>
                );
              })}
            </span>


            <span className="text">
              {carousel.map((item, index) => {
                const src = `/img/${item}.png`;
                const className = item === "eau" ? "eau-img" : "carousel-img";
                return (
                  <img src={src} className={className} alt={item} key={item + index}/>
                );
              })}
            </span>
          </div>
          <h1 className="paragraph1-title hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            Calculez votre empreinte eau individuelle
          </h1>
          <p className="introduction hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            Obtenez rapidement votre empreinte eau directe et
            indirecte grâce à notre calculateur
          </p>
          <div>
            {renderStartButton()}
          </div>

        </div>
      </div>

      <div className="parent-paragraphe-empreinte-eau">
      <div className="home-separator-bottom"></div>
        <div className="paragraphe-empreinte-eau">
          <div className="paragraphe-empreinte-eau-container hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            <h2 className="paragraph1-title">Le calculateur "Mon Empreinte Eau"</h2>
            <p className="description-seo-text">Découvrez "Mon Empreinte Eau", le <b>calculateur d'empreinte eau individuelle</b> complet et simple d'utilisation. Basé sur la méthodologie du Water Footprint Network, notre outil de calcul permet d'estimer rapidement votre empreinte eau directe et indirecte. Calculez dès maintenant votre consommation d'eau pour mieux comprendre l'impact de vos habitudes quotidiennes, de vos achats, de votre alimentation sur cette précieuse ressource.</p>
            <p className="description-seo-text">Obtenez des résultats clairs en quelques clics, visualisez la carte de votre empreinte hydrique, et recevez une liste d'actions personnalisées pour réduire efficacement votre <b>empreinte eau</b>.</p>
            
            
            <h2 className="paragraph1-title">Pour aller plus loin</h2>
            <div className='plus-loin-container' >
              <div className="empreinte-eau-bloc" onClick={() => window.location.href = 'https://blog.mon-empreinte-eau.fr/comprendre-l-empreinte-eau'}>
                <div className="circle-plus-loin">
                  <img
                    src="/img/eau.png"
                    alt="Goutte d'eau symbolisant l'empreinte eau"
                    className="img-plus-loin"
                    loading="lazy"
                  ></img>
                </div>

                <div className ='titre-container'>
                  <p className="titre">C'est quoi l'empreinte eau ?</p>
                </div>
              </div>
              <div className="empreinte-eau-bloc" onClick={() => window.location.href = 'https://blog.mon-empreinte-eau.fr/notre-methodologie'}>
                <div className="circle-plus-loin">
                  <img
                    src="/img/methode.png"
                    alt="Livre de méthodologie d'empreinte eau"
                    className="img-plus-loin"
                    loading="lazy"
                  ></img>
                </div>
                <div className ='titre-container'>
                  <p className="titre">Notre méthode</p>
                </div>
              </div>
              <a className="empreinte-eau-bloc no-link" href="/contact">
                <div className="circle-plus-loin">
                  <img
                    src="/img/contact.png"
                    alt="Formulaire de contact, téléphone"
                    className="img-plus-loin"
                  ></img>
                </div>
                <div className ='titre-container'>
                  <p className="titre">Contactez-nous</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="home-separator"></div>

      <div className="paragraphe-nos-gestes">
        <div>
          <img
            src="/img/atelier_compte_gouttes.webp"
            alt="Des personnes en train de réfléchir autour de l'empreinte eau"
            className="img-atelier-compte-gouttes hidden"
            ref={(el) => hiddenElementsRef.current.push(el)}
            loading="lazy"
          ></img>
        </div>
        <div className="paragraphe-nos-gestes-container hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
          <h3 className="paragraph1-title">Vous souhaitez en apprendre davantage sur l'eau ?</h3>
          <p className="paragraphe-nos-gestes-item">
          Découvrez l'Atelier Compte-Gouttes !
          </p>
          <p className="paragraphe-nos-gestes-item">
          L’Atelier Compte-Gouttes sensibilise aux enjeux liés à l’eau et vous projette en 2035 dans un territoire soumis à une situation de stress hydrique. Comprenez comment réduire l'<b>empreinte eau</b> à l'échelle d'un territoire !
          </p>
          <p className="paragraphe-nos-gestes-title dynamic-text">
            Ensemble apprenons  <span className="typeJsText">{" "}{changingText}</span>
          </p>
          <button className="boutton-commencer"  onClick={() => {window.location.href = 'https://compte-gouttes.fr'}}>
            <a>Découvrir</a>
          </button>
        </div>
      </div>
      
      
      

      <div className="wf-paragraphe">
        <div className="empreinte-eau-definition" >
          <div>
            <img
              src="/img/robinet-personnes.png"
              alt="Des personnes en train de réfléchir autour d'un robinet d'eau'"
              className="img-hydros-teaser hidden"
              ref={(el) => hiddenElementsRef.current.push(el)}
              loading="lazy"
            ></img>
          </div>
          <div className="hydros-teaser-left-container">
            <h2 className="hydros-teaser-title hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
            Vous souhaitez mesurer l'empreinte eau de votre entreprise ? 
            </h2>
            <p className="hydros-text-white">Avec Hydros, nous vous accompagnons pour mesurer votre empreinte eau, vos risques et vos impacts sur la ressource à l'échelle de votre organisation, d'un produit, d'une activité ou d'un territoire.</p>
            <p className="hydros-text-white">Calculer son <b>empreinte eau</b> est le premier pas pour comprendre où et comment agir sur la ressource en eau !</p>           
            <button className="boutton-commencer boutton-orange hidden" ref={(el) => hiddenElementsRef.current.push(el)} onClick={() => window.location.href = 'https://hydros-consulting.com/'}>
              Découvrir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
