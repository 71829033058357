import React, { useState } from 'react';
import LoadingAnimation from '../utils/LoadingAnimation';

const Contact = () => {
    const hiddenElementsRef = LoadingAnimation();
    const [isSend, setIsSent] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        mail: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        setIsSending(true);
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/post_contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                // Clear the form
                setFormData({
                    nom: '',
                    prenom: '',
                    mail: '',
                    message: '',
                });
                // Show success alert
                setIsSending(false)
                setIsSent(true);
            } else {
                setIsSending(false)
                setIsFailed(true);
            }
        } catch (error) {
            setIsSending(false)
            setIsFailed(true);
        }
    };

    const linkHydros = () => {
        window.location.href = 'https://hydros-consulting.com/';
    };

    const linkCompteGouttes = () => {
        window.location.href = 'https://compte-gouttes.fr/';
    };

    return (
        <div className="contact-page" >
            <div className="paragraphe-nos-gestes-container hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
                <h1 className="paragraph1-title">
                    Contactez-nous
                </h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="nom" className="paragraphe-nos-gestes-item">
                            Nom
                        </label>
                        <input
                            type="text"
                            id="nom"
                            name="nom"
                            className="paragraphe-nos-gestes-title dynamic-text formulaire"
                            value={formData.nom}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="prenom" className="paragraphe-nos-gestes-item">
                            Prénom
                        </label>
                        <input
                            type="text"
                            id="prenom"
                            name="prenom"
                            className="paragraphe-nos-gestes-title dynamic-text formulaire"
                            value={formData.prenom}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mail" className="paragraphe-nos-gestes-item">
                            Mail
                        </label>
                        <input
                            type="email"
                            id="mail"
                            name="mail"
                            className="paragraphe-nos-gestes-title dynamic-text formulaire"
                            value={formData.mail}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" className="paragraphe-nos-gestes-item">
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            className="paragraphe-nos-gestes-title dynamic-text formulaire"
                            style={{ height: '7rem'}}
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div style={{ height: '4rem', display: 'flex', marginTop:'1rem' }}>
        {isSend ? (
          <div className='' >Votre message a bien été envoyé !</div>
        ) : isSending ? (
          <div className="load"></div>
        ) : isFailed ? (
          <div className='echec-status' >Echec</div>
        ) : (
          <button type="submit" className="boutton-commencer">
            Envoyer
          </button>
        )}
      </div>
                </form>
            </div>
            <div className='hidden' ref={(el) => hiddenElementsRef.current.push(el)}>
                <img
                    src="/img/atelier_compte_gouttes.webp"
                    alt="atelier-compte-gouttes"
                    className="img-atelier-compte-gouttes"
                />
                <div>Retrouvez-nous aussi ici : <br/><img src='/img/Logo_Hydros.png' onClick={linkHydros} style={{height:'6rem', marginRight:'3rem',cursor:'pointer'}}></img><img src='/img/logo_compte_gouttes_bleu.avif' onClick={linkCompteGouttes } style={{height:'6rem',cursor:'pointer'}}></img></div>
            </div>
        </div>
    );
};

export default Contact;
