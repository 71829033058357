import React, { createContext, useContext, useState, useEffect } from 'react';

// Créez un contexte
const SituationContext = createContext();

// Utilisez ce hook pour accéder au contexte
export const useSituation = () => useContext(SituationContext);

// Le composant Provider qui enveloppe votre application
export const SituationProvider = ({ children }) => {
  const [situation, updateSituation] = useState(() => {
    const savedSituation = localStorage.getItem('compte-gouttes-situation');
    return savedSituation ? JSON.parse(savedSituation) : null;
  });

  // Sauvegarder dans le localStorage quand la situation change
  useEffect(() => {
    if (situation) {
      localStorage.setItem('compte-gouttes-situation', JSON.stringify(situation));
    }
  }, [situation]);

  return (
    <SituationContext.Provider value={{ situation, updateSituation }}>
      {children}
    </SituationContext.Provider>
  );
};
