import React, { useState, useEffect } from "react";
import { useSituation } from "../SituationManager";
import { useEngine } from "../EngineManager";
import LoadingAnimation from "../utils/LoadingAnimation";



import MainResults from "../graph/MainResults";
import ActionSection from "../graph/ActionSection";
import RepartitionSection from "../graph/Repartition";
import EquivalentSection from "../graph/EquivalentSection";
import { useAsyncError } from "react-router-dom";
import { element } from "prop-types";
import { numStr } from "../utils/FormatNumber";
import WaterFootprintColor from "../WFNDetail";
import { useNavigate } from "react-router-dom";
import { EmpreintePays } from "../ComprendreEmpreintePays";
import Engine from "publicodes";

const EndResults = () => {
  const navigate = useNavigate();
  const [isSend, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isChecked, setIsChecked] = useState(false);



  const { situation } = useSituation();
  const engine = useEngine();
  const hiddenElementsRef = LoadingAnimation();
  const [showGoutteEau, setShowGoutteEau] = useState('bleu');

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [footprintResult, setFootprintResult] = useState(situation.simulation.footprintResult);
  const [actionDict, setActionDict] = useState({});
  const [equivalentList, setequivalentList] = useState([]);

  const getActionSections = () => {
    const newActionDict = {};
    const listSectionToDisplay = [];

    footprintResult.detail.map((element) => { listSectionToDisplay.push(element.dottedName) });
    //console.log(listSectionToDisplay)

    Object.entries(engine.publicParsedRules).forEach(([key, value]) => {
      if (
        value.rawNode.type === 'action' &&
        engine.evaluate({ "est applicable": key }).nodeValue
      ) {
        listSectionToDisplay.forEach(section => {
          if (value.dottedName.includes(section)) {
            if (!newActionDict[section]) {
              newActionDict[section] = [];
            }
            newActionDict[section].push(value.dottedName);
          }
        });
      }
    });

    return newActionDict;
  };

  const getEquivalentList = () => {
    const listEqui = [];
    Object.entries(engine.publicParsedRules).forEach(([key, value]) => {
      if (
        value.rawNode.type === 'equivalent') {
        listEqui.push({
          key: key,
          rawNode: value.rawNode,
          value: engine.evaluate(key).nodeValue
        })
      }
    });

    return listEqui;
  };

  // const getWFNData = () => {
  //   engine.publicParsedRules['empreinte eau . bleu'];

  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (engine.publicParsedRules["direct"]) {
      engine.setSituation(situation.simulation.simulationAnswers);
      setActionDict(getActionSections());
      setequivalentList(getEquivalentList());
      setDecriptionDirect(engine.publicParsedRules.direct.rawNode.description);
      setDescriptionIndirect(engine.publicParsedRules.indirect.rawNode.description);
    }
  }, [engine])

  const getDetailByKey = (details, key) => {
    const detail = details.find((detail) => detail.key === key);
    return detail ? detail.value : 0;
  };

  const [formData, setFormData] = useState({
    mail: '',
    session_id: situation.userId,
    total: footprintResult['globalFootprint'],
    nrj: getDetailByKey(footprintResult["detail"], "nrj"),
    service_societaux: getDetailByKey(footprintResult["detail"], "services societaux"),
    alimentation: getDetailByKey(footprintResult["detail"], "alimentation"),
    textile: getDetailByKey(footprintResult["detail"], "textile"),
    numerique: getDetailByKey(footprintResult["detail"], "numerique"),
    logement: getDetailByKey(footprintResult["detail"], "construction"),
  });

  useEffect(() => {
    setFormData({
      ...formData,
      total: footprintResult.globalFootprint,
      directFootprint: footprintResult['directFootprint'],
      indirectFootprint: footprintResult['indirectFootprint'],
      nrj: getDetailByKey(footprintResult.detail, "nrj"),
      service_societaux: getDetailByKey(footprintResult.detail, "services societaux"),
      alimentation: getDetailByKey(footprintResult.detail, "alimentation"),
      textile: getDetailByKey(footprintResult.detail, "textile"),
      numerique: getDetailByKey(footprintResult.detail, "numerique"),
      logement: getDetailByKey(footprintResult.detail, "construction"),
      robinet: getDetailByKey(footprintResult.detail, "robinet"),
      linge: getDetailByKey(footprintResult.detail, "linge"),
      douche: getDetailByKey(footprintResult.detail, "douche"),
      wc: getDetailByKey(footprintResult.detail, "wc"),
      cuisine: getDetailByKey(footprintResult.detail, "cuisine"),
    });
  }, [footprintResult]);

  const calculatePseudoMarginTop = (value) => {
    if (value >= 10000) {
      return 95;
    } else if (value === 0) {
      return 0;
    } else {
      return (value / 10000) * 95; // proportionnel entre 0 et 45
    }
  };


  const handleDownloadClick = async () => {
    const alimentationFootprint = getDetailByKey(footprintResult.detail, "alimentation");
    const constructionFootprint = getDetailByKey(footprintResult.detail, "construction");
    const textileFootprint = getDetailByKey(footprintResult.detail, "textile");
    const numeriqueFootprint = getDetailByKey(footprintResult.detail, "numerique");
    const interieurFootprint =
      footprintResult["directFootprint"] +
      getDetailByKey(footprintResult.detail, "nrj") +
      getDetailByKey(footprintResult.detail, "services societaux");

    try {
      const url = new URL(`${process.env.REACT_APP_API_URL}/generate_pdf`);
      url.searchParams.append('globalFootprint', situation.simulation.footprintResult.globalFootprint);
      url.searchParams.append('directFootprint', situation.simulation.footprintResult.directFootprint);
      url.searchParams.append('indirectFootprint', situation.simulation.footprintResult.indirectFootprint);
      url.searchParams.append('blue_wf', engine.evaluate('empreinte eau . bleu').nodeValue);
      url.searchParams.append('grey_wf', engine.evaluate('empreinte eau . gris').nodeValue);
      url.searchParams.append('green_wf', engine.evaluate('empreinte eau . vert').nodeValue);
      url.searchParams.append('alimentation_footprint', alimentationFootprint);
      url.searchParams.append('numerique_footprint', numeriqueFootprint);
      url.searchParams.append('textile_footprint', textileFootprint);
      url.searchParams.append('construction_footprint', constructionFootprint);
      url.searchParams.append('interieur_footprint', interieurFootprint);


      const response = await fetch(url.toString());

      if (response.ok) {
        // La réponse est OK, alors traitons la réponse comme nécessaire
        // Dans cet exemple, nous allons simplement rediriger l'utilisateur vers le rapport téléchargé
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `empreinte_eau.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else {
        console.error('Erreur lors du téléchargement du rapport.');

      }
    } catch (error) {
      console.error('Erreur lors du téléchargement du rapport :', error);

    }
  };


  const pseudoMarginTop = calculatePseudoMarginTop(footprintResult['globalFootprint']);








  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (e) => {
    setIsSending(true)
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send_results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          bleu: engine.evaluate('empreinte eau . bleu').nodeValue,
          vert: engine.evaluate('empreinte eau . vert').nodeValue,
          gris: engine.evaluate('empreinte eau . gris').nodeValue
        }),
      });
      if (response.ok) {
        setFormData(prevState => ({
          ...prevState,

          mail: ''
        }));

        setIsSending(false)
        setIsSent(true)

      } else {

        setIsSending(false)
        setIsFailed(true)
      }
    } catch (error) {

      setIsSending(false)
      setIsFailed(true)

    }
  };

  const handleInfoAction = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  }

  const [descriptiondirect, setDecriptionDirect] = useState('');
  const [descriptionindirect, setDescriptionIndirect] = useState('');



  return (
    <div className="results-page">

      <div className="results-box-container hidden" ref={(el) => hiddenElementsRef.current.push(el)}>

        <div className="box">
          <div className="box-inner"
            style={{ '--pseudo-margin-top': `${(pseudoMarginTop)}%` }}
          >
            <h2 className="results-title mon-empreinte-eau"><strong>Mon empreinte eau</strong> </h2>
            <div className="results-empreinte"><strong>{numStr(footprintResult['globalFootprint'], ' ')} L/Jour</strong> </div>
          </div>
        </div>

        <div className="results-france" >
          <div>
            <img src="/img/flags/france.png" className="france-img" onClick={() => console.log(footprintResult, 'YEAH')}></img>{" "}
          </div>
          <div >
            En moyenne l'empreinte d'eau d'un francais est d'environ 6 000 L/Jour{" "}
          </div>

        </div>
      </div>

      <div className="results-box-container hidden" ref={(el) => hiddenElementsRef.current.push(el)}>
        <EquivalentSection footprintResult={footprintResult} equivalentList={equivalentList}></EquivalentSection>

        <div className="partager-results">
          <div >
            <h4 style={{ margin: '0px' }}>L'empreinte par pays{" "}</h4>
            <EmpreintePays></EmpreintePays>
          </div>


        </div>
      </div>





      <div class="send-result-container">

        <h2 className="graph-results-title" ref={(el) => hiddenElementsRef.current.push(el)}>Recevoir la synthèse de mes résultats par mail</h2>




        {isSend ? (
          <div className='' >Merci, vous allez recevoir votre résultat d'ici quelques minutes !</div>
        ) : isSending ? (
          <div className="load"></div>
        ) : isFailed ? (
          <div className='echec-status' >Echec !</div>
        ) : (

          <form onSubmit={handleSubmit}>
            <div className="form-send-result">
              <input
                id="mail"
                name="mail" type="email" placeholder="Email" class="email-input" value={formData.mail}
                onChange={handleChange} required />
              <button type="submit" class="submit-button">Recevoir</button>
            </div>
            <div className="checkbox-consent-container">
              <input
                type="checkbox"
                id="consent"
                checked={isChecked}
                onChange={handleCheckboxChange}
                required
              />
              <label htmlFor="consent">J'accepte l'utilisation de mon email pour recevoir le résumé</label>
            </div>
          </form>


        )}



        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.5rem' }}>
          <button class="button-hydros" onClick={handleDownloadClick} style={{ marginLeft: '0px' }}>Télécharger le format à partager</button>
          <button class="button-hydros" ><a className="no-link" href='/contact'>Contactez-nous</a></button>
        </div>
      </div>


      {engine.publicParsedRules['direct'] ? (
        <div className="couleur-empreinte-container">

          <div style={{ display: 'flex', alignItems: 'center' }}><div style={{ display: 'flex', width: '90%' }}><h2 className="grands-postes-title" style={{ paddingLeft: '0px', marginLeft: '2rem', padding: '0px' }}>Les couleurs de mon empreinte</h2></div>

            <div><img src='img/info.svg' className="" style={{ width: '3rem' }} onClick={handleInfoAction}></img></div></div>
          {isDescriptionVisible && (
            <div className="couleur-empreinte-info"><p ><img src='/img/idee.png' className="idee-img"></img>{engine.publicParsedRules['explication bleu vert gris'].rawNode.description}</p><a style={{ fontWeight: 'bold', textDecoration: 'none', color: 'var(--hydrosblue)' }} href="https://blog.mon-empreinte-eau.fr/notre-methodologie">En savoir plus</a>
            </div>
          )}
          <WaterFootprintColor engine={engine} setShowGoutteEau={setShowGoutteEau} showGoutteEau={showGoutteEau} />

        </div>
      ) : (<div></div>)}

      <h2 className="grands-postes-title hidden" ref={(el) => hiddenElementsRef.current.push(el)}>Votre répartition</h2>

      <RepartitionSection footprintResult={footprintResult} descriptiondirect={descriptiondirect} descriptionindirect={descriptionindirect}></RepartitionSection>




      <h2 className="grands-postes-title hidden" ref={(el) => hiddenElementsRef.current.push(el)}>Les grands postes de mon empreinte directe</h2>
      <MainResults type="direct" footprintResult={footprintResult} />

      <h2 className="grands-postes-title hidden" ref={(el) => hiddenElementsRef.current.push(el)}>Les grands postes de mon empreinte indirecte</h2>
      <MainResults type="indirect" footprintResult={footprintResult} />

      <h2 className="grands-postes-title hidden" ref={(el) => hiddenElementsRef.current.push(el)}>Explorer la carte de mon empreinte</h2>

      <a className='bg no-link' href='/map'> <button className="button-hydros" style={{ marginTop: '30%' }}>Cliquez pour explorer votre carte !</button></a>

      <h2 className="grands-postes-title hidden" ref={(el) => hiddenElementsRef.current.push(el)}>Comment agir ?</h2>
      {Object.entries(actionDict).map(([key, value]) => (
        <ActionSection sectionName={key} actions={value} engine={engine} />
      ))}



    </div>
  );
};

export default EndResults;
