import React, { useState } from 'react';
import PieChart from './PieChart';
import { numStr } from '../utils/FormatNumber';
import LoadingAnimation from '../utils/LoadingAnimation';

const RepartitionSection = ({ footprintResult, descriptiondirect, descriptionindirect }) => {
    const [showDescriptions, setShowDescriptions] = useState({
        direct: false,
        indirect: false,
    });

    const hiddenElementsRef = LoadingAnimation();

    const toggleDescription = (type) => {
        setShowDescriptions((prev) => ({
            ...prev,
            [type]: !prev[type],
        }));
    };

    const directData = footprintResult.detail.filter(item => item.category === 'direct');
    const indirectData = footprintResult.detail.filter(item => item.category === 'indirect');

    return (
        <div className='repartition-container hidden' ref={(el) => hiddenElementsRef.current.push(el)}>
            <div className="repartition-section-directe">
                <div className='piecharts-title'>
                    <div>Votre empreinte eau directe</div>
                    <div>
                        <img
                            src='img/info.svg'
                            className="info-empreinte"
                            onClick={() => toggleDescription('direct')}
                        />
                    </div>
                </div>
                <div className='total-empreinte'>{footprintResult.directFootprint} litres/jour</div>
                {showDescriptions.direct && (
                    <div>
                        <div style={{ backgroundColor: '#F7F7FF' }} className="couleur-empreinte-info">
                            <p style={{ color: 'var(--hydrosblue)', fontWeight: 'bold' }}>
                                {descriptiondirect}
                            </p>
                        </div>
                    </div>
                )}
                <PieChart width={"30%"} data={directData} title="Votre empreinte eau directe" />
            </div>
            <div className="repartition-section-indirecte">
                <div className='piecharts-title'>
                    <div>Votre empreinte eau indirecte</div>
                    <div>
                        <img
                            src='img/info.svg'
                            className="info-empreinte"
                            onClick={() => toggleDescription('indirect')}
                        />
                    </div>
                </div>
                <div className='total-empreinte'>{numStr(footprintResult.indirectFootprint, ' ')} litres/jour</div>
                {showDescriptions.indirect && (
                    <div>
                        <div style={{ backgroundColor: '#F7F7FF' }} className="couleur-empreinte-info">
                            <p style={{ color: 'var(--hydrosblue)', fontWeight: 'bold' }}>
                                {descriptionindirect}
                            </p>
                        </div>
                    </div>
                )}
                <PieChart width={"30%"} data={indirectData} title="Votre empreinte eau indirecte" />
            </div>
        </div>
    );
};

export default RepartitionSection;


