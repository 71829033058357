import React from 'react';

const imageMap = {
  numerique: '/img/numerique.png',
  nrj: '/img/nrj.png',
  textile: '/img/textile.png',
  "services societaux": '/img/services_societaux.png',
  alimentation: '/img/alimentation.png',
  wc: '/img/wc.png',
  douche: '/img/douche.png',
  cuisine: '/img/cuisine.png',
  vaisselle: '/img/vaisselle.png',
  bain: '/img/bain.png',
  robinet: '/img/robinet.png',
  linge: '/img/linge.png',
  voiture: '/img/voiture.png',
  animaux: '/img/animaux.png',
  jardin: '/img/jardin.png',
  piscine: '/img/piscine.png',
  terrasse: '/img/terrasse.png',
  hydratation: '/img/hydratation.png',
  construction:'/img/construction.png',
};

const colorMap = {
  numerique: 'rgb(162 210 253)',
  nrj: 'rgb(107 196 123)',
  textile: 'rgb(249 205 73)',
  "services societaux": 'rgb(158 142 245)',
  alimentation: 'rgb(253 163 84 )',
  construction:'rgb(158 142 245)',
  douche: 'lightblue',
  wc: 'lightblue',
  cuisine: 'lightblue',
  vaisselle: 'lightblue',
  bain: 'lightblue',
  robinet: 'lightblue',
  linge: 'lightblue',
  voiture: 'lightblue',
  animaux: 'lightblue',
  jardin: 'lightblue',
  piscine: 'lightblue',
  terrasse: 'lightblue',
  hydratation: 'lightblue',
};

const GraphiqueEmpreinte = ({ type, footprintResult }) => {
  const dataEmpreinte = footprintResult.detail.filter(item => item.category === type);

  let combinedWidth = 0;
  let combinedKeys = [];

  //console.log(dataEmpreinte)

  return (
    <div>
      <ul className="results-ul">
        {dataEmpreinte.map(element => {
          const shortKey = element.key;
          const imgSrc = imageMap[shortKey];
          const bgColor = colorMap[shortKey];
          if (element.value < 15 && type==='direct' ) {
            combinedWidth += element.value;
            combinedKeys.push(shortKey);
            return null; // Skip rendering for now
          }

          return (
            <li
              key={element.key}
              className={`results-${type} ${element.key}`}
              style={{
                width: `min(${element.value* 1.25}rem, 10000rem)`,
                backgroundColor: bgColor,
                margin: "5px 0",
              }}
            >
              {}
              {imgSrc && (type !== 'direct' || element.value > 1) && (
                <div className="circle-img">
                  <img
                    src={imgSrc}
                    alt={shortKey}
                    className="results-img"
                    onClick={() => console.log(element.value)}
                  />
                </div>
              )}
            </li>
          )
        })
      }
      {combinedWidth >= 0 && type==='direct' && (
        <li
          className={`results-indirect`}
          style={{
            width: `${combinedWidth* 1.25}rem` ,
            backgroundColor: 'lightblue',
            margin: "5px 0",
          }}
        >               
        </li>
      )}
    </ul>
  </div>
)}


export default GraphiqueEmpreinte;
