import logo from './logo.svg';
import "./assets/fonts/fonts.css";
import './App.css';
import Contact from './components/page/Contact';
// import Question from './components/Question';
import Home from './components/page/Home';
import PublicodeComponent from './components/TestPublicode';
import Question from './components/page/QuestionPublicode';
import { EngineProvider } from './components/EngineManager';
import { SituationProvider } from './components/SituationManager';
import Header from './components/Header';
import Footer from './components/Footer';
import EndResults from './components/page/EndResult';
import Apropos from './components/page/Apropos';
import MapPage from './components/page/MapPage';
import Maptest from './components/graph/AdvancedMap';
import FAQ from './components/page/faq';
// import PublicodesDocumentation from './components/page/DocumentationPage';
import QuestionTEST from './components/page/delete_this_page_after_test';






import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useParams,
  useLocation
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div><Header/><HomePage /><Footer/></div>,
  },
  {
    path: "/question/:QuestionId",
    element: <div><Header/><QuestionPage /><Footer/></div>,
  },
  {
    path: "/questionTEST/:QuestionId",
    element: <div><Header/><QuestionPage /><Footer/></div>,
  },
  {
    path: "/publicode",
    element: <div><Header/><PublicodePage /><Footer/></div>,
  },
  {
    path: "/end_result",
    element: <div><Header/><ResultsPage /><Footer/></div>,
  },
  {
    path: "/contact",
    element: <div><Header/><Contact /><Footer/></div>,
  },
  {
    path: "/a-propos",
    element: <div><Header/><Apropos /><Footer/></div>,
  },
  {
    path: "/map",
    element: <div><Header/><MapPage /><Footer/></div>,
  },
  {
    path: "/maptest",
    element: <div><Header/><Maptest /><Footer/></div>,
  },
  {
    path: "/faq",
    element: <div><Header/><FAQ /><Footer/></div>,
  },
  // {
  //   path: "/documentation/:name*",
  //   element: <div><Header/><PublicodesDocumentation /><Footer/></div>,
  // }
  
]);



function HomePage() {

  

  return (
    <div className="App">
      
      <Home ></Home>
      
    </div>
  )
}

function PublicodePage() {
  return (
    <div className="App">
      <PublicodeComponent/>
    </div>
  )
}

function QuestionPage() {
  const { QuestionId } = useParams();
  return (
    <div className="App">
      <Question QuestionId={QuestionId}></Question>
    </div>
  );
}

function QuestionPageTEST() {
  const { QuestionId } = useParams();
  return (
    <div className="App">
      <QuestionTEST QuestionId={QuestionId}></QuestionTEST>
    </div>
  );
}


function ResultsPage() {
  const { QuestionId } = useParams();
  return (
    <div className="App">
      <EndResults></EndResults>
    </div>
  );
}

function App() {

  return (
    <div>
      <SituationProvider>
        <EngineProvider>
          <RouterProvider router={router} />
          
        </EngineProvider>
      </SituationProvider>
    </div>);
}

export default App;
