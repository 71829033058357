import React, { useState, useEffect } from 'react';


const ValueQuestion = ({ questionId, questionData, answer, setAnswer, situation, updateSituation, engine, updateResult }) => {
    // const [answer, setAnswer] = useState('');

    useEffect(() => {
        //console.log('test')
        setAnswer(situation.simulation.simulationAnswers[questionId]||'')},
        [questionId]
    )


    const UpsertAnswerSituation = (answerValue) => {

           
        const newSituation = { ...situation };
        const key = questionId;
        //console.log()


        if (answerValue.startsWith('-')) {
            // setAnswer(answerValue.slice(1,answerValue.length));
            answerValue = answerValue.slice(1,answerValue.length)
        }
        else if (answerValue.startsWith(0) && answerValue.length>1 && !answerValue.includes(".") && !answerValue.includes(",")) {
            // console.log("aa");
            // console.log(answerValue)
            // console.log(answerValue.slice(1,answerValue.length))
            // setAnswer(answerValue.slice(1,answerValue.length));
            answerValue = answerValue.slice(1,answerValue.length)
            // console.log(answerValue)
        }

        // special case for question 1
        if (questionId=='direct . nombre de personnes' && answerValue[0]=='0') {
            answerValue='';

        }

        if (!answerValue||answerValue==''||answerValue==null) {
            //console.log("find")
            setAnswer(answerValue)

            // Update situation
            delete newSituation.simulation.simulationAnswers[key];   
            updateSituation(newSituation);

            // Update engine
            engine.setSituation(situation.simulation.simulationAnswers);
            updateResult()
        } else {
            setAnswer(answerValue);
            // Update situation
            newSituation.simulation.simulationAnswers[key] = answerValue;   
            updateSituation(newSituation);

            // Update engine
            engine.setSituation(situation.simulation.simulationAnswers);
            updateResult() 
        }}

    return (
        <div>
            <div className='suggestion_container'>

            {Object.entries(questionData.suggestions).map(([key, value]) => (
                <div>
                <button
                    key={key}
                    onClick={() => UpsertAnswerSituation(value+'')}
                    className='button-hydros'
                >
                    {key}
                </button></div>
            ))}
            </div>
            <div className='answer_container'>
                <label>
                    Réponse:
                    <input
                        className='answer_input'
                        type="number"
                        max="100000"
                        value={answer}
                        onChange={(e) => UpsertAnswerSituation(e.target.value)}
                        placeholder={questionData['par défaut']}
                    />
                </label>
            </div>
        </div>
    );
};

export default ValueQuestion;

