import React, { useEffect, useState } from 'react';

const MosaicValueQuestion = ({ questionId, questionData, answer, setAnswer, situation, updateSituation, engine, updateResult }) => {
    // const [answers, setAnswers] = useState({});
    // console.log(engine.publicParsedRules[questionId])
    // console.log(engine.publicParsedRules[questionId + ' . ' + questionData.mosaique.options[1]].rawNode)
    // console.log(questionData)
    console.log(questionId,'questionID')
    console.log(questionData.titre,'questionData')
    useEffect(() => {
        const initialAnswers = questionData.mosaique.options.reduce((acc, option) => {
            const fullOptionKey = questionId + ' . ' + option;
            if (engine.publicParsedRules[fullOptionKey]) {
                console.log(fullOptionKey)
                const defaultValue = engine.publicParsedRules[fullOptionKey].rawNode['par défaut'];
                if (situation.simulation.simulationAnswers[fullOptionKey] || situation.simulation.simulationAnswers[fullOptionKey]==0) {
                    acc[option] = situation.simulation.simulationAnswers[fullOptionKey]
                } else {
                    acc[option] = defaultValue;
                }
            } else {
                acc[option] = situation.simulation.simulationAnswers[fullOptionKey] || 0;
            }
            return acc;
        }, {});

        setAnswer(initialAnswers);
    }, [questionData]);

    const handleInputChange = (option, value) => {
        const newAnswers = { ...answer, [option]: value };
        setAnswer(newAnswers);

        const newSituation = { ...situation };
        newSituation.simulation.simulationAnswers[questionId + ' . ' + option] = value || 0;
        updateSituation(newSituation);

        engine.setSituation(newSituation.simulation.simulationAnswers);
        updateResult();
    };

    const handleSuggestion = (suggestion) => {
        const newAnswers = { ...answer, ...suggestion };
        setAnswer(newAnswers);

        const newSituation = { ...situation };
        Object.keys(newAnswers).forEach(key => {
            newSituation.simulation.simulationAnswers[questionId + ' . ' + key] = newAnswers[key];
        });
        updateSituation(newSituation);

        engine.setSituation(newSituation.simulation.simulationAnswers);
        updateResult();
    };

    const handleSubtraction = (option) => {
        if (answer[option] > 0) {
            const newValue = (answer[option] || 0) - 1;
            handleInputChange(option, newValue);
        }
    };

    const handleAddition = (option) => {
        const newValue = (answer[option] || 0) + 1;
        handleInputChange(option, newValue);
    };

    const getOptionName = (option) => {
        try {
            return engine.publicParsedRules[questionId + ' . ' + option].rawNode.titre;
        } catch {
            return option;
        }
    };

    const sumOptions = () => {
        return Object.values(answer).reduce((sum, value) => sum + (parseInt(value) || 0), 0);
    };

    return (
        <div>
            <div className='suggestions'>
                {Object.entries(questionData.mosaique.suggestions).map(([suggestion, values]) => (
                    <button
                        key={suggestion}
                        onClick={() => handleSuggestion(values)}
                        className='button-hydros'
                    >
                        {suggestion}
                    </button>
                ))}
            </div>

            <div className='mosaic-options'>
                {questionData.mosaique.options.map((option, index) => (
                    <div key={index} className='mosaic-option'>
                        <label>{getOptionName(option)}</label>
                        <div className='mosaic-number-input-container'>
                            <img src='/img/soustraction.svg' alt='subtract' className='mosaic-option-img' onClick={() => handleSubtraction(option)} />
                            <div className='number-input'>{answer[option] || '0'}</div>
                            <img src='/img/addition.svg' alt='add' className='mosaic-option-img' onClick={() => handleAddition(option)} />
                        </div>
                    </div>
                ))}
            </div>
            {questionData.titre ==='Déjeuners et dîners' ?<p>{sumOptions()} repas par semaine</p> :''}
        </div>
    );
};

export default MosaicValueQuestion;
